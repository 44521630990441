<template>
    <div class="border rounded-md flex items-center focus-within:border-bluegrassPrimary">
        <ion-icon :icon="searchOutline" class="pl-2 text-gray-400"></ion-icon>
        <input ref="searchInput" type="search" placeholder="Search driver" class="outline-none p-2" @input="search" />
    </div>
</template>
<script>
import { searchOutline } from 'ionicons/icons';
import { IonIcon } from '@ionic/vue';

export default {
    name: 'ApplicantsSearch',
    components: {
        IonIcon,
    },
    data() {
        return {
            searchOutline,
            debounceTimeout: null,
        };
    },
    watch: {
        $route() {
            this.$refs.searchInput.value = '';
        },
    },
    methods: {
        timeoutClear() {
            if (this.debounceTimeout) {
                clearTimeout(this.debounceTimeout);
            }
        },
        search(event) {
            const val = event.target.value;
            this.$emit('term', val);
            this.setGlobalLoading(true);
            this.timeoutClear();
            if (!val) {
                this.$emit('result', 'refresh');
                return;
            }
            this.debounceTimeout = setTimeout(() => {
                this.doSearch(val);
            }, 600);
        },
        doSearch(term) {
            axios.post(`${apiUrl}/mobile-app/applicant/api/global-search`, { term }).then(({ data }) => {
                this.setGlobalLoading(false);
                const { applicants } = data;
                this.$emit('result', applicants);
            });
        },
    },
};
</script>

<template>
    <card-template
        cardClass="bg-white hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-note class="text-gray-700 w-4 h-4" />
        </template>

        <template #title>
            Note
        </template>
        <template #menu>
            <card-menu 
                :data="data"
                ref="card_menu"
            >
                <a href="#" class="px-3 py-2 hover:bg-gray-50" @click="edit">Edit note</a>
                <a href="#" class="px-3 py-2 hover:bg-gray-50" @click="remove">Delete</a>
            </card-menu>
        </template>

        <template v-if="! editing">
            <span class="inline-block text-blackText flex-grow whitespace-pre-line froala-output" v-html="data.text"></span>
        </template>
        <template v-else>
            <div class="flex flex-col flex-grow relative">
                <div
                    class="flex flex-col flex-grow bg-white rounded-md"
                >
                    <content-note 
                        :users="recruiters"
                        v-model="data.text"
                    />
                </div>
                <div class="flex mt-2 space-x-1 justify-end absolute bottom-0 right-0">
                    <tr-button size="custom" font-size="sm" @click="save"> Save Note </tr-button>
                </div>
            </div>
        </template>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineNote from '@src/Ats/ActivityFeed/vue/Icon/TimelineNote';
import DotsVertical from '@src/Ats/ActivityFeed/vue/Icon/DotsVertical';
import ContentNote from '@src/Messaging/Templates/vue/Content/ContentNote.vue';
import { mapGetters } from "vuex";

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelineNote,
        DotsVertical,
        ContentNote
    },
    props: ['data', 'readonly'],
    data() {
        return {
            editing: false,
        };
    },
    computed: {
        recruiters() {
            return this.$store.getters.globals('dropdowns.recruiters');
        },
    },
    methods: {
        edit() {
            this.editing = true;
            this.$refs.card_menu.closeMenu();
        },
        save() {
            this.$store.dispatch('updateNote', {
                id: this.data.id,
                text: this.data.text
            })
            .then(() => {
                this.$toast.success('Note was updated.');
            })
            .finally(() => {
                this.editing = false;
            });
        },
        remove() {
            this.$store.dispatch('deleteNote', { id: this.data.id })
            .then(() => {
                this.$toast.success('Note was deleted.');
            });
        },
    },
};
</script>

<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM12.1818 9C12.6337 9 13 8.63369 13 8.18182C13 7.72995 12.6337 7.36364 12.1818 7.36364H8.63636V3.81818C8.63636 3.36631 8.27005 3 7.81818 3C7.36631 3 7 3.36631 7 3.81818V8.18182C7 8.63369 7.36631 9 7.81818 9H12.1818Z"
            fill="currentColor"
        />
    </svg>
</template>

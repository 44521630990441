<template>
    <div class="grid grid-cols-attachment gap-2 mt-2">
        <attachment
            v-for="a in attachments"
            :key="a.id"
            :name="a.name"
            :size="a.size"
            @click.native="(e) => linkTo(a.id, e)"
        /> 
    </div>
</template>

<script>
import {mapGetters} from "vuex";
import Attachment from '@src/Ats/ActivityFeed/vue/Attachments/Attachment';

export default {
    components: {
        Attachment,
    },
    props: {
        attachments: {
            type: Array,
            required: true,
        },
    },
    computed: {
        ...mapGetters(['inlineUrl', 'downloadUrl']),
    },
    methods: {
        linkTo(fileId, event) {
            if (event.ctrlKey || event.metaKey || event.altKey) {
                let inlineUrl = `${this.inlineUrl}?dqFileId=${fileId}`;

                window.open(inlineUrl, '_blank').focus();
            } else {
                let downloadUrl = `${this.downloadUrl}?dqFileId=${fileId}`;

                window.open(downloadUrl);
            }
        },
    },
};
</script>

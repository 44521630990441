<template>
    <div>
        <tr-button-simple type="white" size="sm" @click="show_modal = true">
            <div class="flex items-center gap-x-1.5">
                <span class="text-indigo-600"><icon-bolt class="w-3.5 h-3.5" /></span>
                <span>Add auto SMS</span>
            </div>
        </tr-button-simple>

        <add-auto-sms-modal 
            v-if="show_modal"
            :show_modal="show_modal"
            :worklist_id="worklist_id"
            :sms_body="sms_body"
            @close="show_modal = false"
        />
    </div>
</template>

<script>
import AddAutoSmsModal from '@src/Drivers/Worklist/vue/AutoSms/AddAutoSmsModal.vue';
import IconBolt from '@src/Common/Ui/Components/Icons/vue/bolt.vue';

export default {
    components: {
        AddAutoSmsModal,
        IconBolt,
    },
    props: {
        worklist_id: {
            type: Number,
            required: true
        },
        sms_body: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            show_modal: false
        };
    },
};
</script>

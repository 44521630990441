import FroalaEditor from 'froala-editor';
import Tribute from 'tributejs';
const emoji = require('emoji.json')

const froalaMentionMixin = {
    data() {
        return {
            tribute: null,
            tributeRef: null,
            tributeObserver: null,
            emoji: emoji,
        }
    },
    beforeDestroy() {
        const editor = this.$refs[this.tributeRef].getEditor();

        editor.el.removeEventListener("tribute-replaced", this.trackMentionSelected);
    },
    destroyed() {
        if (this.tribute.isActive) {
            this.tribute.hideMenu();
        }
        if (this.tributeObserver) {
            this.tributeObserver.disconnect();
        }
    },
    watch: {
        $route: {
            deep: true,
            handler() {
                if (this.tribute.isActive) {
                    this.tribute.hideMenu();
                }
            }
        },
        tribute: {
            deep: true,
            handler(value) {
                if (value && value.menu) {
                    const editor = this.$refs[this.tributeRef].getEditor();
                    const selection = editor.selection.get();
                    const { menu } = value;
                    menu.querySelectorAll('li').forEach((single) => {
                        single.addEventListener('mousedown',() => {
                            const node = selection.anchorNode;
                            if (node.parentNode.getAttribute('data-role') === 'prepare-for-mention') {
                                editor.selection.setAtEnd(node.parentNode);
                                editor.selection.restore();
                            }
                        });
                    });
                }
                if (value && value.isActive) {
                    this.tribute.allowSpaces = true;
                }
            },
        }
    },
    methods: {
        initMentions(userAutocomplete, emojiAutocomplete, users = [], ref = 'editor') {
            this.tributeRef = ref;
            this.registerCommand();
            const editor = this.$refs[ref].getEditor();
            if (typeof editor.events === 'undefined') {
                // Froala is initializing
                this.$refs[ref].initEvents.push(() => {
                    this.createTribute(userAutocomplete, emojiAutocomplete, users, ref);
                    this.registerObserver();
                });
            } else {
                // Froala already initialized
                this.$refs[ref].destroyEditor();
                this.$refs[ref].initEvents = [() => {
                    this.createTribute(userAutocomplete, emojiAutocomplete, users, ref);
                    this.registerObserver();
                }];
                this.$refs[ref].createEditor();
            }
        },
        createTribute(userAutocomplete, emojiAutocomplete, users, ref) {
            const defaultValues = {
                trigger: '@',
                lookup: 'name',
                allowSpaces: true,
                requireLeadingSpace: true,
                containerClass: 'tribute-container bg-white border border-gray-300 rounded-md p-2 z-50',
                itemClass: 'bg-white cursor-pointer py-2 px-3 border border-transparent',
                selectClass: 'tribute-selected',
                selectTemplate: item => `<b class="bg-gray-100 border border-gray-300 rounded-md py-1 px-2 fr-deletable fr-tribute" contenteditable="false" data-role="mention" data-user-id="${item.original.id}">@${item.original.name}</b>`,
                searchOpts: {
                    pre: '<span class="font-bold">',
                    post: '</span>',
                    skip: false
                },
            };
            const userMention = Object.assign(defaultValues, {
                values: users
            });

            const emojiMention = {
                trigger: ':',
                lookup: 'name',
                allowSpaces: false,
                requireLeadingSpace: true,
                containerClass: 'tribute-container bg-white border border-gray-300 rounded-md p-2 z-50',
                itemClass: 'bg-white cursor-pointer py-2 px-3 border border-transparent',
                menuItemTemplate: function (item) {
                    return item.original.char + ' '+ item.string;
                },
                selectClass: 'tribute-selected',
                selectTemplate: item => item.original.char,
                values: this.emoji,
                menuItemLimit: 10,
                searchOpts: {
                    pre: '<span class="font-bold">',
                    post: '</span>',
                    skip: false
                },
                noMatchTemplate: function () {
                    return "<span>Couldn't find your emoji</span>";
                }
            };

            let tributeCollections = [];
            if(userAutocomplete) {
                tributeCollections.push(userMention);
            }
            if(emojiAutocomplete) {
                tributeCollections.push(emojiMention);
            }

            this.tribute = new Tribute({ collection: tributeCollections});
            const editor = this.$refs[ref].getEditor();
            this.tribute.attach(editor.el);
            editor.events.on('keydown', (e) => {
                if (e.which === FroalaEditor.KEYCODE.ENTER && this.tribute.isActive) {
                    if (this.tribute.current && this.tribute.current.filteredItems.length === 0) {
                        this.tribute.hideMenu();
                        return;
                    }
                    const node = editor.selection.get().anchorNode;
                    if (node.parentNode.getAttribute('data-role') === 'prepare-for-mention') {
                        editor.selection.setAtEnd(node.parentNode);
                        editor.selection.restore();
                    }
                    return false;
                }
                if (e.which === FroalaEditor.KEYCODE.SPACE && this.tribute.isActive && this.tribute.current && this.tribute.current.filteredItems.length === 0) {
                    this.tribute.hideMenu();
                    setTimeout(() => {
                        this.tribute.current = {
                            element: this.tribute.current.element,
                        };
                        this.tribute.allowSpaces = false;
                    }, 100);
                    return;
                }
            }, true);
            editor.events.on('mousedown', (e) => {
                const element = e.target;
                if (element.getAttribute('data-role') === 'mention') {
                    editor.selection.setAfter(element);
                    editor.selection.restore();
                    setTimeout(() => {
                        editor.selection.setAfter(editor.selection.get().anchorNode);
                        editor.selection.restore();
                        element.remove();
                    }, 100);
                } else if (element.getAttribute('data-role') === 'prepare-for-mention') {
                    setTimeout(() => {
                        this.tribute.current.collection = this.tribute.collection[0];
                        this.tribute.current.externalTrigger = true;
                        this.tribute.current.element = element;
                        this.tribute.current.mentionText = element.innerText.replace('@', '');
                        this.tribute.showMenuFor(element);
                    }, 100);
                } else if (this.tribute.isActive) {
                    this.tribute.hideMenu();
                }
            }, true);
            editor.events.on('commands.before', (cmd) => {
                const commands = ['formatOL', 'formatUL'];
                if (commands.includes(cmd)) {
                    this.tributeObserver.disconnect();
                }
            });
            editor.events.on('commands.after', (cmd) => {
                const commands = ['formatOL', 'formatUL'];
                if (commands.includes(cmd)) {
                    this.tributeObserver.observe(document.querySelector('.fr-view'), {
                        childList: true,
                        subtree: true,
                    });
                }
            });

            editor.el.addEventListener("tribute-replaced", this.trackMentionSelected);
        },
        registerObserver() {
            const observer = new MutationObserver(mutationRecords => {
                mutationRecords.forEach(mutation => {
                    mutation.removedNodes.forEach(node => {
                        if (node.nodeName === 'B' && node.getAttribute('data-role') === 'mention') {
                            const editor = this.$refs[this.tributeRef].getEditor();
                            if (!editor.html.get()) return;
                            editor.html.insert(`<span data-role="prepare-for-mention">${node.innerText}</span>`);
                            setTimeout(() => {
                                this.tribute.current.collection = this.tribute.collection[0];
                                this.tribute.current.externalTrigger = true;
                                this.tribute.current.element = document.querySelector(".fr-element");
                                this.tribute.current.mentionText = node.innerText.replace('@', '');
                                this.tribute.showMenuFor(document.querySelector(".fr-element"));
                            }, 200);
                        }
                    });
                });
            })

            observer.observe(document.querySelector('.fr-view'), {
                childList: true,
                subtree: true,
            });

            this.tributeObserver = observer;
        },
        registerCommand() {
            FroalaEditor.DefineIcon('userMentionIcon', {PATH: FroalaEditor.SVG.mention, template: 'svg'});
            FroalaEditor.RegisterCommand('userMention', {
                title: 'Mention user',
                icon: 'userMentionIcon',
                focus: false,
                undo: false,
                refreshAfterCallback: false,
                callback: () => {
                    const input = document.querySelector(".fr-element");
                    this.tribute.current.collection = this.tribute.collection[0];
                    this.tribute.current.externalTrigger = false;
                    this.tribute.current.element = input;
                    this.tribute.current.mentionText = '';
                    this.tribute.showMenuForCollection(input);
                }
            });
        },
        updateUserMentionValues(values) {
            this.tribute.append(0, values, true);
        },
        trackMentionSelected(event) {
            if(! event.detail.item.original.char) {
                // ignore user mention
                return;
            }

            axios.post(route('user-emoji.clicked'), {
                emoji: event.detail.item.original.char,
                code: event.detail.item.original.codes
            }).then(() => {
            });
        },
    },
};

export default froalaMentionMixin;

const delayedCloseMixin = {
    data() {
        return {
            openPopover: false,
            closeTimeout: null,
        };
    },
    methods: {
        close(event) {
            this.hidePopover();

            if (typeof event !== 'undefined' && event && event.key && event.key === 'Escape' && event.target) {
                event.target.blur();
            }
        },
        clearDelayedClose() {
            if (this.closeTimeout) {
                clearTimeout(this.closeTimeout);
            }
        },
        delayedClose() {
            this.clearDelayedClose();
            this.closeTimeout = setTimeout(() => {
                this.close();
            }, 100);
        },
        togglePopover() {
            this.openPopover = !this.openPopover;
        },
        showPopover() {
            this.openPopover = true;
        },
        hidePopover() {
            this.openPopover = false;
        },
    },
};

export default delayedCloseMixin;

<template>
    <ion-app>
        <ion-router-outlet />
        <call-widget v-if="authed" />
    </ion-app>
</template>

<script>
import { IonApp, IonRouterOutlet } from '@ionic/vue';
import { defineComponent } from 'vue';
import { App } from '@capacitor/app';
import { SplashScreen } from '@capacitor/splash-screen';
import CallWidget from '@src/Telephony/Telephony/vue-mobile/vue/CallWidget.vue';

export default defineComponent({
    name: 'App',
    components: {
        CallWidget,
        IonApp,
        IonRouterOutlet,
    },
    data() {
        return {};
    }, 
    computed: {
        authed() {
            return this.$store.getters.authed;
        },
    },
    mounted() {
        SplashScreen.hide();

        App.addListener('appStateChange', ({ isActive }) => {
            if (isActive) {
                this.$store.dispatch('checkForToken').then((token) => {
                    if (!token) {
                        this.$router.push({ name: 'Login' });
                    }
                });
            }
        });
    },
});
</script>

<template>
    <svg width="16" height="16" viewBox="0 0 16 16" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M14.5 8C14.5 11.5899 11.5899 14.5 8 14.5C4.41015 14.5 1.5 11.5899 1.5 8C1.5 4.41015 4.41015 1.5 8 1.5C11.5899 1.5 14.5 4.41015 14.5 8ZM16 8C16 12.4183 12.4183 16 8 16C3.58172 16 0 12.4183 0 8C0 3.58172 3.58172 0 8 0C12.4183 0 16 3.58172 16 8ZM6 7C6.55228 7 7 6.55228 7 6C7 5.44772 6.55228 5 6 5C5.44772 5 5 5.44772 5 6C5 6.55228 5.44772 7 6 7ZM11 6C11 6.55228 10.5523 7 10 7C9.44771 7 9 6.55228 9 6C9 5.44772 9.44771 5 10 5C10.5523 5 11 5.44772 11 6ZM4.35281 8.26456C4.75699 8.18373 5.15022 8.44416 5.23412 8.84694L5.23554 8.85305C5.23758 8.86157 5.24173 8.87806 5.24839 8.90138C5.26176 8.94818 5.28497 9.02137 5.32126 9.11208C5.39444 9.29505 5.51678 9.53927 5.71055 9.78148C6.07922 10.2423 6.74479 10.75 7.9999 10.75C9.25501 10.75 9.92058 10.2423 10.2892 9.78148C10.483 9.53927 10.6054 9.29505 10.6785 9.11208C10.7148 9.02137 10.738 8.94818 10.7514 8.90138C10.7581 8.87806 10.7622 8.86157 10.7643 8.85305L10.7657 8.84693C10.8496 8.44416 11.2428 8.18373 11.647 8.26456C12.0532 8.3458 12.3166 8.74092 12.2353 9.14709L11.4999 9C12.2353 9.14709 12.2353 9.14751 12.2352 9.14795L12.235 9.14886L12.2346 9.15088L12.2336 9.15561L12.231 9.16787C12.2289 9.17725 12.2262 9.18905 12.2228 9.20311C12.2161 9.2312 12.2066 9.26842 12.1937 9.31346C12.168 9.40338 12.1287 9.52551 12.0713 9.66917C11.9569 9.95495 11.7668 10.3357 11.4606 10.7185C10.8292 11.5077 9.74479 12.25 7.9999 12.25C6.25501 12.25 5.17058 11.5077 4.53925 10.7185C4.23302 10.3357 4.04286 9.95495 3.92854 9.66917C3.87108 9.52551 3.83179 9.40338 3.8061 9.31346C3.79323 9.26842 3.7837 9.2312 3.77696 9.20311C3.77358 9.18905 3.7709 9.17725 3.76884 9.16787L3.76621 9.15561L3.76523 9.15088L3.76482 9.14886L3.76464 9.14795C3.76455 9.14751 3.76446 9.14709 4.49966 9.00005L3.76447 9.14709C3.68323 8.74092 3.94664 8.3458 4.35281 8.26456Z"
            fill="currentColor"
        />
    </svg>
</template>

<template>
    <div>
        <transition
            enter-active-class=""
            enter-class=""
            enter-to-class=""
            leave-active-class="transition duration-100 ease-in"
            leave-class="opacity-100"
            leave-to-class="opacity-0"
        >
            <div
                class="absolute z-40 p-3 mt-1 overflow-visible text-base bg-white rounded-md shadow focus:outline-none sm:text-sm border border-solid border-gray-300"
                tabindex="-1"
                role="group"
                v-show="openPopover"
            >
                <div class="flex flex-col space-y-1">
                    <div
                        v-for="user in value"
                        :key="user.id"
                        class="flex space-x-2 items-center px-3 py-2 rounded-md border border-solid border-white hover:bg-gray-50 hover:border-gray-300 cursor-pointer"
                        @click="$emit('selected', user.id)"
                    >
                        <tr-avatar
                            :name="user.name"
                            :color="user.avatar_color || 'bg-gray-300'"
                            :tooltip="!!user.name"
                            size="sm"
                        />
                        <div>{{ user.name }}</div>
                    </div>
                </div>
            </div>
        </transition>
    </div>
</template>
<script>
export default {
    props: {
        value: {
            type: Array,
            required: true
        },
        openPopover: {
            type: Boolean,
            required: true
        },
    },
    data() {
        return {
            loading: false,
        };
    },
    methods: {
    },
    mounted() {
    }
};
</script>

<template>
    <ion-footer v-if="profileEditing">
        <ion-toolbar class="white" :class="{ 'h-0': profileEditing.type === 'boolean' }">
            <component :is="fieldComponent" v-if="profile" />
        </ion-toolbar>
    </ion-footer>
</template>
<script>
import { IonFooter, IonToolbar } from '@ionic/vue';
import InputField from './EditFields/Input.vue';
import DateField from './EditFields/Date.vue';
import PreferenceField from './EditFields/Preference.vue';
import CheckboxesField from './EditFields/Checkboxes.vue';
import EquipmentField from './EditFields/Equipment.vue';
import BooleanField from './EditFields/Boolean.vue';
import LicenseField from './EditFields/License.vue';
import ExperienceField from './EditFields/Experience.vue';
import AddressField from './EditFields/Address.vue';
import EmploymentField from './EditFields/Employment.vue';
import EligibilityField from './EditFields/Eligibility.vue';

export default {
    components: {
        IonFooter,
        IonToolbar,
        InputField,
        DateField,
        PreferenceField,
        CheckboxesField,
        EquipmentField,
        BooleanField,
        LicenseField,
        ExperienceField,
        AddressField,
        EmploymentField,
        EligibilityField,
    },
    computed: {
        profile() {
            const { applicant } = this.$store.getters;
            if (applicant && applicant.profile) {
                return applicant.profile;
            }
            return null;
        },
        profileEditing() {
            return this.$store.getters.profileEditing;
        },
        fieldComponent() {
            if (this.profileEditing) {
                switch (this.profileEditing.type) {
                    case 'date':
                        return 'DateField';

                    case 'preference':
                        return 'PreferenceField';

                    case 'checkboxes':
                        return 'CheckboxesField';

                    case 'equipment':
                        return 'EquipmentField';

                    case 'boolean':
                        return 'BooleanField';

                    case 'license':
                        return 'LicenseField';

                    case 'experience':
                        return 'ExperienceField';

                    case 'address':
                        return 'AddressField';

                    case 'employment':
                        return 'EmploymentField';

                    case 'eligibility':
                        return 'EligibilityField';

                    default:
                        return 'InputField';
                }
            }
            return null;
        },
    },
};
</script>

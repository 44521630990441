<template>
    <div class="grid grid-cols-attachment gap-2 mt-2">
        <attachment 
            :name="name" 
            :size="size" 
        />
    </div>
</template>
<script>
import Attachment from '@src/Ats/ActivityFeed/vue/Attachments/Attachment';

export default {
    components: {
        Attachment,
    },
    props: {
        name: {
            type: String,
            required: true
        },
        size: {
            type: Number,
            default: null
        }
    }
};
</script>

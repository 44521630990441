<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="white without-border ion-padding-start ion-padding-end">
                <p class="text-xl font-medium">Applicant stages</p>
            </ion-toolbar>
        </ion-header>
        <ion-content :fullscreen="true">
            <div class="ion-padding-start ion-padding-end ion-padding-top text-gray-700">
                <div class="space-y-4">
                    <status-filters @filter="(status) => (statusFilter = status)" />
                    <search-input @term="(val) => (search = val)" @result="doSearch" />
                </div>
                <div class="pt-4">
                    <p class="text-xl font-medium pb-4">
                        {{ search ? `Searching: ${search}` : statusFilter.name }}
                    </p>
                    <div v-if="!globalLoading" class="space-y-2">
                        <single-applicant
                            v-for="applicant in applicants"
                            :key="applicant.key ? applicant.key : applicant.id"
                            :applicant="applicant"
                        />
                    </div>
                    <div v-else class="space-y-2">
                        <single-applicant v-for="n in 5" :key="`skeleton-applicant-${n}`" />
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { IonContent, IonHeader, IonPage, IonToolbar } from '@ionic/vue';
import SearchInput from '@src/Drivers/Search/vue-mobile/SearchInput.vue';
import SingleApplicant from './SingleApplicant.vue';
import StatusFilters from './Statuses.vue';

export default {
    name: 'Applicants',
    components: {
        IonContent,
        IonHeader,
        IonPage,
        IonToolbar,
        SearchInput,
        SingleApplicant,
        StatusFilters,
    },
    data() {
        return {
            statusFilter: {
                id: 'all',
                name: 'All',
            },
            search: '',
            searchDebounce: null,
        };
    },
    computed: {
        applicants() {
            const { applicants } = this.$store.getters;
            if (this.statusFilter.id !== 'all') {
                const { statuses } = this.statusFilters.find((single) => single.id === this.statusFilter.id);
                return applicants.filter((single) => statuses.includes(single.status));
            }
            return applicants || [];
        },
        applicantsLoaded() {
            return this.applicants.length;
        },
        employer() {
            return this.$store.getters.employer;
        },
        statusFilters() {
            return this.$store.getters.statusFilters;
        },
    },
    methods: {
        doSearch(result) {
            if (result === 'refresh') {
                this.$store.dispatch('getApplicants');
            } else if (result.length === 0) {
                console.log('no results');
            } else {
                this.$store.commit('setApplicants', result);
            }
        },
    },
    ionViewWillEnter() {
        this.search = '';
        this.$store.dispatch('getApplicants');
        const channel = pusher.subscribe(`private-employer.${this.employer.id}`);
        channel.bind('RefreshQueue', (data) => {
            if (data && data.action === 'update' && data.items && data.items) {
                this.$store.dispatch('refreshApplicants', data.items);
            }
        });
    },
};
</script>

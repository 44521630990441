<template>
    <card-template
        cardClass="bg-white-50 hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-document-text class="w-5 h-5 text-gray-700" />
        </template>
        <template #title>
            Driver profile updated
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="flex flex-col gap-y-1">
            <div class="flex text-xs text-gray-700 items-start gap-x-1" v-for="(change, index) in data.group_cards" :key="index">
                <span v-if="change.before">
                    Changed {{ change.field }}:
                </span>
                <span v-else>
                    Added {{ change.field }}:
                </span>

                <template v-if="change.before">
                    <span class="whitespace-pre-line">{{ change.before }}</span>
                    <span>
                        ->
                    </span>
                </template>

                <i v-if="change.after === ''">(none)</i>
                <span class="whitespace-pre-line" v-else>{{ change.after }}</span>
            </div>
        </div>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineDocumentText from '@src/Ats/ActivityFeed/vue/Icons/DocumentText';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelineDocumentText,
    },
    props: ['data', 'readonly'],
};
</script>

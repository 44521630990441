<template>
    <div>
        <div class="flex flex-wrap mt-2 gap-x-2 gap-y-2">
            <single-label v-for="tag in applicant.tags" :key="tag" @remove="removeTag(tag)">
                {{ tag }}
            </single-label>
            <single-label :button="true" @click="openAddLabel"> + Add Label </single-label>
        </div>
        <!-- Modal -->
        <ion-modal ref="labelModal" :is-open="showLabelModal" :presenting-element="presentingElement">
            <ion-header>
                <ion-toolbar class="white">
                    <ion-buttons slot="start">
                        <ion-button @click="cancel">Cancel</ion-button>
                    </ion-buttons>
                    <ion-title> Create new label </ion-title>
                    <ion-buttons slot="end">
                        <ion-button @click="save" :disabled="!newLabel">Done</ion-button>
                    </ion-buttons>
                </ion-toolbar>
                <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
            </ion-header>
            <ion-content class="ion-padding">
                <ion-input
                    v-model="newLabel"
                    type="text"
                    placeholder="Label name"
                    class="bg-gray-100 px-6 rounded-xl"
                />
            </ion-content>
        </ion-modal>
    </div>
</template>
<script>
import { IonButton, IonButtons, IonContent, IonHeader, IonInput, IonModal, IonProgressBar, IonTitle, IonToolbar } from '@ionic/vue';
import SingleLabel from './SingleLabel.vue';

export default {
    components: {
        IonButton,
        IonButtons,
        IonContent,
        IonHeader,
        IonInput,
        IonModal,
        IonProgressBar,
        IonTitle,
        IonToolbar,
        SingleLabel,
    },
    props: {
        presentingElement: {
            default: null,
        },
    },
    data() {
        return {
            showLabelModal: false,
            newLabel: '',
            loading: false,
        };
    },
    computed: {
        applicant() {
            return this.$store.getters.applicant;
        },
    },
    methods: {
        removeTag(tag) {
            return this.$store.dispatch('updateTags', {
                add: '',
                remove: tag,
            });
        },
        openAddLabel() {
            this.showLabelModal = true;
        },
        cancel() {
            this.showLabelModal = false;
        },
        save() {
            this.loading = true;
            this.$store
                .dispatch('updateTags', {
                    add: this.newLabel,
                    remove: '',
                })
                .then(() => {
                    this.loading = false;
                    this.newLabel = '';
                    this.showLabelModal = false;
                });
        },
    },
};
</script>

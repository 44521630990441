<template>
    <ion-modal
        :is-open="isOpen"
        :initial-breakpoint="0.5"
        :breakpoints="[0.5]"
        :handle="false"
        @willDismiss="() => $emit('willDismiss')"
    >
        <ion-header>
            <ion-toolbar class="white ion-padding-start ion-padding-end">
                <div class="flex justify-between items-center py-1">
                    <p class="text-xl text-blue-700" @click="() => $emit('willDismiss')">Cancel</p>
                    <p class="text-lg text-gray-900">Attachments</p>
                    <p class="text-xl text-blue-700" @click="() => $emit('attachments', images)">Save</p>
                </div>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding bg-gray-300 space-y-2">
            <div class="space-y-1">
                <p class="text-sm text-gray-500">Photos</p>
                <div class="flex space-x-2">
                    <div
                        class="flex-1 h-20 bg-gray-800 flex items-center justify-center text-white text-5xl"
                        @click="takePhoto"
                    >
                        <ion-icon :icon="camera" />
                    </div>
                    <div
                        class="flex-1 h-20 bg-gray-800 flex items-center justify-center text-white text-5xl"
                        @click="pickImages"
                    >
                        <ion-icon :icon="imagesIcon" />
                    </div>
                </div>
            </div>
            <div class="space-y-1">
                <p class="text-sm text-gray-500">Files</p>
                <div class="flex">
                    <div
                        class="w-1/2 pr-2 h-20 bg-gray-800 flex items-center justify-center text-white text-5xl"
                        @click="pickFiles"
                    >
                        <ion-icon :icon="document" />
                    </div>
                </div>
            </div>
            <div class="flex space-x-2 py-2 w-full">
                <div v-for="(image, index) in images" :key="`image-${index}`" class="relative">
                    <div
                        class="absolute top-0 right-0 transform -translate-y-1/4 translate-x-1/4"
                        @click="removeImage(index)"
                    >
                        <ion-icon :icon="closeCircle" class="text-red-600 w-5 h-5" />
                    </div>
                    <ion-skeleton-text v-if="image.uploading" :animated="true" class="w-10 h-10" />
                    <img v-else-if="image.webPath" :src="image.webPath" alt="" class="w-10 h-10" />
                    <div v-else class="w-10 h-10 bg-gray-300 flex items-center justify-center">
                        <ion-icon :icon="documentOutline" />
                    </div>
                </div>
            </div>
        </ion-content>
    </ion-modal>
</template>
<script>
import { IonContent, IonHeader, IonIcon, IonModal, IonSkeletonText, IonToolbar } from '@ionic/vue';
import { camera, closeCircle, document, documentOutline, images } from 'ionicons/icons';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';
import { FilePicker } from '@capawesome/capacitor-file-picker';

export default {
    name: 'AttachmentsModal',
    components: {
        IonContent,
        IonHeader,
        IonIcon,
        IonModal,
        IonSkeletonText,
        IonToolbar,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        attachments: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            camera,
            closeCircle,
            document,
            documentOutline,
            imagesIcon: images,
            images: [],
        };
    },
    watch: {
        attachments: {
            deep: true,
            handler(val) {
                this.images = val;
            },
        },
    },
    methods: {
        takePhoto() {
            Camera.getPhoto({
                resultType: CameraResultType.Uri,
                source: CameraSource.Camera,
                quality: 100,
            }).then((result) => {
                this.addImage(result);
            });
        },
        pickImages() {
            Camera.pickImages({
                quality: 100,
            }).then(({ photos }) => {
                if (photos && photos.length) {
                    this.addImage(photos);
                }
            });
        },
        pickFiles() {
            FilePicker.pickFiles().then(({ files }) => {
                this.addImage(files, true);
            });
        },
        addImage(image, files = false) {
            if (Array.isArray(image)) {
                this.images.push(...image);
                this.$nextTick(() => {
                    image.forEach((i) => {
                        this.createBlob(i, files);
                    });
                });
            } else {
                this.images.push(image);
                this.$nextTick(() => {
                    this.createBlob(image, files);
                });
            }
        },
        createBlob(image, file = false) {
            const path = image.path ? image.path : image.webPath;
            Filesystem.readFile({ path })
                .then(({ data }) => {
                    // iOS & Android
                    let url = `data:image/${image.format};base64,${data}`;
                    if (file) {
                        url = `data:${image.mimeType};base64,${data}`;
                    }
                    fetch(url).then((response) => {
                        this.storeImageBlob(response, path);
                    });
                })
                .catch(() => {
                    // Web
                    fetch(path).then((response) => {
                        this.storeImageBlob(response, path);
                    });
                });
        },
        storeImageBlob(response, path) {
            response.blob().then((blob) => {
                const index = this.images.findIndex((i) => i.path === path || i.webPath === path);
                if (index !== -1) {
                    this.images[index].blob = blob;
                    const split = path.split('/');
                    const name = split.pop();
                    this.images[index].name = name;
                    this.images[index].uploading = true;
                    this.$store.dispatch('uploadAttachment', this.images[index]).then((id) => {
                        this.images[index].uploading = false;
                        this.images[index].id = id;
                    });
                }
            });
        },
        removeImage(index) {
            this.images.splice(index, 1);
        },
    },
};
</script>

<template>
    <card-template
        cardClass="bg-indigo-50 hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-sms class="text-gray-700 w-4 h-4" />
        </template>

        <template #title>
            SMS received
        </template>
        <template #actions>
            <tr-button-simple
                size="sm"
                type="secondary"
                @click="reply"
            >
                <div class="flex items-center gap-x-2">
                    <icon-paper-plane class="w-3 h-3" />
                    <span>Reply</span>
                </div>
            </tr-button-simple>
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="inline-block text-blackText whitespace-pre-wrap" v-html="data.text"></div>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineSms from '@src/Ats/ActivityFeed/vue/Icon/TimelineSms';
import IconPaperPlane from "@src/Common/Ui/Components/Icons/vue/solid/paper-plane";
import { mapGetters } from 'vuex';
import ActionSectionMixin from '@src/Ats/Dialer/vue/Actions/ActionSectionMixin';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelineSms,
        IconPaperPlane
    },
    mixins: [
        ActionSectionMixin
    ],
    props: ['data', 'readonly'],
    computed: {
        ...mapGetters(['applicantUi']),
    },
    methods: {
        reply() {
            this.applicantUi.actionTab = "sms";

            this.$nextTick(() => {
                this.focusSmsBody();
            });
        },
    }
};
</script>

<template>
    <card-template
        cardClass="bg-indigo-50 hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-application class="text-gray-700 w-5 h-5" />
        </template>
        <template #title>
            Driver sent a short-form application
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="flex flex-col items-start gap-y-1">
            <div
                class="inline-block px-2 py-0 text-xs rounded bg-green-100 border-green-300 text-green-800 border"
            >
                {{ data.app_type_human }}
            </div>
            <div class="flex flex-col gap-y-0.5 text-xs text-gray-700">
                <div v-if="data.job_title">
                    Job: <a :href="data.public_url " target="_blank" class="font-bold">{{ data.job_title }}</a>
                </div>
                <div v-if="data.location">
                    {{ data.location }}
                </div>
                <div v-if="data.app_type === 'call' && data.job_phone">
                    Job phone number: {{ data.job_phone }}
                </div>
            </div>
        </div>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineApplication from '@src/Ats/ActivityFeed/vue/Icon/TimelineApplication';

export default {
    components: {
        TimelineApplication,
        CardMenu,
        CardTemplate
    },
    props: ['data', 'readonly'],
    computed: {
        states() {
            return _.join(this.data.locations, ', ');
        },
    },
};
</script>

import FeedTab from './vue/Feed.vue';

const routes = [
    {
        path: '/tabs/',
        children: [
            {
                path: 'feed',
                name: 'FeedTab',
                component: FeedTab,
            },
        ],
    },
];

routes.forEach((route) => window.routes.add(route));

<template>
    <div ref="wrapper" class="relative">
        <ion-input ref="ionInput" :class="$attrs.class" :placeholder="$attrs.placeholder" @ionChange="inputChanged" />
    </div>
</template>
<script>
import { IonInput } from '@ionic/vue';

export default {
    name: 'TrGooglePlaces',
    components: {
        IonInput,
    },
    inheritAttrs: false,
    props: {
        placement: {
            type: String,
            default: 'bottom',
        },
        fields: {
            type: [String, Array],
            default: () => [
                'address_components',
                'adr_address',
                'alt_id',
                'formatted_address',
                'geometry',
                'icon',
                'id',
                'name',
                'business_status',
                'photo',
                'place_id',
                'scope',
                'type',
                'url',
                'vicinity',
            ],
        },
        types: {
            type: [String, Array],
            default: () => ['locality', 'administrative_area_level_3', 'sublocality_level_1', 'neighborhood'],
        },
        country: { type: [String, Array], default: '' },
        value: {
            type: [String, Object],
            default: () => {},
        },
    },
    data() {
        return {
            autocomplete: null,
        };
    },
    mounted() {
        setTimeout(() => {
            this.$refs.ionInput.$el.getInputElement().then((input) => {
                const options = {
                    types: typeof this.types === 'string' ? [this.types] : this.types,
                    fields: this.fields,
                };
                if (this.country) {
                    options.componentRestrictions = {
                        country: this.country,
                    };
                }
                this.autocomplete = new google.maps.places.Autocomplete(input, options);
                this.autocomplete.addListener('place_changed', this.onPlaceChanged);
                if (this.placement === 'top') {
                    setTimeout(() => {
                        const pacContainer = document.querySelector('.pac-container');
                        pacContainer.classList.add('top');
                        this.$refs.wrapper.prepend(pacContainer);
                    }, 300);
                }

                if (this.value && typeof this.value === 'object') {
                    input.value = `${this.value.locality}, ${this.value.administrative_area_level_1}`;
                } else if (this.value && typeof this.value === 'string') {
                    input.value = this.value;
        }
            });
        }, 200);
    },
    methods: {
        onPlaceChanged() {
            const place = this.autocomplete.getPlace();
            const formattedResult = this.formatResult(place);
            this.$refs.ionInput.$el.getInputElement().then((input) => {
                input.value = `${formattedResult.locality}, ${formattedResult.administrative_area_level_1}`;
            });
            this.$emit('ionChange', { detail: { value: formattedResult } });
        },
        formatResult(place) {
            const components = place.address_components;
            const name = components[0].short_name;
            const state = components.find(
                (single) => single.types.findIndex((type) => type === 'administrative_area_level_1') !== -1,
            ).short_name;
            return {
                locality: name,
                administrative_area_level_1: state,
                latitude: place.geometry.location.lat(),
                longitude: place.geometry.location.lng(),
            };
        },
        inputChanged({ detail }) {
            const { value } = detail;
            if (!value) {
                this.$emit('ionChange', { detail: { value: '' } });
            }
        }
    },
};
</script>

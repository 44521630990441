<template>
    <div class="flex flex-wrap">
        <!-- Location -->
        <single-tag
            :icon="location"
            :colors="{
                icon: 'text-blue-400',
                background: 'bg-blue-100',
                text: 'text-blue-800',
            }"
        >
            {{ applicant.location }}
        </single-tag>
        <!-- Experiences -->
        <single-tag
            :icon="star"
            :colors="{
                icon: 'text-green-400',
                background: 'bg-green-100',
                text: 'text-green-800',
            }"
        >
            {{ cdlXp }}
        </single-tag>
        <!-- Owner operator -->
        <single-tag
            v-if="applicant.profile.is_owner_operator"
            :icon="person"
            :colors="{
                icon: 'text-yellow-400',
                background: 'bg-yellow-100',
                text: 'text-yellow-800',
            }"
        >
            Owner Operator
        </single-tag>
        <!-- Equipments -->
        <single-tag
            v-for="equipment in equipments"
            :key="equipment"
            :icon="trucktrailer"
            :external-icon="true"
            :colors="{
                icon: 'text-gray-400',
                background: 'bg-gray-100',
                text: 'text-gray-800',
            }"
        >
            {{ equipment }}
        </single-tag>
    </div>
</template>
<script>
import { location, person, star } from 'ionicons/icons';
import SingleTag from './SingleTag.vue';

export default {
    name: 'Tags',
    components: {
        SingleTag,
    },
    data() {
        return {
            location,
            person,
            star,
            trucktrailer: require('./../icons/truck-trailer.svg'),
        };
    },
    computed: {
        applicant() {
            return this.$store.getters.applicant;
        },
        options() {
            return this.$store.getters.applicantOptions;
        },
        cdlXp() {
            let xp = '';
            if (this.applicant.profile.cdl_xp_last_3years) {
                xp = this.options.xpmonths.find((x) => x.id === this.applicant.profile.cdl_xp_last_3years).name;
            } else if (this.applicant.profile.cdl_xp) {
                xp = this.options.cdl_xp.find((x) => x.id === this.applicant.profile.cdl_xp).name;
            }
            if (xp) {
                xp = `${xp} Exp.`;
            }
            return xp;
        },
        equipments() {
            const { profile } = this.applicant;
            const result = [];
            if (profile.dry_van_xp && profile.dry_van_xp != 'none') result.push('Dry Van');
            if (profile.flatbed_xp && profile.flatbed_xp != 'none') result.push('Flatbed');
            if (profile.refrigerated_xp && profile.refrigerated_xp != 'none') result.push('Refrigerated');
            if (profile.tanker_xp && profile.tanker_xp != 'none') result.push('Tanker');
            if (profile.auto_hauler_xp && profile.auto_hauler_xp != 'none') result.push('Auto Hauler');
            if (profile.hazmat_xp && profile.hazmat_xp != 'none') result.push('HazMat');
            return result;
        },
    },
};
</script>

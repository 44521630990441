<template>
    <div class="h-full">
        <div class="pt-4 space-y-2">
            <div v-for="note in notes" :key="note.id" class="flex space-x-2">
                <tr-avatar
                    size="xs"
                    :color="getAuthor(note.author_id).avatar_color"
                    :name="getAuthor(note.author_id).name"
                />
                <div class="bg-bluegrassPrimaryLight border border-indigo-300 rounded-lg p-4 flex-grow">
                    <div class="text-sm text-white break-all" v-html="note.text"></div>
                    <div v-if="note.images && note.images.length" class="flex mt-2">
                        <div v-for="image in note.images" :ref="`image-${image.id}`" :key="image.id" class="w-14 h-20">
                            <ion-skeleton-text :animated="true" />
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { IonSkeletonText } from '@ionic/vue';
import { chevronDownOutline } from 'ionicons/icons';

export default {
    name: 'Notes',
    components: {
        IonSkeletonText,
    },
    data() {
        return {
            chevronDownOutline,
        };
    },
    computed: {
        applicant() {
            return this.$store.getters.applicant;
        },
        recruiters() {
            return this.$store.getters.recruiters;
        },
        notes() {
            return this.$store.getters.notes;
        },
    },
    watch: {
        notes(val) {
            if (val) {
                setTimeout(() => {
                    const wrapper = document.querySelector('#tabWrapper');
                    wrapper.scrollTop = wrapper.scrollHeight;
                }, 500);
            }
            this.loadImages();
        },
    },
    mounted() {
        this.$store.dispatch('getNotes');
    },
    unmounted() {
        this.$store.commit('setNotes', []);
    },
    methods: {
        loadImages() {
            if (this.notes) {
                this.notes.forEach((note) => {
                    if (note.images) {
                        note.images.forEach((image) => {
                            this.getImageData(image);
                        });
                    }
                });
                // const { images } = this.;
                // images.forEach((image) => this.getImageData(image));
            }
        },
        getImageData(image) {
            const { id, url } = image;
            return axios.get(url, { responseType: 'blob' }).then(({ data }) => {
                this.$refs[`image-${id}`][0].innerHTML = `<img src="${URL.createObjectURL(data)}" class="w-full" />`;
            });
        },
        getAuthor(id) {
            return this.recruiters.find((recruiter) => recruiter.id === id);
        },
    },
};
</script>

<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="white ion-padding-start ion-padding-end">
                <div v-if="applicant" class="flex items-center justify-between">
                    <router-link
                        :to="{ name: 'ApplicantsDetail', params: { id: applicant.id } }"
                        class="flex items-center space-x-2"
                        :class="{
                            'w-full': !applicant,
                        }"
                    >
                        <ion-icon class="text-2xl" :icon="chevronBackOutline" />
                        <p class="text-2xl">Email</p>
                    </router-link>
                    <p class="text-gray-700 text-lg">{{ applicant.name }}</p>
                </div>
            </ion-toolbar>
            <ion-progress-bar v-if="globalLoading || headerLoading" type="indeterminate"></ion-progress-bar>
        </ion-header>
        <ion-content ref="content" :fullscreen="true" class="ion-padding space-y-4">
            <div
                v-for="single in allEmails"
                :key="single.id"
                class="flex flex-col space-y-1"
                :class="{
                    'items-end': single.direction === 'outgoing',
                    'items-start': single.direction === 'incoming',
                }"
            >
                <div
                    class="p-4 border rounded-lg w-full border-indigo-300 text-sm"
                    :class="{
                        'bg-bluegrassPrimaryLight text-white': single.direction === 'outgoing',
                        'bg-indigo-100 text-gray-800': single.direction === 'incoming',
                    }"
                >
                    <p
                        :class="{
                            'text-gray-200': single.direction === 'outgoing',
                            'text-gray-700': single.direction === 'incoming',
                        }"
                        class="pb-1"
                    >
                        {{ single.subject }}
                    </p>
                    <div v-html="single.message" />
                </div>
                <div class="flex items-center space-x-2 text-xs text-gray-500">
                    <tr-avatar :color="single.avatar_color" :name="single.name" size="xs" />
                    <p>{{ formatDate(single.created_at) }}</p>
                </div>
            </div>
            <!-- Templates modal -->
            <templates-modal
                :is-open="templatesOpened"
                @resolve="resolveTemplate"
                @willDismiss="templatesOpened = false"
            />
            <!-- New email modal -->
            <new-email-modal
                :is-open="newEmailOpened"
                :text="body"
                :subject="subject"
                :attachments="attachments"
                @openTemplates="templatesOpened = true"
                @openAttachments="attachmentsOpened = true"
                @willDismiss="newEmailDismissed"
            />
            <!-- Attachments modal -->
            <attachments-modal
                :is-open="attachmentsOpened"
                :attachments="attachments"
                @attachments="addAttachments"
                @willDismiss="attachmentsOpened = false"
            />
        </ion-content>
        <ion-footer>
            <ion-toolbar class="white ion-padding-start ion-padding-end">
                <div class="flex items-center justify-end space-x-2">
                    <div
                        class="w-8 h-8 text-lg rounded-full bg-bluegrassPrimary text-white flex items-center justify-center"
                    >
                        <ion-icon :icon="documentTextOutline" @click="templatesOpened = true" />
                    </div>
                    <div
                        class="flex items-center space-x-1 text-sm font-semibold text-white bg-bluegrassPrimary rounded-full px-4 py-1"
                        @click="newEmailOpened = true"
                    >
                        <pencil-icon class="w-4" />
                        <p>Compose email</p>
                    </div>
                </div>
            </ion-toolbar>
        </ion-footer>
    </ion-page>
</template>
<script>
import { IonFooter, IonHeader, IonContent, IonIcon, IonPage, IonProgressBar, IonToolbar } from '@ionic/vue';
import { chevronBackOutline, close, documentTextOutline, ellipsisHorizontal, paperPlane } from 'ionicons/icons';
import PencilIcon from './icons/pencil.vue';
import TemplatesModal from './TemplatesModal.vue';
import NewEmailModal from './NewEmail.vue';
import AttachmentsModal from './AttachmentsModal.vue';

export default {
    name: 'ApplicantsEmail',
    components: {
        IonFooter,
        IonHeader,
        IonContent,
        IonIcon,
        IonPage,
        IonProgressBar,
        IonToolbar,
        PencilIcon,
        TemplatesModal,
        NewEmailModal,
        AttachmentsModal,
    },
    data() {
        return {
            chevronBackOutline,
            close,
            documentTextOutline,
            ellipsisHorizontal,
            paperPlane,
            templatesOpened: false,
            body: '',
            subject: '',
            newEmailOpened: false,
            attachmentsOpened: false,
            attachments: [],
        };
    },
    computed: {
        applicant() {
            return this.$store.getters.applicant;
        },
        allEmails() {
            return this.$store.getters.emails;
        },
        emailTemplates() {
            return this.$store.getters.emailTemplates;
        },
    },
    watch: {
        allEmails() {
            setTimeout(() => {
                this.$refs.content.$el.scrollToBottom();
            }, 200);
        },
    },
    methods: {
        formatDate(date) {
            const luxonDate = this.$luxon.fromString(date);
            return this.$luxon.formatDatetimeShort(luxonDate);
        },
        resolveTemplate(id) {
            this.setHeaderLoading(true);
            axios
                .post(`${apiUrl}/mobile-app/message-templates/preview`, {
                    applicantId: this.applicant.id,
                    template: id,
                })
                .then(({ data }) => {
                    const { body, subject } = data.resolved;
                    this.body = body;
                    this.subject = subject;
                    this.setHeaderLoading(false);
                    this.templatesOpened = false;
                    this.newEmailOpened = true;
                })
                .catch(() => {});
        },
        newEmailDismissed() {
            this.newEmailOpened = false;
            this.subject = '';
            this.body = '';
        },
        addAttachments(attachments) {
            this.attachments = attachments;
            this.attachmentsOpened = false;
        },
    },
    ionViewWillEnter() {
        this.$store.dispatch('getEmails');
        this.$store.dispatch('getTemplates');
    },
    ionViewWillLeave() {
        this.$store.commit('setEmails', []);
    },
};
</script>

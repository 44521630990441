import Tribute from 'tributejs';
const emoji = require('emoji.json')

const emojiMentionMixin = {
    data() {
        return {
            tributeInstance: null,
            tributeQuerySelector: "textarea",
            emoji: emoji,
        }
    },
    computed: {
        tributeTarget() {
            return this.$el.querySelector(this.tributeQuerySelector);
        }
    },
    mounted() {
        const emojiMention = {
            trigger: ':',
            lookup: 'name',
            allowSpaces: false,
            requireLeadingSpace: true,
            containerClass: 'tribute-container bg-white border border-gray-300 rounded-md p-2 z-50',
            itemClass: 'bg-white cursor-pointer py-2 px-3 border border-transparent',
            menuItemTemplate: function (item) {
                return item.original.char + ' '+ item.string;
            },
            selectClass: 'tribute-selected',
            selectTemplate: item => item.original.char,
            values: this.emoji,
            menuItemLimit: 10,
            searchOpts: {
                pre: '<span class="font-bold">',
                post: '</span>',
                skip: false
            },
            noMatchTemplate: function () {
                return "<span>Couldn't find your emoji</span>";
            }
        };

        this.tributeInstance = new Tribute({ collection: [emojiMention]});
        this.tributeInstance.attach(this.tributeTarget);

        this.tributeTarget
            .addEventListener("tribute-replaced", this.trackEmojiSelected);
    },
    beforeDestroy() {
        this.tributeTarget.removeEventListener("tribute-replaced", this.trackEmojiSelected);

        if (this.tributeInstance) {
            this.tributeInstance.detach(this.tributeTarget);
        }
    },
    methods: {
        trackEmojiSelected(event) {
            axios.post(route('user-emoji.clicked'), {
                emoji: event.detail.item.original.char,
                code: event.detail.item.original.codes
            }).then(() => {
            });
        },
    },
};

export default emojiMentionMixin;

<template>
    <div
        v-if="show"
        v-click-outside="
            () => {
                $emit('close');
            }
        "
        class="absolute left-0 z-50 w-screen max-w-md p-2 mt-2 bg-white border rounded-md shadow-lg top-full broder-gray-300"
    >
        <div class="flex flex-col space-y-2 intro-variables" data-position="left">
            <div class="flex items-center justify-between pb-2 border-b border-gray-300">
                <div class="text-sm text-gray-500">{{ title }}</div>
                <div class="flex items-center space-x-2">
                    <tr-x class="w-5 h-5 text-gray-700 cursor-pointer" @click.native="$emit('close')" />
                </div>
            </div>
            <div class="space-y-2">
                <div
                    v-for="variable in variables"
                    :key="`variable-${variable.name}`"
                >
                    <div
                        v-if="isBulk || variable.value"
                        class="px-2 py-1 rounded-md cursor-pointer hover:bg-indigo-50"
                        @click="$emit('choose', variable.name)"
                    >
                        <p v-if="isBulk" class="text-sm text-gray-900 truncate font-bold">
                            <span class="text-gray-900 font-normal" v-html="sanitize(variable.description)"></span>
                        </p>

                        <template v-else>
                            <p class="text-sm text-gray-900 truncate font-bold" v-if="variable.name !== 'application_link'">
                                <template v-if="! isBulk">
                                    {{ variable.value }} - 
                                </template>
                                <span class="text-gray-500 font-normal" v-html="sanitize(variable.description)"></span>
                            </p>

                            <p class="text-sm text-gray-900 truncate font-bold" v-if="variable.name === 'application_link'">
                                Full DOT Application Form Link
                                <template v-if="! isBulk">
                                    - <span class="text-gray-500 font-normal" >{{ variable.value }}</span>
                                </template>
                            </p>
                        </template>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    props: {
        variables: {
            type: Array,
            default: () => [],
        },
        show: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        isBulk: {
            type: Boolean,
            default: false,
        },
    },
    methods: {
        sanitize(input) {
            return input.replace(/<\/?[^>]+(>|$)/g, ' ');
        },
    },
};
</script>

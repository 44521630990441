<template>
    <div>
    </div>
</template>
<script>
import { actionSheetController } from '@ionic/vue';
import fieldsMixin from './fieldsMixin';

export default {
    name: 'BooleanField',
    mixins: [fieldsMixin],
    mounted() {
        actionSheetController.create({
            header: this.profileEditing.label,
            buttons: [
                {
                    text: 'Yes',
                    role: this.profile[this.profileEditing.value] === true ? 'selected' : '',
                    data: {
                        action: 'yes',
                    },
                },
                {
                    text: 'No',
                    role: this.profile[this.profileEditing.value] === false ? 'selected' : '',
                    data: {
                        action: 'no',
                    },
                },
                {
                    text: 'Cancel',
                    role: 'cancel',
                    data: {
                        action: 'cancel',
                    }
                }
            ]
        }).then((alert) => {
            alert.present();
            alert.onDidDismiss().then(({ role }) => {
                this.cancel();
            });
        });
    },
}
</script>

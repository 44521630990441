<template>
    <div class="flex flex-grow">
        <div :class="wrapperClasses">
            <preview-button
                v-if="previewable"
                :preview-opened="previewOpened"
                @click.native="showPreview"
            />
            <div class="flex flex-col">
                <div class="relative flex-grow flex-shrink-0 w-full">
                    <tr-textarea
                        v-model="localValue"
                        :placeholder="placeholder"
                        :classes="styles.textarea"
                    />
                </div>
                <p v-if="hasErrors" class="text-sm text-red-500">
                    {{ errorMessage }}
                </p>
            </div>
        </div>
 
        <div
            v-if="previewOpened"
            :class="previewClasses"
        >{{ preview }}</div>
    </div>
</template>

<script>
import PreviewButton from '@src/Messaging/Templates/vue/partials/PreviewButton.vue';
import emojiMentionMixin from '@src/Messaging/Templates/vue/Content/emojiMentionMixin';

export default {
    components: { PreviewButton },
    mixins: [
        emojiMentionMixin
    ],
    props: {
        value: {
            type: String,
            default: '',
        },
        errors: {
            type: Array,
            default: () => [],
        },
        placeholder: {
            type: String,
            default: null,
        },
        previewable: {
            type: Boolean,
            default: true,
        },
        classes: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            localValue: this.value,
            previewOpened: false,
            preview: '',
            previewTimeout: null,
        };
    },
    computed: {
        hasErrors() {
            return this.errors.length > 0;
        },
        errorMessage() {
            if (this.hasErrors) {
                return this.errors.join(', ');
            }
            return null;
        },
        styles() {
            let defaultStyle = {
                wrapper: 'relative flex flex-col bg-gray-50',
                wrapperWidth: 'w-full',
                textarea: {
                    input: 'w-full px-3 py-2 text-sm h-52 shadow-sm border border-gray-300 focus:border-gray-300 rounded-md outline-none'
                },
                preview: 'flex py-4 px-6 border-t border-b border-r border-gray-300 rounded-tr-md rounded-br-md bg-gray-50 text-sm whitespace-pre-wrap overflow-clip overflow-hidden',
                previewWidth: '',
            };

            if(this.previewable) {
                // fixed width so expanded preview looks okay in <tr-modal>
                defaultStyle = _.merge(defaultStyle, {
                    wrapperWidth: 'w-96 2xl:w-120',
                    previewWidth: 'w-80 2xl:w-120',
                })
            }

            return _.merge(defaultStyle, this.classes);
        },
        wrapperClasses() {
            const classes = [];
            classes.push(this.styles.wrapper);
            classes.push(this.styles.wrapperWidth);
        
            if(this.hasErrors) {
                classes.push('has-errors');
            }

            return classes.join(' ');
        },
        previewClasses() {
            const classes = [];
            classes.push(this.styles.preview);
            classes.push(this.styles.previewWidth);

            return classes.join(' ');
        },
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                this.localValue = value;
            },
        },
        localValue(localValue) {
            this.$emit('input', localValue);
            this.debouncedPreview();
        },
    },
    methods: {
        showPreview() {
            this.previewOpened = !this.previewOpened;
            this.reloadPreview();
        },
        reloadPreview() {
            if (this.previewOpened) {
                axios
                    .post(route('message-templates.preview'), {
                        body: this.localValue,
                    })
                    .then(({ data }) => {
                        const { resolved } = data;
                        const { body } = resolved;
                        this.preview = body;
                    });
            }
        },
        debouncedPreview() {
            if (this.previewTimeout) {
                clearTimeout(this.previewTimeout);
            }
            this.previewTimeout = setTimeout(() => {
                this.reloadPreview();
            }, 600);
        },
    },
};
</script>

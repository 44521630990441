export default {
    computed: {
        profile() {
            const { applicant } = this.$store.getters;
            if (applicant && applicant.profile) {
                return applicant.profile;
            }
            return null;
        },
        profileEditing() {
            return this.$store.getters.profileEditing;
        },
    },
    methods: {
        cancel() {
            this.$store.commit('setProfileEditing', null);
        },
        save(tableRecord = false) {
            if (tableRecord) {
                this.$store.dispatch('storeProfileTableRecord').then(() => {
                    this.cancel();
                });
            } else {
                this.$store.dispatch('storeProfileField').then(() => {
                    this.cancel();
                });
            }
        },
    },
};

import Cleave from 'cleave.js';

const globalMixin = {
    directives: {
        cleave: {
            created: (el, binding) => {
                if (binding.value) {
                    setTimeout(() => {
                        el.cleave = new Cleave(el, binding.value || {});
                    }, 200);
                }
            },
            update: (el) => {
                if (el.cleave) {
                    const event = new Event('input', { bubbles: true });
                    setTimeout(() => {
                        el.value = el.cleave.properties.result;
                        el.dispatchEvent(event);
                    }, 100);
                }
            },
        },
    },
    computed: {
        globalLoading() {
            return this.$store.getters.globalLoading;
        },
        headerLoading() {
            return this.$store.getters.headerLoading;
        },
    },
    methods: {
        setGlobalLoading(val) {
            this.$store.commit('setGlobalLoading', val);
        },
        setHeaderLoading(val) {
            this.$store.commit('setHeaderLoading', val);
        },
    },
};

export default globalMixin;

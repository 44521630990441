<template>
    <div class="flex flex-col px-4">
        <div class="flex justify-between items-center py-1">
            <p class="text-xl text-blue-700" @click="cancel">Cancel</p>
            <p class="text-lg text-gray-900">Address</p>
            <button
                class="text-xl"
                :class="{ 'text-gray-300': headerLoading, 'text-blue-700': !headerLoading }"
                :disabled="headerLoading"
                @click="save(true)"
            >
                Save
            </button>
        </div>
        <div v-if="localAddress" class="flex flex-col space-y-1 py-2">
            <div class="flex items-center space-x-4">
                <p class="text-sm w-2/5">Start Date</p>
                <ion-input
                    v-cleave="{
                        date: true,
                        datePattern: ['m', 'Y'],
                    }"
                    inputmode="numeric"
                    class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                    :value="localAddress.period_from"
                    placeholder="mm/yyyy"
                    @ionChange="(event) => updateValue(event, 'period_from')"
                />
            </div>
            <div class="flex items-center space-x-4">
                <p class="text-sm w-2/5">End Date</p>
                <ion-input
                    v-cleave="{
                        date: true,
                        datePattern: ['m', 'Y'],
                    }"
                    inputmode="numeric"
                    class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                    :value="localAddress.period_to"
                    placeholder="mm/yyyy"
                    @ionChange="(event) => updateValue(event, 'period_to')"
                />
            </div>
            <div class="flex items-center space-x-4">
                <p class="text-sm w-2/5">Street Address</p>
                <ion-input
                    class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                    :value="localAddress.street_1"
                    placeholder="..."
                    @ionChange="(event) => updateValue(event, 'street_1')"
                />
            </div>
            <div class="flex items-center space-x-4">
                <p class="text-sm w-2/5">Street Address (line 2)</p>
                <ion-input
                    class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                    :value="localAddress.street_2"
                    placeholder="..."
                    @ionChange="(event) => updateValue(event, 'street_2')"
                />
            </div>
            <div class="flex items-center space-x-4">
                <p class="text-sm w-2/5">City</p>
                <tr-google-places
                    class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                    :value="localAddress.city"
                    placeholder="..."
                    placement="top"
                    country="us"
                    @ionChange="(event) => updateValue(event, 'city')"
                />
            </div>
            <div class="flex items-center space-x-4">
                <p class="text-sm w-2/5">Zip Code</p>
                <ion-input
                    type="number"
                    class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                    :value="localAddress.zip_code"
                    placeholder="mm/yyyy"
                    @ionChange="(event) => updateValue(event, 'zip_code')"
                />
            </div>
        </div>
    </div>
</template>
<script>
import { IonInput } from '@ionic/vue';
import fieldsMixin from './fieldsMixin';

export default {
    name: 'AddressField',
    components: {
        IonInput,
    },
    mixins: [fieldsMixin],
    data() {
        return {
            localAddress: null,
        };
    },
    computed: {},
    mounted() {
        this.localAddress = { ...this.profile.last_address };
    },
    methods: {
        updateValue({ detail }, key) {
            const { value } = detail;
            const { profileEditing } = this;
            let finalValue = value;
            const newValue = profileEditing.newValue ?? {
                field: 'address',
                value: { ...this.localAddress },
            };
            if (key === 'city') {
                finalValue = `${value.locality}, ${value.administrative_area_level_1}`;
            }
            newValue.value[key] = finalValue;
            this.localAddress[key] = finalValue;
            this.$store.commit('setProfileEditing', { ...profileEditing, newValue });
        },
    },
};
</script>

<template>
    <div class="flex flex-col text-sm space-y-1 min-w-60 p-2 select-none">
        <div class="w-full px-3 py-1 h-9 flex items-center space-x-2 border border-solid border-white hover:bg-gray-50 hover:border-gray-300 text-gray-700 rounded cursor-pointer" @click="toggle">
            <tr-eye-solid v-if="active" class="w-6 h-6" />
            <tr-eye v-else class="w-6 h-6" />
            <div>{{ active ? 'Stop watching' : 'Start watching' }}</div>
        </div>
        <hr class="h-px border-none bg-gray-300 m-0" />
        <div v-if="watching.length" class="flex flex-col space-y-1">
            <div class="text-xs px-3 mt-3">WATCHING THIS APPLICANT</div>
            <div
                v-for="user in watching"
                :key="user.id"
                class="flex space-x-2 justify-between items-center px-3 py-2 rounded-md border border-solid border-white hover:bg-gray-50 hover:border-gray-300 cursor-pointer"
            >
                <div class="flex space-x-2 items-center">
                    <tr-avatar
                        :name="user.name"
                        :color="user.avatar_color || 'bg-gray-300'"
                        :tooltip="!!user.name"
                        size="sm"
                    />
                    <div>{{ user.name }}</div>
                </div>
                <div @click="remove(user.id)">
                    <tr-x class="w-4 h-4 text-gray-500" />
                </div>
            </div>
        </div>
        <div v-else class="flex flex-col items-center space-y-2 py-1">
            <no-watchers-icon />
            <div>No watchers yet</div>
        </div>
        <hr class="h-px border-none bg-gray-300 m-0" />
        <div v-if="show.search" v-click-outside="close" class="flex flex-col">
            <div @click="showSearch(term)">
                <tr-input type="search" input-style="h-9" :inline="true" v-model="term" @input="search" @esc="show.search = false" />
            </div>
            <search-result v-model="list" :open-popover="openPopover" @selected="add" />
        </div>
        <div v-else class="w-full px-3 py-1 h-9 flex items-center space-x-2 border border-solid border-white hover:bg-gray-50 hover:border-gray-300 text-gray-700 rounded cursor-pointer" @click="showSearch">
            <tr-plus class="w-6 h-6" />
            <div>Add watchers</div>
        </div>
    </div>
</template>

<script>
import delayedCloseMixin from '@src/Common/Ui/Components/Inputs/vue/helpers/delayedCloseMixin';
import focusInputMixin from '@src/Common/Ui/Components/Inputs/vue/helpers/focusInputMixin';
import { mapGetters } from 'vuex';
import TrEye from '@src/Common/Ui/Components/Icons/vue/outline/eye.vue';
import TrEyeSolid from '@src/Common/Ui/Components/Icons/vue/solid/eye.vue';
import TrPlus from '@src/Common/Ui/Components/Icons/vue/outline/plus.vue';
import NoWatchersIcon from './NoWatchersIcon';
import SearchResult from './SearchResult';

export default {
    components: {
        TrEye,
        TrEyeSolid,
        TrPlus,
        NoWatchersIcon,
        SearchResult,
    },
    mixins: [ delayedCloseMixin, focusInputMixin ],
    data() {
        return {
            show: {
                search: false,
                list: false,
            },
            term: '',
        }
    },
    computed: {
        ...mapGetters(['me', 'applicantActivityFeed', 'applicantId']),
        recruiters() {
            return this.$store.getters.globals('dropdowns.recruiters');
        },
        active() {
            return this.applicantActivityFeed.watchers.includes(this.me.user.id);
        },
        styles() {
            return [
                this.active || this.applicantActivityFeed.watchers.length ? 'text-bluegrassPrimary' : 'text-gray-700',
                'hover:bg-gray-50 hover:border-gray-300',
                this.active ? 'bg-indigo-100 border-indigo-300' : 'bg-white border-white',
            ];
        },
        list() {
            return _.take(_.filter(
                this.recruiters,
                (o) => (!this.term || _.lowerCase(o.name).indexOf(_.lowerCase(this.term)) >= 0) && !this.applicantActivityFeed.watchers.includes(o.id)
            ), 5);
        },
        watching() {
            return _.map(this.applicantActivityFeed.watchers, (id) => _.find(this.recruiters, { id: id }));
        }
    },
    methods: {
        toggle() {
            if (this.applicantActivityFeed.watchers.includes(this.me.user.id)) {
                this.remove(this.me.user.id);
            } else {
                this.add(this.me.user.id);
            }
        },
        showSearch() {
            this.show.search = true;
            this.focusChildInput();
            this.search('');
        },
        search(term) {
            this.term = term;
            if (this.show.search && this.list.length) {
                this.showPopover();
            } else {
                this.hidePopover();
            }
        },
        add(id) {
            return axios.post(
                route('applicant.watcher.add',
                { applicantId: this.applicantId }),
                { recruiterId: id }
            ).then(() => {
                this.$set(this.applicantActivityFeed, 'watchers', _.xor(this.applicantActivityFeed.watchers, [id]));
                if (!this.show.search || !this.list.length) this.hidePopover();
                this.focusChildInput();
            });
        },
        remove(id) {
            return axios.post(
                route('applicant.watcher.remove',
                { applicantId: this.applicantId }),
                { recruiterId: id }
            ).then(() => {
                this.$set(this.applicantActivityFeed, 'watchers', _.xor(this.applicantActivityFeed.watchers, [id]));
                this.focusChildInput();
            });
        },
    },
};
</script>

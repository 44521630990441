<template>
    <card-template
        cardClass="bg-white-50 hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-document class="text-gray-700 w-5 h-5" />
        </template>
        <template #title>
            Documents
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <attachment-single 
            :name="data.name" 
            @click.native="(e) => openFile(e)"
        />
    </card-template>
</template>

<script>
import {mapGetters} from "vuex";
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineDocument from '@src/Ats/ActivityFeed/vue/Icon/TimelineDocument';
import AttachmentSingle from '@src/Ats/ActivityFeed/vue/Attachments/AttachmentSingle';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelineDocument,
        AttachmentSingle,
    },
    props: ['data', 'readonly'],
    computed: {
        ...mapGetters(['inlineUrl', 'downloadUrl']),
    },
    methods: {
        openFile(event) {
            if (event.ctrlKey || event.metaKey || event.altKey) {
                window.open(this.inlineUrl + `?dqFileId=${this.data.id}`, '_blank').focus();
            } else {
                window.open(this.downloadUrl + `?dqFileId=${this.data.id}`);
            }
        }
    }
};
</script>

<template>
    <tr-modal
        v-if="ready"
        :default-open="show_modal" 
        size="lg"
        @close="$emit('close')"
    >
        <template #title>
            Auto SMS setup
        </template>

        <div class="flex flex-col gap-y-4">
            <p class="mt-2">Suggested message to be sent to all drivers in <span class="font-medium">{{ form_data.name }}</span></p>

            <detail-actions-auto-sms 
                :form_data="form_data" 
                :lists="lists"
                :errors="errors"
            />

            <tr-callout type="idea">
                {{ callout_idea_text }}
            </tr-callout>
        </div>
        <template #footer>
            <tr-button-simple @click="save">
                Save
            </tr-button-simple>
        </template>
    </tr-modal>
</template>

<script>
import { mapGetters } from 'vuex';
import DetailActionsAutoSms from '@src/Drivers/Worklist/vue/Detail/DetailActionsAutoSms.vue';

export default {
    components: {
        DetailActionsAutoSms,
    },
    props: {
        show_modal: {
            type: Boolean,
            default: false
        },
        worklist_id: {
            type: Number,
            required: true
        },
        sms_body: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            form_data: {},
            errors: {},
            lists: {},

            ready: false,
            callout_idea_text: "We suggest to use variables like {{driver_name}} so that you can personalize messages",
        };
    },
    computed: {
        ...mapGetters(['applicantId']),
    },
    mounted() {
        this.refresh();
    },
    methods: {
        refresh() {
            return axios
                .post(route('worklist.auto-sms.parse', { worklistId: this.worklist_id }), {
                    sms_body: this.sms_body,
                    applicant_id: this.applicantId,
                })
                .then((result) => {
                    if(result.data.form_data) {
                        this.form_data = result.data.form_data
                    };

                    this.lists = result.data.lists;

                    this.$nextTick(() => {
                        this.ready = true;
                    });
                });
        },
        save() {
            return axios
                .post(route('worklist.auto-sms.store', { worklistId: this.worklist_id }), {
                    auto_sms_text: this.form_data.auto_sms_text,
                    auto_sms_phone_number_id: this.form_data.auto_sms_phone_number_id,
                })
                .then((response) => {
                    this.$toast.success('Auto SMS has been set up.');

                    this.$emit('close');
                });
        }
    },
};
</script>

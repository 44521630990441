<template>
    <ion-page>
        <ion-header>
            <ion-toolbar class="white ion-padding-start ion-padding-end">
                <div v-if="applicant" class="flex items-center justify-between">
                    <router-link
                        :to="{ name: 'ApplicantsDetail', params: { id: applicant.id } }"
                        class="flex items-center space-x-2"
                        :class="{
                            'w-full': !applicant,
                        }"
                    >
                        <ion-icon class="text-2xl" :icon="chevronBackOutline" />
                        <p class="text-2xl">SMS</p>
                    </router-link>
                    <p class="text-gray-700 text-lg">{{ applicant.name }}</p>
                </div>
            </ion-toolbar>
            <ion-progress-bar v-if="globalLoading || headerLoading" type="indeterminate"></ion-progress-bar>
        </ion-header>
        <ion-content ref="content" :fullscreen="true" class="ion-padding space-y-4">
            <div
                v-for="single in allSms"
                :key="single.id"
                class="flex flex-col space-y-1"
                :class="{
                    'items-end': single.direction === 'outgoing',
                    'items-start': single.direction === 'incoming',
                }"
            >
                <div
                    class="p-4 border rounded-lg w-full border-indigo-300 text-sm"
                    :class="{
                        'bg-bluegrassPrimaryLight text-white': single.direction === 'outgoing',
                        'bg-indigo-100 text-gray-700': single.direction === 'incoming',
                    }"
                >
                    {{ single.text }}
                </div>
                <div class="flex items-center space-x-2 text-xs text-gray-500">
                    <tr-avatar :color="single.avatar_color" :name="single.name" size="xs" />
                    <p>{{ formatDate(single.created_at) }}</p>
                </div>
            </div>
            <!-- Templates modal -->
            <ion-modal
                :is-open="templatesOpened"
                :initial-breakpoint="0.75"
                :breakpoints="[0.75, 1]"
                @willDismiss="templatesOpened = false"
            >
                <ion-header>
                    <ion-toolbar class="white">
                        <div class="flex justify-between items-center py-1">
                            <p class="text-xl text-blue-700" @click="templatesOpened = false">Cancel</p>
                            <p class="text-lg text-gray-900">Sms templates</p>
                            <p class="opacity-0 pointer-events-none">Cancel</p>
                        </div>
                    </ion-toolbar>
                </ion-header>
                <ion-content class="ion-padding">
                    <p class="text-xs text-gray-500 uppercase mb-4">Choose a template</p>
                    <div class="space-y-2">
                        <ion-item v-for="template in smsTemplates" :key="template.id" class="px-0" @click="resolveTemplate(template.id)">
                            <div class="flex flex-col">
                                <p class="text-base">{{ template.name }}</p>
                                <p class="text-sm text-gray-500 truncate pb-2">{{ template.content.body }}</p>
                            </div>
                        </ion-item>
                    </div>
                </ion-content>
            </ion-modal>
        </ion-content>
        <ion-footer>
            <ion-toolbar class="white without-border">
                <div class="w-full flex items-center px-3 space-x-3">
                    <ion-icon
                        :icon="documentTextOutline"
                        class="text-gray-700 text-xl"
                        @click="templatesOpened = true"
                    />
                    <ion-input
                        v-model="sms"
                        placeholder="Start typing"
                        class="bg-gray-100 px-4 notes-input text-base rounded-xl"
                    />
                    <ion-icon
                        :icon="paperPlane"
                        class="text-xl"
                        :class="{
                            'text-gray-400': !sms || sending,
                            'text-bluegrassPrimary': sms && !sending,
                        }"
                        @click="sendSms"
                    />
                </div>
            </ion-toolbar>
        </ion-footer>
    </ion-page>
</template>
<script>
import {
    IonFooter,
    IonHeader,
    IonContent,
    IonIcon,
    IonInput,
    IonItem,
    IonModal,
    IonPage,
    IonProgressBar,
    IonToolbar,
} from '@ionic/vue';
import { chevronBackOutline, documentTextOutline, paperPlane } from 'ionicons/icons';

export default {
    name: 'ApplicantsSms',
    components: {
        IonFooter,
        IonHeader,
        IonContent,
        IonIcon,
        IonInput,
        IonItem,
        IonModal,
        IonPage,
        IonProgressBar,
        IonToolbar,
    },
    data() {
        return {
            chevronBackOutline,
            documentTextOutline,
            paperPlane,
            sms: '',
            sending: false,
            templatesOpened: false,
        };
    },
    computed: {
        applicant() {
            return this.$store.getters.applicant;
        },
        allSms() {
            return this.$store.getters.sms;
        },
        smsTemplates() {
            return this.$store.getters.smsTemplates;
        },
    },
    watch: {
        allSms() {
            setTimeout(() => {
                this.$refs.content.$el.scrollToBottom();
            }, 200);
        },
    },
    methods: {
        sendSms() {
            this.$store.dispatch('sendSms', this.sms).then(() => {
                this.sms = '';
            });
        },
        resolveTemplate(id) {
            this.setHeaderLoading(true);
            axios
                .post(`${apiUrl}/mobile-app/message-templates/preview`, {
                    applicantId: this.applicant.id,
                    template: id,
                })
                .then(({ data }) => {
                    this.sms = data.resolved.body;
                    this.setHeaderLoading(false);
                    this.templatesOpened = false;
                })
                .catch(() => {});
        },
        formatDate(date) {
            const luxonDate = this.$luxon.fromString(date);
            return this.$luxon.formatDatetimeShort(luxonDate);
        },
    },
    ionViewWillEnter() {
        this.$store.dispatch('getSms');
        this.$store.dispatch('getTemplates');
    },
    ionViewWillLeave() {
        this.$store.commit('setSms', []);
    },
};
</script>

<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="ion-padding">
                <div class="">
                    <h1>Home</h1>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { IonContent, IonHeader, IonPage, IonToolbar, IonRouterOutlet } from '@ionic/vue'; 
export default {
    name: 'Home',
    components: {
        IonContent,
        IonPage,
    }
}
</script>

<template>
    <div class="flex flex-col px-4">
        <div class="flex justify-between items-center py-1">
            <p class="text-xl text-blue-700" @click="cancel">Cancel</p>
            <p class="text-lg text-gray-900">Class A License</p>
            <button
                class="text-xl"
                :class="{ 'text-gray-300': headerLoading, 'text-blue-700': !headerLoading }"
                :disabled="headerLoading"
                @click="save(true)"
            >
                Save
            </button>
        </div>
        <div v-if="localLicense && license" class="flex flex-col space-y-1 py-2">
            <div class="flex items-center space-x-4">
                <p class="text-sm w-2/5">Number</p>
                <ion-input
                    class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                    :value="license.number"
                    placeholder="..."
                    @ionChange="(event) => updateValue(event, 'number')"
                />
            </div>
            <div class="flex items-center space-x-4">
                <p class="text-sm w-2/5">Class</p>
                <ion-select
                    interface="popover"
                    class="flex-1 border pl-4 border-gray-300 rounded-md py-1 text-sm"
                    :value="license.class"
                    placeholder="..."
                    @ionChange="(event) => updateValue(event, 'class')"
                >
                    <ion-select-option
                        v-for="singleClass in options.class"
                        :key="singleClass.id"
                        :value="singleClass.id"
                    >
                        {{ singleClass.name }}
                    </ion-select-option>
                </ion-select>
            </div>
            <div class="flex items-center space-x-4">
                <p class="text-sm w-2/5">State</p>
                <ion-select
                    interface="action-sheet"
                    class="flex-1 border pl-4 border-gray-300 rounded-md py-1 text-sm"
                    :value="license.state"
                    placeholder="..."
                    @ionChange="(event) => updateValue(event, 'state')"
                >
                    <ion-select-option v-for="state in statesUS" :key="state.id" :value="state.id">
                        {{ state.name }}
                    </ion-select-option>
                </ion-select>
            </div>
            <div class="flex items-center space-x-4">
                <p class="text-sm w-2/5">License Expiration</p>
                <tr-datepicker
                    class="flex-1"
                    name="License Expiration"
                    :value="license.license_expiration"
                    @ionChange="(event) => updateValue(event, 'license_expiration')"
                />
            </div>
            <div class="flex items-center space-x-4">
                <p class="text-sm w-2/5">Physical Expiration</p>
                <tr-datepicker
                    class="flex-1"
                    name="Physical Expiration"
                    :value="license.physical_expiration"
                    @ionChange="(event) => updateValue(event, 'physical_expiration')"
                />
            </div>
            <div class="flex space-x-4">
                <p class="text-sm w-2/5">Endorsements</p>
                <div>
                    <ion-item
                        v-for="endorsement in options.endorsements"
                        :key="endorsement.id"
                        lines="none"
                        class="py-0 endorsement"
                    >
                        <ion-checkbox
                            slot="start"
                            class="w-4 h-4"
                            :checked="isChecked(endorsement.id)"
                            @ionChange="(event) => updateValue(event, 'endorsements', endorsement.id)"
                        />
                        <p class="text-sm">{{ endorsement.name }}</p>
                    </ion-item>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
import { IonCheckbox, IonInput, IonItem, IonSelect, IonSelectOption } from '@ionic/vue';
import { statesUS } from '@assets/js/helpers/geo';
import fieldsMixin from './fieldsMixin';

export default {
    name: 'LicenseField',
    components: {
        IonCheckbox,
        IonInput,
        IonItem,
        IonSelect,
        IonSelectOption,
    },
    mixins: [fieldsMixin],
    data() {
        return {
            statesUS,
            localLicense: null,
        };
    },
    computed: {
        options() {
            return this.$store.getters.options;
        },
        license() {
            if (this.localLicense) return this.localLicense;
            return null;
        },
    },
    mounted() {
        this.localLicense = { ...this.profile.last_license };
    },
    methods: {
        isChecked(id) {
            const { endorsements } = this.license;
            if (endorsements) {
                return endorsements.includes(id);
            }
            return false;
        },
        updateValue({ detail }, key, id = null) {
            const { checked, value } = detail;
            const { profileEditing } = this;
            const newValue = profileEditing.newValue ?? {
                field: 'licenses',
                value: { ...this.license },
            };
            let finalValue = this.license[key];
            // Checkboxes
            if (typeof checked !== 'undefined') {
                if (!finalValue) {
                    finalValue = [];
                }
                if (checked) {
                    finalValue.push(id);
                } else {
                    finalValue = finalValue.filter((s) => s !== id);
                }
            } else {
                finalValue = value;
            }
            this.localLicense[key] = finalValue;
            newValue.value[key] = finalValue;
            this.$store.commit('setProfileEditing', { ...profileEditing, newValue });
        },
    },
};
</script>

<template>
    <ion-modal
        :is-open="isOpen"
        @willDismiss="() => $emit('willDismiss')"
        @willPresent="prepareData"
        @didPresent="initFroala"
    >
        <ion-header>
            <ion-toolbar class="white ion-padding-start ion-padding-end">
                <div class="flex items-center justify-between text-2xl">
                    <ion-icon :icon="close" @click="() => $emit('willDismiss')" />
                    <ion-icon :icon="ellipsisHorizontal" />
                </div>
            </ion-toolbar>
            <ion-progress-bar v-if="globalLoading || headerLoading" type="indeterminate"></ion-progress-bar>
        </ion-header>
        <ion-content>
            <div class="h-full flex flex-col pb-10">
                <div class="flex items-center border-b border-gray-300 text-sm py-2 ion-padding-start ion-padding-end">
                    <p class="w-1/5 text-gray-500">To</p>
                    <p class="text-gray-900">{{ applicant.name }}</p>
                </div>
                <div class="flex items-center border-b border-gray-300 text-sm py-2 ion-padding-start ion-padding-end">
                    <p class="w-1/5 text-gray-500">From</p>
                    <p class="text-gray-900">{{ user.name }}</p>
                </div>
                <div class="flex items-center border-b border-gray-300 text-sm ion-padding-start ion-padding-end">
                    <ion-input placeholder="Subject" :value="localSubject" @ionChange="updateSubject" />
                </div>
                <div id="emailFroala" class="w-full flex-grow mb-24"></div>
                <footer ref="footer" class="fixed bottom-0 left-0 w-full border-t border-gray-300">
                    <ion-toolbar class="white without-border">
                        <div class="flex items-center justify-between">
                            <div class="w-full flex items-center px-3 space-x-3 text-gray-700 text-xl">
                                <div class="relative flex items-center">
                                    <p v-if="attachments && attachments.length" class="absolute top-0 right-0 transform translate-x-1/2 -translate-y-1/2 bg-bluegrassPrimary text-white text-xs rounded-full px-2 py-1">{{ attachments.length }}</p>
                                    <ion-icon :icon="attach" @click="() => $emit('openAttachments')" />
                                </div>
                                <ion-icon :icon="documentTextOutline" @click="() => $emit('openTemplates')" />
                                <ion-icon :icon="textIcon" @click="toggleToolbar" />
                            </div>
                            <div class="pr-3 text-xl flex items-center">
                                <ion-icon
                                    :icon="paperPlane"
                                    :class="{
                                        'text-gray-700': !localSubject || !localText,
                                        'text-bluegrassPrimary': localSubject && localText,
                                    }"
                                    @click="send"
                                />
                            </div>
                        </div>
                    </ion-toolbar>
                </footer>
            </div>
        </ion-content>
    </ion-modal>
</template>
<script>
import { IonContent, IonHeader, IonIcon, IonInput, IonModal, IonProgressBar, IonToolbar } from '@ionic/vue';
import { attach, close, documentTextOutline, ellipsisHorizontal, paperPlane, text } from 'ionicons/icons';
import FroalaEditor from 'froala-editor/js/froala_editor.pkgd.min.js';
import 'froala-editor/css/froala_editor.pkgd.min.css';

export default {
    name: 'NewEmailModal',
    components: {
        IonContent,
        IonHeader,
        IonIcon,
        IonInput,
        IonModal,
        IonProgressBar,
        IonToolbar,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
        subject: {
            type: String,
            default: '',
        },
        text: {
            type: String,
            default: '',
        },
        attachments: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        return {
            attach,
            close,
            documentTextOutline,
            ellipsisHorizontal,
            paperPlane,
            textIcon: text,
            localSubject: '',
            localText: '',
            froala: null,
            toolbar: null,
        };
    },
    computed: {
        applicant() {
            return this.$store.getters.applicant;
        },
        user() {
            return this.$store.getters.user;
        },
    },
    watch: {
        subject(val) {
            this.localSubject = val;
        },
        text(val) {
            this.localText = val;
        },
    },
    methods: {
        prepareData() {
            this.localSubject = this.subject;
            this.localText = this.text;
        },
        initFroala() {
            this.froala = new FroalaEditor('#emailFroala', {
                quickInsertTags: [''],
                quickInsertEnabled: false,
                toolbarButtons: [
                    ['bold', 'italic', 'underline'],
                    ['formatOL', 'formatUL', 'variablesDropdown'],
                ],
                htmlRemoveTags: ['script', 'style', 'base'],
                pasteDeniedTags: ['script', 'style', 'base', 'div', 'span', 'dl', 'dt', 'dd'],
                pasteDeniedAttrs: ['class', 'id', 'style', 'dir'],
                toolbarSticky: false,
                toolbarBottom: true,

                // always clean on Word paste (not giving a choice)
                wordPasteModal: false,
                wordPasteKeepFormatting: false,
                placeholderText: 'Compose email',
                attribution: false,
                key: 'xGE6oE4A3J4B11A8C5F5gKTRe1CD1PGb1DESAb1Kd1EBH1Pd1TKoD6C5G5D4G2D3J4B4D6C4==',
                charCounterCount: false,
                events: {
                    initialized: () => {
                        this.toolbar = document.querySelector('.fr-toolbar');
                        const footerHeight = this.$refs.footer.clientHeight;
                        this.toolbar.style.bottom = `${footerHeight}px`;
                        this.froala.html.set(this.localText);
                    },
                    input: () => {
                        this.localText = this.froala.html.get();
                    },
                    'commands.after': () => {
                        this.localText = this.froala.html.get();
                    },
                    'paste.after': () => {
                        this.localText = this.froala.html.get();
                    },
                },
            });
        },
        toggleToolbar() {
            if (this.toolbar.style.display === 'block') {
                this.toolbar.style.display = 'none';
            } else {
                this.toolbar.style.display = 'block';
            }
        },
        updateSubject({ detail }) {
            const { value } = detail;
            this.localSubject = value;
        },
        send() {
            const formData = new FormData();
            formData.append('applicant_ids[]', this.applicant.id);
            formData.append('subject', this.localSubject);
            formData.append('text', this.localText);
            this.attachments.forEach(single => formData.append('attachment_ids[]', single.id));
            this.$store.dispatch('sendEmail', formData).then(() => {
                this.$emit('willDismiss');
            });
        },
    },
};
</script>

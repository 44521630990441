<template>
    <card-template
        cardClass="bg-white-50 hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-phone-out class="text-gray-700 w-5 h-5" />
        </template>
        <template #title>
            Outbound call
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="flex flex-col gap-y-2">
            <div class="text-xs text-gray-700">Call duration {{ data.duration }}</div>

            <div class="flex gap-x-2 items-baseline text-gray-700">
                <span class="text-sm font-medium">Call disposition</span>
                <span class="text-xs">{{ data.disposition }}</span>
                <span>{{ data.reasons }}</span>
            </div>
 
            <template v-if="statusChange">
                <div class="flex gap-x-2 text-gray-700">
                    <span class="text-sm font-medium">Status change</span>
                </div>
                
                <div class="flex gap-x-2 items-center">
                    <span class="text-xs text-gray-700">From</span>
                    <status-pill :status="statusChange.from" :class="Object.values(statusChange.from_colors)" />

                    <icon-arrow-narrow-right class="mx-0.5 text-gray-700" />

                    <span class="text-xs text-gray-700">To</span>
                    <status-pill :status="statusChange.to" :class="Object.values(statusChange.to_colors)" />

                    <div v-if="statusChange.reasons" class="inline-block text-xs text-gray-700">
                        <span v-if="!statusChange.reasons.length" class="italic">(No Reason)</span>
                        <span v-else>
                            Reason:
                            <template v-for="(r, index) in statusChange.reasons">
                                <template v-if="index > 0">, </template>
                                <span :class="{ 'text-red-500': r.show_as_deleted}">{{ r.name }}</span>
                            </template>
                        </span>
                    </div>
                </div>
            </template>
        </div>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelinePhoneOut from '@src/Ats/ActivityFeed/vue/Icon/TimelinePhoneOut';
import StatusPill from '@src/Ats/Dialer/vue/Queue/StatusPill';
import IconArrowNarrowRight from '@src/Common/Ui/Components/Icons/vue/solid/arrow-narrow-right.vue';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelinePhoneOut,
        StatusPill,
        IconArrowNarrowRight
    },
    props: ['data', 'readonly'],
    computed: {
        statusChange() {
            if(this.data.group_card_status) {
                return this.data.group_card_status;
            }

            return null;
        },
    }
}
</script>

<template>
    <div class="grid grid-cols-attachment gap-2 mt-2">
        <attachment 
            v-for="a in attachments"
            :key="a.uuid"
            :name="a.name" 
            :size="a.size" 
            @click.native="(e) => linkTo(a.uuid, e)"
        />
    </div>
</template>
<script>
import Attachment from '@src/Ats/ActivityFeed/vue/Attachments/Attachment';

export default {
    components: {
        Attachment
    },
    props: {
        attachments: {
            type: Array,
            required: true
        },
    },
    methods: {
        attachmentUrl(attachmentId, type = 'inline') {
            return route('email.attachment.' + type) + `?attachmentId=${attachmentId}`;
        },
        linkTo(attachmentId, event) {
            if (event.ctrlKey || event.metaKey || event.altKey) {
                window.open(this.attachmentUrl(attachmentId), '_blank').focus();
            } else {
                window.open(this.attachmentUrl(attachmentId, 'download'));
            }
        },
    },
};
</script>

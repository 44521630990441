<template>
    <card-template
        cardClass="bg-white hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-mail class="text-gray-700 w-5 h-5" />
        </template>

        <template #title>
            <div class="flex items-center gap-x-2">
                <span>Bulk Email</span>

                <template v-if="data.email_failed_at">
                    <tr-tooltip :content="data.email_failed_reason">
                        <message-state state="Failed" />
                    </tr-tooltip>
                </template>
                <template v-else>
                    <message-state state="Scheduled" />
                </template>
            </div>
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="w-full truncate text-gray-500">
            Subject: {{ data.subject }}
        </div>
        <div class="inline-block text-blackText" v-html="data.text"></div>

        <email-attachment-list :attachments="data.attachments" />
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineMail from '@src/Ats/ActivityFeed/vue/Icon/TimelineMail';
import EmailAttachmentList from '@src/Ats/ActivityFeed/vue/Attachments/EmailAttachmentList';
import MessageState from '@src/Ats/ActivityFeed/vue/BulkMessages/MessageState';
import DocumentIcon from '@src/Common/Ui/Components/Icons/vue/outline/document.vue';
import { humanFileSize } from "@src/Ats/DqFiles/vue/helpers.js";

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelineMail,
        EmailAttachmentList,
        MessageState,
        DocumentIcon,
    },
    props: ['data', 'readonly'],
    methods: {
        fileSize(bytes) {
            return humanFileSize(bytes);
        },
        attachmentUrl(attachmentId, type = 'inline') {
            return route('email.attachment.' + type) + `?attachmentId=${attachmentId}`;
        },
        linkTo(attachmentId, event) {
            if (event.ctrlKey || event.metaKey || event.altKey) {
                window.open(this.attachmentUrl(attachmentId), '_blank').focus();
            } else {
                window.open(this.attachmentUrl(attachmentId, 'download'));
            }
        },
    }
};
</script>

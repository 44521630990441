<template>
    <card-template
        cardClass="bg-white hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-user class="text-gray-700 w-5 h-5" />
        </template>
        <template #title>
            Status changed
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="flex gap-x-2 items-center">
            <span class="text-xs text-gray-700">From</span>
            <status-pill :status="data.from" :class="Object.values(data.from_colors)" />

            <icon-arrow-narrow-right class="mx-0.5 text-gray-700" />

            <span class="text-xs text-gray-700">To</span>
            <status-pill :status="data.to" :class="Object.values(data.to_colors)" />

            <div v-if="reasons" class="inline-block text-xs text-gray-700">
                <span v-if="!reasons.length" class="italic">(No Reason)</span>
                <span v-else>
                    Reason:
                    <template v-for="(r, index) in reasons">
                        <template v-if="index > 0">, </template>
                        <span :class="{ 'text-red-500': r.show_as_deleted}">{{ r.name }}</span>
                    </template>
                </span>
            </div>
        </div>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineUser from '@src/Ats/ActivityFeed/vue/Icon/TimelineUser';
import StatusPill from '@src/Ats/Dialer/vue/Queue/StatusPill';
import IconArrowNarrowRight from '@src/Common/Ui/Components/Icons/vue/solid/arrow-narrow-right.vue';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelineUser,
        StatusPill,
        IconArrowNarrowRight
    },
    props: ['data', 'readonly'],
    methods: {
        colorClass(colors) {
            return Object.values(colors);
        }
    },
    computed: {
        reasons() {
            if (!this.data.has_reasons)
                return null;

            return this.data.reasons;
        }
    }
};
</script>

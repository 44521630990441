<template>
    <span class="inline-flex items-center justify-center rounded-full" :class="[color, wrapperStyle, avatarSize]">
        <span class="font-medium leading-none text-white" :class="[textStyle]">{{ initials }}</span>
    </span>
</template>
<script>
export default {
    props: {
        size: {
            type: String,
            default: 'base',
        },
        color: {
            type: String,
            default: 'bg-blue-500',
        },
        name: {
            type: String,
            default: '',
        },
    },
    computed: {
        wrapperStyle() {
            return this.color;
        },
        textStyle() {
            switch (this.size) {
                case 'sm':
                    return 'text-sm';

                case 'xs':
                    return 'text-xs';

                default:
                    return '';
            }
        },
        avatarSize() {
            switch (this.size) {
                case 'sm':
                    return 'w-8 h-8';

                case 'xs':
                    return 'w-6 h-6';

                default:
                    return 'w-10 h-10';
            }
        },
        initials() {
            let chars = '';
            if (this.name) {
                const split = this.name.split(' ');
                const first = split[0];
                chars = first.charAt(0);

                if (split.length > 1) {
                    const last = split.pop();
                    chars += last.charAt(0);
                }
            }
            return chars;
        },
    },
};
</script>

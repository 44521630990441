<template>
    <ion-page>
        <ion-content id="main-content">
            <ion-tabs @ionTabsWillChange="beforeTabChange">
                <ion-router-outlet></ion-router-outlet>
                <ion-tab-bar slot="bottom">
                    <ion-tab-button tab="Home" href="/tabs/home">
                        <ion-icon v-if="currentTab && currentTab === 'Home'" :icon="home"></ion-icon>
                        <ion-icon v-else :icon="homeOutline"></ion-icon>
                        <ion-label>Home</ion-label>
                    </ion-tab-button>
                    <ion-tab-button tab="Applicants" href="/tabs/applicants">
                        <ion-icon v-if="currentTab && currentTab === 'Applicants'" :icon="people"></ion-icon>
                        <ion-icon v-else :icon="peopleOutline"></ion-icon>
                        <ion-label>Applicants</ion-label>
                    </ion-tab-button>
                    <ion-tab-button tab="Jobs" href="/tabs/jobs">
                        <ion-icon v-if="currentTab && currentTab === 'Jobs'" :icon="briefcase"></ion-icon>
                        <ion-icon v-else :icon="briefcaseOutline"></ion-icon>
                        <ion-label>Jobs</ion-label>
                    </ion-tab-button>
                    <ion-tab-button tab="Feed" href="/tabs/feed">
                        <ion-icon v-if="currentTab && currentTab === 'Feed'" :icon="notifications"></ion-icon>
                        <ion-icon v-else :icon="notificationsOutline"></ion-icon>
                        <ion-label>Feed</ion-label>
                    </ion-tab-button>
                    <ion-tab-button tab="Settings" href="/tabs/settings">
                        <ion-icon v-if="currentTab && currentTab === 'Settings'" :icon="settings"></ion-icon>
                        <ion-icon v-else :icon="settingsOutline"></ion-icon>
                        <ion-label>Settings</ion-label>
                    </ion-tab-button>
                </ion-tab-bar>
            </ion-tabs>
        </ion-content>
    </ion-page>
</template>
<script>
import { IonContent, IonIcon, IonLabel, IonPage, IonRouterOutlet, IonTabs, IonTabBar, IonTabButton } from '@ionic/vue';
import {
    briefcase,
    briefcaseOutline,
    fileTray,
    fileTrayOutline,
    home,
    homeOutline,
    notifications,
    notificationsOutline,
    people,
    peopleOutline,
    settings,
    settingsOutline,
} from 'ionicons/icons';

export default {
    name: 'TabsLayout',
    components: {
        IonContent,
        IonIcon,
        IonLabel,
        IonPage,
        IonRouterOutlet,
        IonTabs,
        IonTabBar,
        IonTabButton,
    },
    data() {
        return {
            currentTab: 'Home',
            briefcase,
            briefcaseOutline,
            fileTray,
            fileTrayOutline,
            home,
            homeOutline,
            notifications,
            notificationsOutline,
            people,
            peopleOutline,
            settings,
            settingsOutline,
        };
    },
    computed: {
        authed() {
            return this.$store.getters.authed;
        },
    },
    methods: {
        beforeTabChange(val) {
            this.currentTab = val.tab;
            if (val.tab === 'Applicants') {
                this.$router.replace({ name: 'ApplicantsTab' });
            }
        },
    },
};
</script>

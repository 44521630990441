const ActionSectionMixin = {
    methods: {
        setEmailSubject(subject) {
            document.querySelector("#action-email [data-role=subject]").value = subject;
        },
        focusEmailBody() {
            setTimeout(() => {
                document.querySelector("#action-email .fr-element").focus();
            }, 200);
        },
        focusSmsBody() {
            document.querySelector("#action-sms textarea").focus();
        }
    }
};

export default ActionSectionMixin;

export function humanFileSize(bytes) {
    let localBytes = bytes;
    const thresh = 1000;

    if (Math.abs(bytes) < thresh) {
        return `${bytes} B`;
    }

    const units = ['kB', 'MB', 'GB'];
    let u = -1;
    const r = 10 ** 1;

    do {
        localBytes /= thresh;
        u += 1;
    } while (Math.round(Math.abs(localBytes) * r) / r >= thresh && u < units.length - 1);

    return `${localBytes.toFixed(1)} ${units[u]}`;
}

<template>
    <div 
        class="relative flex px-2" 
        :class="cardClasses"
        :data-key="card.activity_key"
    >
        <div
            class="flex flex-shrink-0 flex-grow-0 justify-center w-7"
            :class="[ card.is_date_first ? 'mt-4' : '' ]"
        >

            <div class="h-full w-0.5 bg-gray-300" aria-hidden="true"></div>
        </div>

        <div class="flex-1 flex-col pl-4 py-4">
            <div class="flex justify-between border-b border-gray-300 pb-1 mb-2 items-center">
                <div class="flex gap-x-2 items-center">
                    <slot name="icon" />
                    <div class="flex font-medium text-sm">
                        <slot name="title" />
                    </div>
                    <span class="text-xs text-gray-500">{{ time }}</span>
                </div>

                <div v-if="!readonly" class="flex gap-x-2">
                    <slot name="actions" />
                    <slot name="menu" />
                </div>
            </div>

            <div>
                <slot />

                <card-emoji-panel
                    v-if="!readonly"
                    :applicant-id="applicantId"
                    :activity-key="card.activity_key"
                    :reactions="card.reactions"
                />
            </div>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IconDotsVertical from './Icons/DotsVertical.vue';
import IconSms from './Icons/Sms.vue';
import IconPhoneOut from './Icons/PhoneOut.vue';
import IconPhoneIn from './Icons/PhoneIn.vue';
import IconPhoneMissed from './Icons/PhoneMissed.vue';
import IconMail from './Icons/Mail.vue';
import IconNote from './Icons/Note.vue';
import IconDocumentText from './Icons/DocumentText.vue';
import IconTag from './Icons/Tag.vue';
import IconDocument from './Icons/Document.vue';
import IconUser from './Icons/User.vue';
import TimelineSms from '@src/Ats/ActivityFeed/vue/Icon/TimelineSms';
import CardEmojiPanel from '@src/Ats/ActivityFeed/vue/Emojis/CardEmojiPanel';

export default {
    components: {
        TimelineSms,
        IconDotsVertical,
        IconSms,
        IconPhoneOut,
        IconPhoneIn,
        IconPhoneMissed,
        IconMail,
        IconNote,
        IconDocumentText,
        IconTag,
        IconDocument,
        IconUser,
        CardEmojiPanel,
    },
    props: [
        'cardClass',
        'time',
        'isMenu',
        'card',
        'readonly',
    ],
    computed: {
        ...mapGetters(['applicantId']),
        cardClasses() {
            if(this.card.activity_key === this.$route.params.activity_key) {
                return "bg-yellow-50 hover:bg-gray-50";
            }

            return this.cardClass;
        }
    },
};
</script>

<template>
    <svg width="24" height="24" viewBox="0 0 24 24" fill="none" xmlns="http://www.w3.org/2000/svg">
        <rect x="2" y="4" width="20" height="18" fill="#E0E7FF"/>
        <path fill-rule="evenodd" clip-rule="evenodd" d="M7.2 0C7.86274 0 8.4 0.537258 8.4 1.2V2.4H15.6V1.2C15.6 0.537258 16.1373 0 16.8 0C17.4627 0 18 0.537258 18 1.2V2.4H20.4C22.3882 2.4 24 4.01178 24 6V20.4C24 22.3882 22.3882 24 20.4 24H3.6C1.61177 24 0 22.3882 0 20.4V6C0 4.01178 1.61178 2.4 3.6 2.4H6V1.2C6 0.537258 6.53726 0 7.2 0ZM6 4.8H3.6C2.93726 4.8 2.4 5.33726 2.4 6V20.4C2.4 21.0627 2.93726 21.6 3.6 21.6H20.4C21.0627 21.6 21.6 21.0627 21.6 20.4V6C21.6 5.33726 21.0627 4.8 20.4 4.8H18V6C18 6.66274 17.4627 7.2 16.8 7.2C16.1373 7.2 15.6 6.66274 15.6 6V4.8H8.4V6C8.4 6.66274 7.86274 7.2 7.2 7.2C6.53726 7.2 6 6.66274 6 6V4.8Z" fill="#374051"/>
        <path d="M13.458 17.138H9.566L8.922 19H6.864L10.378 9.214H12.66L16.174 19H14.102L13.458 17.138ZM12.926 15.57L11.512 11.482L10.098 15.57H12.926Z" fill="#374051"/>
    </svg>
</template>
<script>
export default {};
</script>

const { v4: uuidv4 } = require('uuid');

const store = {
    state() {
        return {
            applicants: [],
            applicant: null,
            options: null,
            profileEditing: null,
            notes: [],
            sms: [],
            emailTemplates: [],
            smsTemplates: [],
            applicantActivityFeed: null,
        };
    },
    getters: {
        applicants: (state) => state.applicants,
        applicant: (state) => state.applicant,
        applicantId: (state) => state.applicant ? state.applicant.id : null,
        applicantSnippet: (state) => {
            if (state.applicant && state.applicant.id) {
                return state.applicant.snippet;
            }
            return null;
        },
        applicantOptions: (state) => state.options,
        options: (state) => state.options,
        profileEditing: (state) => state.profileEditing,
        notes: (state) => state.notes,
        sms: (state) => state.sms,
        emails: (state) => state.emails,
        emailTemplates: (state) => state.emailTemplates,
        smsTemplates: (state) => state.smsTemplates,
        applicantActivityFeed: (state) => state.applicantActivityFeed,
    },
    actions: {
        getApplicants({ commit, getters }) {
            commit('setGlobalLoading', true);
            const { employer } = getters;
            axios
                .post(`${apiUrl}/mobile-app/applicants`, {
                    employerId: employer.id,
                })
                .then(({ data }) => {
                    commit('setApplicants', data.applicants);
                    commit('setGlobalLoading', false);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        getApplicantDetail({ commit, getters }, id = null) {
            let applicantId = id;
            if (!id) {
                applicantId = getters.applicant.id;
            }
            return axios
                .post(`${apiUrl}/mobile-app/applicant-detail`, {
                    applicantId,
                })
                .then(({ data }) => {
                    const { applicant } = data;
                    commit('setApplicant', applicant);
                    commit('setApplicantOptions', data.options);
                    commit('setGlobalLoading', false);
                    commit('setHeaderLoading', false);
                })
                .catch(() => {});
        },
        getActivityFeed({ commit, getters }) {
            const applicantId = getters.applicant.id;
            commit('setHeaderLoading', true);
            return axios.get(`${apiUrl}/mobile-app/applicant/${applicantId}/activity-feed`).then(({ data }) => {
                commit('setActivityFeed', data.activity_feed);
                commit('setHeaderLoading', false);
            });
        },
        refreshApplicants({ commit, getters }, items) {
            const { applicants } = getters;
            items.remove.forEach((item) => {
                const foundIndex = applicants.findIndex((a) => a.id === item);
                const adding = items.add.findIndex((add) => add.id === item);
                if (foundIndex !== -1 && adding === -1) {
                    commit('removeApplicant', foundIndex);
                }
            });
            items.add.forEach((item) => {
                const foundIndex = applicants.findIndex((a) => a.id === item.id);
                if (foundIndex !== -1) {
                    commit('updateApplicant', { index: foundIndex, data: item });
                }
            });
        },
        updateTags({ state, dispatch }, payload) {
            const applicantId = state.applicant.id;
            return axios
                .post(`${apiUrl}/mobile-app/applicant/${applicantId}/tags`, {
                    add: payload.add,
                    remove: payload.remove,
                })
                .then(() => {
                    dispatch('getApplicantDetail', applicantId);
                });
        },
        setNextContact({ state, dispatch }, payload) {
            const applicantId = state.applicant.id;
            return axios
                .post(`${apiUrl}/mobile-app/queue/next-contact/save`, {
                    applicantId,
                    nextCallInUtc: payload.nextCallInUtc,
                    isCustom: payload.isCustom,
                })
                .then(() => dispatch('getApplicantDetail', applicantId));
        },
        updateStatus({ state, dispatch }, payload) {
            const applicantId = state.applicant.id;
            return axios
                .post(`${apiUrl}/mobile-app/applicant/${applicantId}/status`, { value: payload.value })
                .then(() => dispatch('getApplicantDetail', applicantId));
        },
        updateRecruiter({ state, dispatch }, payload) {
            const applicantId = state.applicant.id;
            return axios
                .post(`${apiUrl}/mobile-app/applicant/${applicantId}/recruiter`, { value: payload.value })
                .then(() => dispatch('getApplicantDetail', applicantId));
        },
        getNotes({ commit, getters }) {
            commit('setHeaderLoading', true);
            const applicantId = getters.applicant.id;
            axios
                .get(`${apiUrl}/mobile-app/note`, { params: { applicantId } })
                .then(({ data }) => {
                    commit('setNotes', data.notes);
                    commit('setHeaderLoading', false);
                })
                .catch(() => {});
        },
        addNote({ state, dispatch, commit }, payload) {
            commit('setHeaderLoading', true);
            const applicantId = state.applicant.id;
            return axios
                .post(`${apiUrl}/mobile-app/note`, payload, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(() => {
                    dispatch('getNotes');
                    return dispatch('getApplicantDetail', applicantId);
                });
        },
        storeProfileField({ dispatch, commit, getters }) {
            commit('setHeaderLoading', true);
            const applicantId = getters.applicant.id;
            const { profileEditing } = getters;
            const { newValue } = profileEditing;
            return axios
                .post(`${apiUrl}/mobile-app/applicant/${applicantId}/profile`, newValue)
                .then(() => dispatch('getApplicantDetail', applicantId));
        },
        storeProfileTableRecord({ commit, dispatch, getters }) {
            commit('setHeaderLoading', true);
            const applicantId = getters.applicant.id;
            const { profileEditing } = getters;
            const { newValue } = profileEditing;
            return axios
                .post(`${apiUrl}/mobile-app/applicant/${applicantId}/table-store-record`, newValue)
                .then(() => dispatch('getApplicantDetail', applicantId));
        },
        getSms({ commit, getters }) {
            commit('setHeaderLoading', true);
            const applicantId = getters.applicant.id;
            axios
                .get(`${apiUrl}/mobile-app/message/sms/list`, {
                    params: {
                        applicantId,
                    },
                })
                .then(({ data }) => {
                    commit('setSms', data.sms);
                    commit('setHeaderLoading', false);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        sendSms({ commit, dispatch, getters }, text) {
            commit('setHeaderLoading', true);
            const applicantId = getters.applicant.id;
            return axios
                .post(`${apiUrl}/mobile-app/message/sms/outgoing`, {
                    applicant_ids: [applicantId],
                    text,
                })
                .then(() => dispatch('getSms'));
        },
        getTemplates({ commit }) {
            return axios.get(`${apiUrl}/mobile-app/message-templates`).then(({ data }) => {
                const { templates } = data;
                commit('setEmailTemplates', templates.email);
                commit('setSmsTemplates', templates.text);
            });
        },
        getEmails({ commit, getters }) {
            commit('setHeaderLoading', true);
            const applicantId = getters.applicant.id;
            axios
                .get(`${apiUrl}/mobile-app/message/email/list`, {
                    params: {
                        applicantId,
                    },
                })
                .then(({ data }) => {
                    commit('setEmails', data.emails);
                    commit('setHeaderLoading', false);
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        uploadAttachment({ commit, getters }, item) {
            commit('setHeaderLoading', true);
            return new Promise((resolve, reject) => {
                const formData = new FormData();
                const applicantId = getters.applicant.id;
                const employerId = getters.employer.id;
                const file = new File([item.blob], item.name, {
                    type: item.blob.type,
                });
                formData.append('applicantId', applicantId);
                formData.append('employerId', employerId);
                formData.append(`files[${uuidv4()}]`, file);
                axios
                    .post(`${apiUrl}/mobile-app/email/attachment/create`, formData)
                    .then(({ data }) => {
                        const { files } = data;
                        resolve(files[0].id);
                        commit('setHeaderLoading', false);
                    })
                    .catch(() => {
                        reject();
                        commit('setHeaderLoading', false);
                    });
            });
        },
        sendEmail({ commit, dispatch, getters }, formData) {
            commit('setHeaderLoading', true);
            const applicantId = getters.applicant.id;
            return axios
                .post(`${apiUrl}/mobile-app/message/email/outgoing`, formData, {
                    headers: {
                        'Content-Type': 'multipart/form-data',
                    },
                })
                .then(() => {
                    dispatch('getEmails');
                    dispatch('getApplicantDetail', applicantId);
                })
                .catch(() => {});
        },
    },
    mutations: {
        setApplicants(state, applicants) {
            state.applicants = applicants;
        },
        updateApplicant(state, payload) {
            state.applicants[payload.index] = payload.data;
        },
        removeApplicant(state, index) {
            state.applicants.splice(index, 1);
        },
        setApplicant(state, data) {
            state.applicant = data;
        },
        setApplicantOptions(state, data) {
            state.options = data;
        },
        setProfileEditing(state, value) {
            state.profileEditing = value;
        },
        setNotes(state, data) {
            state.notes = data;
        },
        setSms(state, data) {
            state.sms = data;
        },
        setEmails(state, data) {
            state.emails = data;
        },
        setEmailTemplates(state, value) {
            state.emailTemplates = value;
        },
        setSmsTemplates(state, data) {
            state.smsTemplates = data;
        },
        setActivityFeed(state, data) {
            state.applicantActivityFeed = data;
        },
    },
};

window.store.registerModule('applicantsModule', store);

<template>
    <div class="flex items-center px-4 space-x-3">
        <ion-input
            ref="profileEditInput"
            v-cleave="profileEditing.mask ? profileEditing.mask : {}"
            class="bg-gray-100 px-4 notes-input text-base rounded-xl"
            :placeholder="placeholder"
            :inputmode="inputMode"
            @ionChange="updateValue"
        />
        <button class="text-xl" :class="{'text-gray-300': headerLoading, 'text-blue-700': !headerLoading}" :disabled="headerLoading" @click="save()">Save</button>
    </div>
</template>
<script>
import { IonInput } from '@ionic/vue';
import fieldsMixin from './fieldsMixin';

export default {
    name: 'EditInput',
    components: {
        IonInput,
    },
    mixins: [fieldsMixin],
    computed: {
        placeholder() {
            if (this.profile && this.profileEditing) {
                return this.profile[this.profileEditing.name];
            }
            return '';
        },
        inputMode() {
            switch (this.profileEditing.type) {
                case 'number':
                    return 'numeric';

                case 'tel':
                    return 'tel';

                case 'email':
                    return 'email';

                default:
                    return 'text';
            }
        },
    },
    mounted() {
        setTimeout(() => {
            this.initInput();
        }, 200);
    },
    methods: {
        initInput() {
            const input = this.$refs.profileEditInput.$el.getInputElement();
            input.value = '';
            this.$refs.profileEditInput.$el.setFocus();
        },
        updateValue({ detail }) {
            const { value } = detail;
            const { profileEditing } = this;
            profileEditing.newValue = {
                fields: [
                    {
                        id: profileEditing.name,
                        value,
                    }
                ]
            };
            this.$store.commit('setProfileEditing', profileEditing);
        },
    },
};
</script>

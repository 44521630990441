import Pusher from 'pusher-js';

if (process.env.VUE_APP_LOG_PUSHER) {
    Pusher.log = (msg) => {
        console.log(msg);
    };
}

const store = {
    state() {
        return {
            pusherInitialized: false,
        };
    },
    getters: {
        pusherInitialized: (state) => state.pusherInitialized,
    },
    actions: {
        initPusher({ commit, getters }, token) {
            const { pusherInitialized } = getters;
            if (!pusherInitialized) {
                window.pusher = new Pusher(pusherKey, {
                    cluster: 'mt1',
                    channelAuthorization: {
                        endpoint: `${apiUrl}/mobile-app/broadcasting/auth`,
                        headers: {
                            Authorization: `Bearer ${token}`,
                        },
                    },
                });
                commit('setPusherInitialized', true);
            }
        },
    },
    mutations: {
        setPusherInitialized(state, value) {
            state.pusherInitialized = value;
        },
    },
};

window.store.registerModule('pusher', store);

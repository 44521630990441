const store = {
    state() {
        return {
            statuses: [],
            statusFilters: [],
        };
    },
    getters: {
        statuses: (state) => state.statuses,
        statusFilters: (state) => state.statusFilters,
    },
    actions: {
        getGlobals({ commit }) {
            return axios.get(`${apiUrl}/mobile-app/globals`).then(({ data }) => {
                commit('setStatuses', data.globals.dropdowns.statuses);
                commit('setStatusFilters', data.globals.statusFilters);
                window.app.timezones = data.globals.timezones;
            });
        },
    },
    mutations: {
        setStatuses(state, data) {
            state.statuses = data;
        },
        setStatusFilters(state, data) {
            state.statusFilters = data;
        },
    },
};

window.store.registerModule('globals', store);

<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="w-full h-full flex flex-col items-center justify-center bg-indigo-50">
                <div class="space-y-4 w-2/3">
                    <ion-img src="/images/bluegrass.svg" />
                    <ion-item ref="emailItem" fill="outline" mode="md">
                        <ion-input v-model="email" placeholder="E-mail" type="email" :disabled="loading"></ion-input>
                    </ion-item>
                    <ion-item ref="passwordItem" fill="outline" mode="md">
                        <ion-input
                            v-model="password"
                            placeholder="Password"
                            type="password"
                            :disabled="loading"
                        ></ion-input>
                        <ion-note slot="error" class="text-center">{{ error }}</ion-note>
                    </ion-item>
                    <ion-button
                        :disabled="!email || !password"
                        color="primary"
                        expand="block"
                        shape="round"
                        @click="submit"
                    >
                        <template v-if="!loading"> Login </template>
                        <template v-else>
                            <ion-spinner name="dots"></ion-spinner>
                        </template>
                    </ion-button>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { IonButton, IonContent, IonInput, IonImg, IonItem, IonNote, IonPage, IonSpinner } from '@ionic/vue';

export default {
    name: 'Login',
    components: {
        IonButton,
        IonContent,
        IonInput,
        IonImg,
        IonItem,
        IonNote,
        IonPage,
        IonSpinner,
    },
    data() {
        return {
            email: '',
            password: '',
            error: 'Sorry, we were not able to log you in. Please try again.',
            loading: false,
        };
    },
    methods: {
        submit() {
            this.loading = true;
            this.$refs.passwordItem.$el.classList.remove('ion-invalid');
            axios
                .post(`${apiUrl}/mobile-app/login`, {
                    email: this.email,
                    password: this.password,
                })
                .then(({ data }) => {
                    const { token } = data;
                    ionicStorage.set('token', token).then(() => {
                        this.$store.dispatch('checkForToken').then(() => {
                            this.loading = false;
                            this.$router.push({ name: 'ApplicantsTab' });
                        });
                    });
                })
                .catch(() => {
                    this.loading = false;
                    this.$refs.passwordItem.$el.classList.add('ion-invalid');
                });
        },
    },
};
</script>

<template>
    <div class="h-full">
        <activity-feed v-if="activityFeed" :compact="true" :readonly="true" :mobile="true" />
    </div>
</template>
<script>
import ActivityFeed from '@src/Ats/ActivityFeed/vue/Index.vue';

export default {
    name: 'Activity',
    components: {
        ActivityFeed,
    },
    computed: {
        activityFeed() {
            return this.$store.getters.applicantActivityFeed;
        },
    },
    mounted() {
        this.$store.dispatch('getActivityFeed');
    },
}
</script>

<template>
    <div
        class="relative inline-block pl-3 pr-5 py-0.5 text-sm border rounded-full whitespace-nowrap group"
        :class="{
            'text-indigo-800 bg-indigo-100': !button && !removing,
            'text-indigo-500 bg-indigo-100': button && !removing,
            'bg-gray-100 text-gray-500 opacity-50': removing,
        }"
    >
        <slot />
        <button
            v-if="!button"
            type="button"
            class="absolute items-center justify-center flex-shrink-0 w-4 h-4 ml-1 rounded-full opacity-30"
            style="top: 4px"
            @click="removeTag"
        >
            <svg class="w-2 h-2" stroke="currentColor" fill="none" viewBox="0 0 8 8">
                <path stroke-linecap="round" stroke-width="1.5" d="M1 1l6 6m0-6L1 7" />
            </svg>
        </button>
    </div>
</template>
<script>
export default {
    props: {
        button: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            removing: false,
        };
    },
    methods: {
        removeTag() {
            this.$emit('remove');
            this.removing = true;
        },
    },
};
</script>

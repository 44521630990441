<template>
    <router-link
        v-if="!globalLoading && applicant"
        :to="{ name: 'ApplicantsDetail', params: { id: applicant.id }, query: { tab: 'profile' } }"
        class="border border-gray-300 rounded-md flex justify-between py-2 px-2 shadow-sm"
    >
        <div class="space-y-1">
            <p class="text-sm">{{ applicant.name }}</p>
            <div class="flex space-x-1 items-center">
                <p
                    v-if="applicant.status_name && applicant.status_colors"
                    class="text-xs px-2 rounded-full border inline"
                    :class="[applicant.status_colors.bg, applicant.status_colors.border, applicant.status_colors.text]"
                >
                    {{ applicant.status_name }}
                </p>
                <icon-email v-if="applicant.flags && applicant.flags.email" />
                <icon-sms v-if="applicant.flags && applicant.flags.sms" />
                <icon-missed-call v-if="applicant.flags && applicant.flags.missed_call" />
                <icon-inbound-call v-if="applicant.flags && applicant.flags.incoming_call" />
            </div>
        </div>
        <div class="flex flex-col">
            <div v-if="applicant.avatars" class="flex items-center justify-end">
                <tr-avatar
                    v-for="avatar in applicant.avatars"
                    :key="`${applicant.id}${avatar.id}`"
                    :name="avatar.name"
                    :color="avatar.avatar_color"
                    size="xs"
                />
            </div>
            <div v-if="remindMeAt" class="text-sm text-gray-400 flex items-center">
                <ion-icon :icon="notificationsOutline" />
                <span>
                    {{ remindMeAt }}
                </span>
            </div>
        </div>
    </router-link>
    <div v-else class="h-14">
        <ion-skeleton-text :animated="true" class="rounded-md" />
    </div>
</template>
<script>
import { IonIcon, IonSkeletonText } from '@ionic/vue';
import { notificationsOutline } from 'ionicons/icons';
import IconEmail from './icons/IconEmail.vue';
import IconInboundCall from './icons/IconInboundCall.vue';
import IconMissedCall from './icons/IconMissedCall.vue';
import IconSms from './icons/IconSms.vue';

export default {
    name: 'SingleApplicant',
    components: {
        IconEmail,
        IconInboundCall,
        IconMissedCall,
        IconSms,
        IonIcon,
        IonSkeletonText,
    },
    props: {
        applicant: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            notificationsOutline,
        };
    },
    computed: {
        remindMeAt() {
            if (!this.applicant.flags.remind_me_at) return null;
            const date = this.$luxon.fromISO(this.applicant.flags.remind_me_at);
            if (date < this.$luxon.now().set({ hour: 0, minute: 0, second: 0 })) return null;

            return date.toFormat('h:mma').toLowerCase();
        },
    },
};
</script>

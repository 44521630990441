<template>
    <div class="flex flex-col px-4">
        <div class="flex justify-between items-center py-1">
            <p class="text-xl text-blue-700" @click="cancel">Cancel</p>
            <p class="text-lg text-gray-900">CDL A Experience</p>
            <button
                class="text-xl"
                :class="{ 'text-gray-300': headerLoading, 'text-blue-700': !headerLoading }"
                :disabled="headerLoading"
                @click="save()"
            >
                Save
            </button>
        </div>
        <div v-if="localProfile" class="flex flex-col space-y-1 py-2">
            <div class="flex items-center">
                <p class="text-sm w-2/5">Last 3 years</p>
                <ion-select
                    interface="action-sheet"
                    class="flex-1 border border-gray-300 rounded-md py-1 px-3 text-sm"
                    placeholder="..."
                    :value="localProfile.cdl_xp_last_3years"
                    @ionChange="(event) => updateValue(event, 'cdl_xp_last_3years')"
                >
                    <ion-select-option v-for="option in xpOptions" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </ion-select-option>
                </ion-select>
            </div>
            <div class="flex items-center">
                <p class="text-sm w-2/5">Total experience</p>
                <ion-select
                    interface="action-sheet"
                    class="flex-1 border border-gray-300 rounded-md py-1 px-3 text-sm"
                    placeholder="..."
                    :value="localProfile.cdl_xp"
                    @ionChange="(event) => updateValue(event, 'cdl_xp')"
                >
                    <ion-select-option v-for="option in totalOptions" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </ion-select-option>
                </ion-select>
            </div>
        </div>
    </div>
</template>
<script>
import { IonSelect, IonSelectOption } from '@ionic/vue';
import fieldsMixin from './fieldsMixin';

export default {
    name: 'ExperienceField',
    components: {
        IonSelect,
        IonSelectOption,
    },
    mixins: [fieldsMixin],
    data() {
        return {
            localProfile: null,
        };
    },
    computed: {
        xpOptions() {
            const { options } = this.$store.getters;
            if (options && options.xpmonths) {
                return options.xpmonths;
            }
            return null;
        },
        totalOptions() {
            const { options } = this.$store.getters;
            if (options && options.cdl_xp) {
                return options.cdl_xp;
            }
            return null;
        },
    },
    mounted() {
        this.localProfile = { ...this.profile };
    },
    methods: {
        updateValue({ detail }, id) {
            const { value } = detail;
            const { profileEditing } = this;
            const newValue = this.profileEditing.newValue ?? {};
            newValue.fields = [
                {
                    id,
                    value,
                },
            ];
            this.localProfile[id] = value;
            this.$store.commit('setProfileEditing', {
                ...profileEditing,
                newValue,
            });
        },
    },
};
</script>

<template>
    <ion-select
        v-if="applicant"
        :value="applicant.status"
        placeholder="Status"
        interface="action-sheet"
        class="border border-gray-300 text-sm rounded-md p-2"
        @ionChange="updateStatus"
    >
        <ion-select-option v-for="status in statuses" :key="status.key" :value="status.id">{{
            status.name
        }}</ion-select-option>
    </ion-select>
    <ion-skeleton-text v-else :animated="true" class="p-2 text-sm m-0" />
</template>
<script>
import { IonSelect, IonSelectOption, IonSkeletonText } from '@ionic/vue';

export default {
    name: 'StatusSelect',
    components: {
        IonSelect,
        IonSelectOption,
        IonSkeletonText,
    },
    computed: {
        applicant() {
            return this.$store.getters.applicant;
        },
        statuses() {
            return this.$store.getters.statuses;
        },
    },
    methods: {
        updateStatus({ detail }) {
            const { value } = detail;
            this.$store.dispatch('updateStatus', { value });
        },
    },
};
</script>

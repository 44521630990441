<template>
    <div class="flex flex-col px-4">
        <div class="flex justify-between items-center py-1">
            <p class="text-xl text-blue-700" @click="cancel">Cancel</p>
            <p class="text-lg text-gray-900">Equipment</p>
            <button class="text-xl" :class="{'text-gray-300': headerLoading, 'text-blue-700': !headerLoading}" :disabled="headerLoading" @click="save()">Save</button>
        </div>
        <div v-if="localProfile" class="flex flex-col space-y-1 py-2">
            <div v-for="select in selects" :key="select.id" class="flex items-center">
                <p class="text-sm w-1/3">{{ select.name }}</p>
                <ion-select
                    interface="action-sheet"
                    class="w-2/3 border border-gray-300 rounded-md py-1 px-3 text-sm"
                    placeholder="Experience"
                    :value="localProfile[select.id]"
                    @ionChange="(event) => updateValue(event, select.id)"
                >
                    <ion-select-option v-for="option in options" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </ion-select-option>
                </ion-select>
            </div>
        </div>
    </div>
</template>
<script>
import { IonSelect, IonSelectOption } from '@ionic/vue';
import fieldsMixin from './fieldsMixin';

export default {
    name: 'EquipmentField',
    components: {
        IonSelect,
        IonSelectOption,
    },
    mixins: [fieldsMixin],
    data() {
        return {
            selects: [
                { id: 'dry_van_xp', name: 'Dry Van' },
                { id: 'flatbed_xp', name: 'Flatbed' },
                { id: 'refrigerated_xp', name: 'Refrigerated' },
                { id: 'tanker_xp', name: 'Tanker' },
                { id: 'auto_hauler_xp', name: 'Auto Hauler' },
                { id: 'hazmat_xp', name: 'HazMat' },
            ],
            localProfile: null,
        };
    },
    computed: {
        options() {
            const { options } = this.$store.getters;
            if (options && options.xpmonths) {
                return options.xpmonths;
            }
            return null;
        },
    },
    mounted() {
        this.localProfile = {...this.profile};
    },
    methods: {
        updateValue({ detail }, id) {
            const { value } = detail;
            const { profileEditing } = this;
            if (profileEditing.newValue && profileEditing.newValue.fields) {
                const index = profileEditing.newValue.fields.findIndex(f => f.id === id);
                if (index !== -1) {
                    profileEditing.newValue.fields[index].value = value;
                } else {
                    profileEditing.newValue.fields.push({
                        id,
                        value,
                    });
                }
            } else {
                profileEditing.newValue = {
                    fields: [
                        {
                            id,
                            value
                        }
                    ]
                };
            }
            this.localProfile[id] = value;
            this.$store.commit('setProfileEditing', profileEditing);
        },
    },
};
</script>

<template>
    <card-template
        cardClass="bg-white-50 hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-phone-missed class="text-gray-700 w-5 h-5" />
        </template>
        <template #title>
            <template v-if="! data.group_cards">
                Call not answered
            </template>
            <template v-else>
                {{ data.group_cards.length }} calls not answered
            </template>
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div v-if="! data.group_cards" class="flex flex-col">
            <div class="flex gap-x-2 items-baseline text-gray-700" v-if="data.disposition_key != 'no-answer'">
                <span class="text-sm font-medium">Call disposition</span>
                <span class="text-xs">{{ data.disposition }}</span>
            </div>
        </div>
        <div v-else class="flex flex-col">
            <div 
                v-for="(c, index) in data.group_cards" 
                :key="index"
                class="flex gap-x-2 items-center text-xs"
            >
                <span>{{ getNumberWithOrdinal(data.group_cards.length - index) }} call</span>

                <span class="text-xs">{{ c.disposition }}</span>

                <span class="text-xs text-gray-500">{{ c.time }}</span>
            </div>
        </div>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelinePhoneMissed from '@src/Ats/ActivityFeed/vue/Icon/TimelinePhoneMissed';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelinePhoneMissed,
    },
    props: ['data', 'readonly'],
    methods: {
        getNumberWithOrdinal(n) {
            const s = ["th", "st", "nd", "rd"];
            let v = n % 100;

            return n + (s[(v - 20) % 10] || s[v] || s[0]);
        },
    }
};
</script>

<template>
    <ion-page>
        <ion-content :fullscreen="true">
            <div class="ion-padding">
                <div class="">
                    <h1>Feed</h1>
                </div>
            </div>
        </ion-content>
    </ion-page>
</template>
<script>
import { IonContent, IonPage } from '@ionic/vue';

export default {
    name: 'Feed',
    components: {
        IonContent,
        IonPage,
    },
};
</script>

<template>
    <div class="border border-gray-300 rounded pl-4 pr-1 py-1 w-full h-full text-sm flex items-center space-x-2" @click="openDatepicker">
        <ion-icon :icon="calendarClearOutline" class="text-sm -ml-px text-gray-400" />
        <div class="flex-grow">
            <p v-if="value">{{ formattedValue }}</p>
            <p v-else class="text-gray-400">{{ placeholder }}</p>
        </div>
        <ion-icon v-if="value" :icon="closeCircle" class="text-sm text-gray-400" @click.stop="updateValue(null)" />
        <!-- Datepicker modal -->
        <ion-modal 
            ref="datetimeModal"
            class="rounded-none"
            :handle="false"
            :initial-breakpoint="0.40"
            :breakpoints="[0.40]"
            :keep-contents-mounted="true"
            @willDismiss="willDismiss"
        >
            <div class="h-2/5 w-full flex flex-col px-4">
                <div class="flex justify-between items-center py-1">
                    <p class="text-xl text-blue-700" @click="cancel">Cancel</p>
                    <p class="text-lg text-gray-900">{{ name }}</p>
                    <button
                        class="text-xl"
                        :class="{ 'text-gray-300': headerLoading, 'text-blue-700': !headerLoading }"
                        :disabled="headerLoading"
                        @click="save"
                    >
                        Save
                    </button>
                </div>
                <div>
                    <ion-datetime
                        ref="datetime"
                        class="w-full"
                        locale="en-US"
                        presentation="date"
                        :max="max"
                        :prefer-wheel="true"
                        :value="value"
                        @ionChange="updateValue"
                    />
                </div>
            </div>
        </ion-modal>
    </div>
</template>
<script>
import { IonIcon, IonDatetime, IonModal } from '@ionic/vue';
import { calendarClearOutline, closeCircle } from 'ionicons/icons';

export default {
    components: {
        IonIcon,
        IonDatetime,
        IonModal
    },
    props: {
        value: {
            type: String,
            default: new Date().toISOString(),
        },
        placeholder: {
            type: String,
            default: 'Pick a date'
        },
        name: {
            type: String,
            default: 'Datepicker',
        },
        max: {
            type: String,
            default: '2050-12-31',
        },
    },
    data() {
        return {
            calendarClearOutline,
            closeCircle,
            localValue: null,
        }
    },
    computed: {
        formattedValue() {
            if (this.value) {
                return this.$luxon.formatDate(this.$luxon.fromISO(this.value));
            }
            return null;
        },
    },
    methods: {
        updateValue(val) {
            let value = val;
            if (val) {
                value = val.detail.value;
            }
            this.localValue = value;
        },
        openDatepicker() {
            this.$refs.datetimeModal.$el.present();
        },
        cancel() {
            this.$refs.datetimeModal.$el.dismiss();
        },
        save() {
            this.$emit('ionChange', { detail: { value: this.localValue } });
            this.cancel();
        },
        willDismiss() {
            this.$refs.datetime.$el.reset(this.value);
        },
    }
}
</script>

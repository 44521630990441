<template>
    <div class="flex flex-col px-4 w-full">
        <div class="flex justify-between items-center py-1">
            <p class="text-xl text-blue-700" @click="cancel">Cancel</p>
            <p class="text-lg text-gray-900">Last position</p>
            <button
                class="text-xl"
                :class="{ 'text-gray-300': headerLoading, 'text-blue-700': !headerLoading }"
                :disabled="headerLoading"
                @click="save(true)"
            >
                Save
            </button>
        </div>
        <div v-if="localEmployment" class="flex flex-col space-y-1 py-2">
            <div class="flex items-center">
                <p class="text-sm w-2/5">Type</p>
                <ion-select
                    interface="action-sheet"
                    class="flex-1 border border-gray-300 rounded-md py-1 px-3 text-sm"
                    placeholder="..."
                    :value="type"
                    @ionChange="(event) => updateValue(event, 'type')"
                >
                    <ion-select-option v-for="option in employmentOptions" :key="option.id" :value="option.id">
                        {{ option.name }}
                    </ion-select-option>
                </ion-select>
            </div>
            <!-- Employed -->
            <template v-if="type === 'employment'">
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Position</p>
                    <ion-input
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.position"
                        placeholder="..."
                        @ionChange="(event) => updateValue(event, 'employment_position')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Start Date</p>
                    <ion-input
                        v-cleave="{
                            date: true,
                            datePattern: ['m', 'Y'],
                        }"
                        inputmode="numeric"
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.period_from"
                        placeholder="mm/yyyy"
                        @ionChange="(event) => updateValue(event, 'period_from')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">End Date</p>
                    <ion-input
                        v-cleave="{
                            date: true,
                            datePattern: ['m', 'Y'],
                        }"
                        inputmode="numeric"
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.period_to"
                        placeholder="mm/yyyy"
                        @ionChange="(event) => updateValue(event, 'period_to')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Company Name</p>
                    <ion-input
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.company_name"
                        placeholder="..."
                        @ionChange="(event) => updateValue(event, 'company_name')"
                    />
                </div>
                <div class="flex items-center space-x-4">
                    <p class="text-sm w-2/5">Location</p>
                    <tr-google-places
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.location"
                        placeholder="..."
                        placement="top"
                        country="us"
                        @ionChange="(event) => updateValue(event, 'location')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Phone</p>
                    <ion-input
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.company_phone"
                        type="tel"
                        placeholder="..."
                        @ionChange="(event) => updateValue(event, 'company_phone')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Reference</p>
                    <ion-textarea
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.reference"
                        placeholder="..."
                        @ionChange="(event) => updateValue(event, 'reference')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Were terminated/<br />laid off</p>
                    <ion-segment
                        class="w-3/5"
                        :value="localEmployment.employment_was_fired ?? ''"
                        @ionChange="(event) => updateValue(event, 'employment_was_fired')"
                    >
                        <ion-segment-button :value="true"> Yes </ion-segment-button>
                        <ion-segment-button value=""> </ion-segment-button>
                        <ion-segment-button :value="false"> No </ion-segment-button>
                    </ion-segment>
                </div>
                <div
                    v-if="localEmployment.employment_was_fired && localEmployment.employment_was_fired !== 'false'"
                    class="flex items-center"
                >
                    <p class="text-sm w-2/5">Reason for Leaving</p>
                    <ion-textarea
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.employment_reason_for_leaving"
                        placeholder="..."
                        @ionChange="(event) => updateValue(event, 'employment_reason_for_leaving')"
                    />
                </div>
            </template>
            <!-- Unemployed -->
            <template v-if="type === 'unemployment'">
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Start Date</p>
                    <ion-input
                        v-cleave="{
                            date: true,
                            datePattern: ['m', 'Y'],
                        }"
                        inputmode="numeric"
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.period_from"
                        placeholder="mm/yyyy"
                        @ionChange="(event) => updateValue(event, 'period_from')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">End Date</p>
                    <ion-input
                        v-cleave="{
                            date: true,
                            datePattern: ['m', 'Y'],
                        }"
                        inputmode="numeric"
                        class="rounded-md flex-1 border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.period_to"
                        placeholder="mm/yyyy"
                        @ionChange="(event) => updateValue(event, 'period_to')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Comments</p>
                    <ion-textarea
                        class="rounded-md flex-1 border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.unemployment_comment"
                        placeholder="..."
                        @ionChange="(event) => updateValue(event, 'unemployment_comment')"
                    />
                </div>
            </template>
            <!-- School (non CDL) -->
            <template v-if="type === 'school'">
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Start Date</p>
                    <ion-input
                        v-cleave="{
                            date: true,
                            datePattern: ['m', 'Y'],
                        }"
                        inputmode="numeric"
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.period_from"
                        placeholder="mm/yyyy"
                        @ionChange="(event) => updateValue(event, 'period_from')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">End Date</p>
                    <ion-input
                        v-cleave="{
                            date: true,
                            datePattern: ['m', 'Y'],
                        }"
                        inputmode="numeric"
                        class="rounded-md flex-1 border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.period_to"
                        placeholder="mm/yyyy"
                        @ionChange="(event) => updateValue(event, 'period_to')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">School Name</p>
                    <ion-input
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.school_name"
                        placeholder="..."
                        @ionChange="(event) => updateValue(event, 'school_name')"
                    />
                </div>
                <div class="flex items-center space-x-4">
                    <p class="text-sm w-2/5">Location</p>
                    <tr-google-places
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.location"
                        placeholder="..."
                        placement="top"
                        country="us"
                        @ionChange="(event) => updateValue(event, 'location')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Subject of study</p>
                    <ion-input
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.school_subject"
                        placeholder="..."
                        @ionChange="(event) => updateValue(event, 'school_subject')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Graduaton date</p>
                    <ion-input
                        v-cleave="{
                            date: true,
                            datePattern: ['m', 'Y'],
                        }"
                        inputmode="numeric"
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.school_graduation_date"
                        placeholder="mm/yyyy"
                        @ionChange="(event) => updateValue(event, 'school_graduation_date')"
                    />
                </div>
            </template>
            <!-- Military Service -->
            <template v-if="type === 'military'">
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Branch of Service</p>
                    <ion-select
                        interface="action-sheet"
                        class="flex-1 border border-gray-300 rounded-md py-1 px-3 text-sm"
                        placeholder="..."
                        :value="localEmployment.military_branch"
                        @ionChange="(event) => updateValue(event, 'military_branch')"
                    >
                        <ion-select-option v-for="option in militaryOptions" :key="option.id" :value="option.id">
                            {{ option.name }}
                        </ion-select-option>
                    </ion-select>
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Start Date</p>
                    <ion-input
                        v-cleave="{
                            date: true,
                            datePattern: ['m', 'Y'],
                        }"
                        inputmode="numeric"
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.period_from"
                        placeholder="mm/yyyy"
                        @ionChange="(event) => updateValue(event, 'period_from')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">End Date</p>
                    <ion-input
                        v-cleave="{
                            date: true,
                            datePattern: ['m', 'Y'],
                        }"
                        inputmode="numeric"
                        class="rounded-md flex-1 border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.period_to"
                        placeholder="mm/yyyy"
                        @ionChange="(event) => updateValue(event, 'period_to')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Rank</p>
                    <ion-input
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.military_rank"
                        placeholder="..."
                        @ionChange="(event) => updateValue(event, 'military_rank')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Can obtain DD214</p>
                    <ion-segment
                        class="w-3/5"
                        :value="localEmployment.military_can_get_dd214 ?? ''"
                        @ionChange="(event) => updateValue(event, 'military_can_get_dd214')"
                    >
                        <ion-segment-button :value="true"> Yes </ion-segment-button>
                        <ion-segment-button value=""> </ion-segment-button>
                        <ion-segment-button :value="false"> No </ion-segment-button>
                    </ion-segment>
                </div>
            </template>
            <!-- Driver Training -->
            <template v-if="type === 'cdl_school'">
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Start Date</p>
                    <ion-input
                        v-cleave="{
                            date: true,
                            datePattern: ['m', 'Y'],
                        }"
                        inputmode="numeric"
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.period_from"
                        placeholder="mm/yyyy"
                        @ionChange="(event) => updateValue(event, 'period_from')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">End Date</p>
                    <ion-input
                        v-cleave="{
                            date: true,
                            datePattern: ['m', 'Y'],
                        }"
                        inputmode="numeric"
                        class="rounded-md flex-1 border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.period_to"
                        placeholder="mm/yyyy"
                        @ionChange="(event) => updateValue(event, 'period_to')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Company Name</p>
                    <ion-input
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.company_name"
                        placeholder="..."
                        @ionChange="(event) => updateValue(event, 'company_name')"
                    />
                </div>
                <div class="flex items-center space-x-4">
                    <p class="text-sm w-2/5">Location</p>
                    <tr-google-places
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.location"
                        placeholder="..."
                        placement="top"
                        country="us"
                        @ionChange="(event) => updateValue(event, 'location')"
                    />
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Did they graduate</p>
                    <ion-segment
                        class="w-3/5"
                        :value="localEmployment.cdls_graduated ?? ''"
                        @ionChange="(event) => updateValue(event, 'cdls_graduated')"
                    >
                        <ion-segment-button :value="true"> Yes </ion-segment-button>
                        <ion-segment-button value=""> </ion-segment-button>
                        <ion-segment-button :value="false"> No </ion-segment-button>
                    </ion-segment>
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Were subject to safety regulations</p>
                    <ion-segment
                        class="w-3/5"
                        :value="localEmployment.cdls_safety_regulation ?? ''"
                        @ionChange="(event) => updateValue(event, 'cdls_safety_regulation')"
                    >
                        <ion-segment-button :value="true"> Yes </ion-segment-button>
                        <ion-segment-button value=""> </ion-segment-button>
                        <ion-segment-button :value="false"> No </ion-segment-button>
                    </ion-segment>
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">Were subject to drug and alcohol testing</p>
                    <ion-segment
                        class="w-3/5"
                        :value="localEmployment.cdls_subject_to_testing ?? ''"
                        @ionChange="(event) => updateValue(event, 'cdls_subject_to_testing')"
                    >
                        <ion-segment-button :value="true"> Yes </ion-segment-button>
                        <ion-segment-button value=""> </ion-segment-button>
                        <ion-segment-button :value="false"> No </ion-segment-button>
                    </ion-segment>
                </div>
                <div class="flex items-center">
                    <p class="text-sm w-2/5">GPA</p>
                    <ion-input
                        class="rounded-md border-gray-300 border text-sm py-1 px-4 input-padding-0"
                        :value="localEmployment.cdls_gpa"
                        placeholder="..."
                        @ionChange="(event) => updateValue(event, 'cdls_gpa')"
                    />
                </div>
                <div class="flex">
                    <p class="text-sm w-2/5">Skills trained</p>
                    <div>
                        <ion-item v-for="option in cdlsTrainedOptions" :key="option.id" lines="none" class="py-0">
                            <ion-checkbox
                                slot="start"
                                class="w-4 h-4"
                                :checked="isChecked(option.id)"
                                @ionChange="(event) => updateValue(event, 'cdls_trained', option.id)"
                            />
                            <p class="text-sm">{{ option.name }}</p>
                        </ion-item>
                    </div>
                </div>
            </template>
        </div>
    </div>
</template>
<script>
import {
    IonCheckbox,
    IonInput,
    IonItem,
    IonSegment,
    IonSegmentButton,
    IonSelect,
    IonSelectOption,
    IonTextarea,
} from '@ionic/vue';
import fieldsMixin from './fieldsMixin';

export default {
    name: 'EmploymentField',
    components: {
        IonCheckbox,
        IonInput,
        IonItem,
        IonSegment,
        IonSegmentButton,
        IonSelect,
        IonSelectOption,
        IonTextarea,
    },
    mixins: [fieldsMixin],
    data() {
        return {
            employmentOptions: [
                { id: 'employment', name: 'Employed' },
                { id: 'cdl_school', name: 'Driver Training' },
                { id: 'military', name: 'Military Service' },
                { id: 'school', name: 'School (non CDL)' },
                { id: 'unemployment', name: 'Unemployed' },
            ],
            localEmployment: null,
        };
    },
    computed: {
        type() {
            if (this.localEmployment && this.localEmployment.type) return this.localEmployment.type;
            return 'employment';
        },
        options() {
            return this.$store.getters.options;
        },
        militaryOptions() {
            if (this.options && this.options.military_branch) return this.options.military_branch;
            return [];
        },
        cdlsTrainedOptions() {
            if (this.options && this.options.cdls_trained) return this.options.cdls_trained;
            return [];
        },
    },
    mounted() {
        this.localEmployment = { ...this.profile.last_employment };
    },
    methods: {
        updateValue({ detail }, id, checkbox = null) {
            const { checked, value } = detail;
            const { profileEditing } = this;
            let finalValue = this.localEmployment[id];
            const newValue = profileEditing.newValue ?? {
                field: 'employment_history',
                value: { ...this.localEmployment },
            };
            if (typeof checked !== 'undefined') {
                if (!finalValue) {
                    finalValue = [];
                }
                if (checked) {
                    finalValue.push(checkbox);
                } else {
                    finalValue = finalValue.filter(s => s !== checkbox);
                }
            } else {
                finalValue = value;
            }
            newValue.value[id] = finalValue;
            this.localEmployment[id] = finalValue;
            this.$store.commit('setProfileEditing', { ...profileEditing, newValue });
        },
        isChecked(id) {
            const skills = this.localEmployment.cdls_trained ?? [];
            return skills.includes(id);
        },
    },
};
</script>

import { createStore } from 'vuex';

const store = createStore({
    state() {
        return {
            loading: false,
            headerLoading: false,
            backdrop: false,
        };
    },
    getters: {
        globalLoading: (state) => state.loading,
        headerLoading: (state) => state.headerLoading,
        globalBackdrop: (state) => state.backdrop,
    },
    actions: {
        toggleGlobalBackdrop({ getters, commit }) {
            const { globalBackdrop } = getters;
            commit('setGlobalBackdrop', !globalBackdrop);
        },
    },
    mutations: {
        setGlobalLoading(state, value) {
            state.loading = value;
        },
        setHeaderLoading(state, value) {
            state.headerLoading = value;
        },
        setGlobalBackdrop(state, value) {
            state.backdrop = value;
        },
    },
});

window.store = store;

export default store;

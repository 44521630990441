<template>
    <div class="border rounded-md border-gray-300 mt-4 px-2 text-sm space-y-1 pb-1">
        <!-- First block -->
        <div class="border-b border-gray-300 py-1 space-y-1">
            <single-row label="Full Name" value="name" @edit="edit({ name: 'name', type: 'text' })" />
            <single-row
                label="SSN"
                value="ssn"
                :even="false"
                @edit="
                    edit({
                        name: 'ssn',
                        type: 'number',
                        mask: { blocks: [3, 2, 4], delimiter: '-', numericOnly: true },
                    })
                "
            />
            <single-row
                label="Date of Birth"
                value="date_of_birth"
                type="date"
                @edit="edit({ name: 'date_of_birth', type: 'date' })"
            />
            <single-row label="Phone" value="phone" :even="false" @edit="edit({ name: 'phone', type: 'tel' })" />
            <single-row label="Email" value="email" @edit="edit({ name: 'email', type: 'email' })" />
            <single-row
                label="Preference"
                :value="['preferred_contact_method', 'preferred_contact_time']"
                :even="false"
                type="checkboxes"
                @edit="edit({ name: ['preferred_contact_method', 'preferred_contact_time'], type: 'preference' })"
            />
        </div>
        <!-- Second block -->
        <div class="border-b border-gray-300 pb-1">
            <single-row label="Class A License" value="license" type="license" @edit="edit({ name: 'license', type: 'license' })" />
            <single-row label="CDL A Exp." value="experience" type="experience" :even="false" @edit="edit({ name: 'experience', type: 'experience' })" />
            <single-row
                label="Equipment"
                field-id="equipment"
                :value="['dry_van_xp', 'flatbed_xp', 'refrigerated_xp', 'tanker_xp', 'auto_hauler_xp', 'hazmat_xp']"
                type="equipment"
                @edit="
                    edit({
                        value: [
                            'dry_van_xp',
                            'flatbed_xp',
                            'refrigerated_xp',
                            'tanker_xp',
                            'auto_hauler_xp',
                            'hazmat_xp',
                        ],
                        type: 'equipment',
                        fieldId: 'equipment',
                    })
                "
            />
            <single-row label="Owner Operator" value="is_owner_operator" type="boolean" :even="false" @edit="edit({ value: 'is_owner_operator', type: 'boolean', label: 'Owner Operator' })" />
            <single-row label="Team driving" value="teams_interest" type="boolean" @edit="edit({ value: 'teams_interest', type: 'boolean', label: 'Team driving' })" />
            <single-row
                label="Route preference"
                value="route_preference"
                type="checkboxes"
                :even="false"
                @edit="edit({ name: 'route_preference', type: 'checkboxes' })"
            />
        </div>
        <!-- Third block --->
        <div class="pb-1">
            <single-row label="Address" value="address" type="address" @edit="edit({ name: 'address', type: 'address' })" />
            <single-row label="Last Position" value="employment" type="employment" :even="false" @edit="edit({ name: 'employment', type: 'employment' })" />
            <single-row label="Accidents (3yrs)" value="accident_count" type="accidents" :disabled="true" />
            <single-row label="Violations (3yrs)" value="violation_count" type="accidents" :disabled="true" :even="false" />
            <single-row label="Eligibility" value="eligibility" type="eligibility" @edit="edit({ name: 'eligibility', type: 'eligibility' })" />
        </div>
    </div>
</template>
<script>
import { IonSkeletonText } from '@ionic/vue';
import SingleRow from './SingleRow.vue';

export default {
    components: {
        IonSkeletonText,
        SingleRow,
    },
    data() {
        return {
            employmentOptions: [
                { id: 'employment', name: 'Employed' },
                { id: 'cdl_school', name: 'Driver Training' },
                { id: 'military', name: 'Military Service' },
                { id: 'school', name: 'School (non CDL)' },
                { id: 'unemployment', name: 'Unemployed' },
            ],
        };
    },
    computed: {
        profile() {
            const applicant = this.$store.getters.applicant;
            if (applicant && applicant.profile) {
                return applicant.profile;
            }
            return null;
        },
        license() {
            if (this.profile && this.profile.last_license) {
                return this.profile.last_license.license_expiration_ok ? 'Valid' : 'Invalid';
            }
            return '';
        },
        preferredContact() {
            const { profile } = this;
            const result = [];
            if (profile.preferred_contact_method) result.push('preferred_contact_method');
            if (profile.preferred_contact_time) result.push('preferred_contact_time');
            return result.join(', ');
        },
        address() {
            const { profile } = this;
            const result = [];
            if (this.profile.last_address.city) result.push(this.profile.last_address.city);
            if (this.profile.last_address.zip_code) result.push(this.profile.last_address.zip_code);
            return result.join(', ');
        },
        employment() {
            const data = this.profile.last_employment;
            if (data.type === 'unemployment') return 'Unemployed';
            if (data.type === 'school') return 'School (non CDL)';
            if (data.type === 'military')
                return `${
                    data.military_branch
                        ? _.find(this.options.military_branch, (x) => x.id == data.military_branch).name
                        : null
                } (Military Service)`;
            if (data.type === 'cdl_school') return 'CDL School';
            if (data.type === 'employment') {
                const result = [];
                if (data.employment_position) result.push(data.employment_position);
                if (data.company_name) result.push(data.company_name);
                return result.join(', ');
            }
        },
        eligible() {
            const { profile } = this;
            const passed =
                profile.english_level === 'proficient' &&
                profile.eligible_for_employment === true &&
                profile.fmcsr_391_15 === false &&
                profile.fmcsr_391_21b9_suspended === false &&
                profile.fmcsr_391_21b9_denied === false &&
                profile.fmcsr_391_40_25j === false &&
                profile.fmcsr_395_2 === false;
            return passed ? 'Passed' : '';
        },
        editing() {
            return this.$store.getters.profileEditing;
        },
    },
    methods: {
        edit(field) {
            this.$store.commit('setProfileEditing', field);
        },
    },
};
</script>

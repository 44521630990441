<template>
    <card-template
        cardClass="bg-indigo-50 hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-phone-missed class="text-gray-700 w-5 h-5" />
        </template>
        <template #title>
            Missed call
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelinePhoneMissed from '@src/Ats/ActivityFeed/vue/Icon/TimelinePhoneMissed';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelinePhoneMissed,
    },
    props: ['data', 'readonly'],
};
</script>

<template>
    <tr-dropdown
        placement="bottom-end"
        container="popper-container"
    >
        <template #button>
            <tr-tooltip content="Emoji">
                <tr-button
                    type="secondary"
                    size="auto"
                    class="hover:bg-indigo-200"
                    style="padding: 7px"
                >
                    <icon-emoji-happy class="w-4 h-4" />
                </tr-button>
            </tr-tooltip>
        </template>
        <template #menu>
            <emoji-select-dialog @selected="(emoji) => $emit('selected', emoji)" />
        </template>
    </tr-dropdown>
</template>

<script>
import IconEmojiHappy from '@src/Common/Ui/Components/Icons/vue/outline/emoji-happy.vue';
import EmojiSelectDialog from '@src/Common/UserEmoji/vue/EmojiSelectDialog.vue';

export default {
    components: {
        IconEmojiHappy,
        EmojiSelectDialog,
    }
};
</script>

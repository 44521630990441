<template>
    <div
        class="flex flex-1 gap-x-4 text-sm h-7 items-center py-0.5"
        :class="{ 'justify-center': compact, 'justify-end': !compact }"
    >
        <div class="flex items-center gap-x-2 text-gray-600">
            <span class="text-xs">Calls</span>
            <span class="text-gray-900">{{ stats.calls }}</span>
        </div>
        <div class="w-px h-7 bg-gray-300"></div>
        <div class="flex items-center gap-x-2 text-gray-600">
            <span class="text-xs">SMS</span>
            <span class="text-gray-900">{{ stats.sms }}</span></div>
        <div class="w-px h-7 bg-gray-300"></div>
        <div class="flex items-center gap-x-2 text-gray-600">
            <span class="text-xs">Email</span>
            <span class="text-gray-900">{{ stats.emails }}</span>
        </div>
        <div class="w-px h-7 bg-gray-300"></div>
        <div class="flex items-center gap-x-2 text-gray-600">
            <span class="text-xs">Note</span>
            <span class="text-gray-900">{{ stats.notes }}</span>
        </div>
    </div>
</template>

<script>
export default {
    props: ['stats', 'compact'],
};
</script>

<template>
    <div class="flex gap-x-1 items-center text-sm">
        <tr-dropdown 
            placement="bottom-start" 
            container="activity-feed-container"
            ref="emoji_select"
            @opened="(v) => reactions_opened = v"
        >
            <template #button>
                <a 
                    href="#" 
                    class="flex hover:bg-gray-50 border hover:border-gray-300 p-1 rounded"
                    :class="[reactions_opened ? 'bg-gray-50 border-gray-300' : 'bg-white border-transparent']"
                >
                    <icon-emoji-happy class="w-4 h-4" />
                </a>
            </template>
            <template #menu>
                <card-emoji-select
                    :applicant-id="applicantId"
                    :activity-key="data.activity_key"
                    @selected="emojiSelected"
                />
            </template>
        </tr-dropdown>

        <tr-dropdown 
            placement="bottom-start" 
            container="activity-feed-container" 
            ref="card_menu"
            @opened="(v) => menu_opened = v"
        >
            <template #button>
                <a 
                    href="#" 
                    class="flex hover:bg-gray-50 border hover:border-gray-300 p-1 rounded"
                    :class="[menu_opened ? 'bg-gray-50 border-gray-300' : 'bg-white border-transparent']"
                >
                    <icon-dots-vertical class="w-4 h-4" />
                </a>
            </template>
            <template #menu>
                <div class="flex flex-col w-60 p-2 text-gray-700">
                    <a href="#" class="px-3 py-2 hover:bg-gray-50" @click.prevent="copyLink(data.activity_key)">Copy link</a>
                    <slot />
                </div>
            </template>
        </tr-dropdown>
    </div>
</template>

<script>
import IconDotsVertical from '@src/Common/Ui/Components/Icons/vue/outline/dots-vertical.vue';
import IconEmojiHappy from '@src/Common/Ui/Components/Icons/vue/outline/emoji-happy.vue';
import CardEmojiSelect from '@src/Ats/ActivityFeed/vue/Emojis/CardEmojiSelect';
import { mapGetters } from 'vuex';

export default {
    components: {
        IconDotsVertical,
        IconEmojiHappy,
        CardEmojiSelect,
    },
    props: ['data'],
    data() {
        return {
            reactions_opened: false,
            menu_opened: false,
        };
    },
    computed: {
        ...mapGetters(['applicantId']),
    },
    methods: {
        copyLink(activityKey) {
            let link = url(`dialer/tasks/${this.applicantId}/${activityKey}`);

            navigator.clipboard.writeText(link)
                .then(() => {
                    this.$toast.success('Link copied');
                });

            this.closeMenu();
        },
        emojiSelected() {
            this.$refs.emoji_select.close();
        },
        closeMenu() {
            this.$refs.card_menu.close();
        },
    },
};
</script>

<template>
    <card-template
        cardClass="bg-indigo-50 hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-document-text class="w-5 h-5 text-gray-700" />
        </template>

        <template #title>
            Applicant imported
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="text-xs">
            <template v-if="data.source_name">
                Source: <span class="font-medium">{{ data.source_name }}</span><br>
            </template>
            <template v-if="data.source === 'indeed' && data.source_url">
                Indeed Profile: <a :href="data.source_url" target="_blank" class="font-medium">Click to open in Indeed</a><br>
            </template>
            <attachment-list
                v-if="data.attachments"
                :attachments="data.attachments"
            />
            <template v-if="data.author">
                By: <span class="font-medium">{{ data.author }}</span><br>
            </template>
        </div>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineDocumentText from '@src/Ats/ActivityFeed/vue/Icons/DocumentText';
import AttachmentList from '@src/Ats/ActivityFeed/vue/Attachments/AttachmentList';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelineDocumentText,
        AttachmentList,
    },
    props: ['data', 'readonly'],
    computed: {
        title() {
            return this.data.source === 'import' ? 'Driver imported' : 'Driver added';
        }
    },
};
</script>

const focusInputMixin = {
    methods: {
        focusChildInput(index = 0) {
            this.$nextTick(() => {
                const input = this.$el.querySelectorAll('input,textarea')[index];
                if (input && typeof input.focus === 'function') {
                    input.focus();
                    this.selectInputText(input);
                }
            });
        },
        selectInputText(field) {
            if (typeof field.select === 'function') {
                field.select();
            } else if (field.type !== 'email' && typeof field.setSelectionRange === 'function') {
                field.setSelectionRange(0, field.value.length);
            }
        },
    },
};

export default focusInputMixin;

<template>
    <div class="flex flex-col gap-y-4">
        <div class="bg-gray-100 border border-gray-300 p-4 rounded-md">
            <content-text
                v-model="form_data.auto_sms_text"
                :templates="lists.sms_templates"
                :variables="lists.variables"
            />
        </div>

        <div class="flex items-center gap-x-8 text-sm">
            <span>From number</span>
            <tr-select 
                v-model="form_data.auto_sms_phone_number_id"
                :options="lists.phone_numbers"
                :return-id="true"
            />
            <a href="#" class="text-indigo-700" @click="modal_auto_sms = true">Receive test message</a>
        </div>

        <modal-test-sms
            v-if="modal_auto_sms"
            :show_modal="modal_auto_sms"
            :sms_body="form_data.auto_sms_text"
            :phone_number_id="form_data.auto_sms_phone_number_id"
            @close="modal_auto_sms = false"
        />
    </div>
</template>

<script>
import ContentText from '@src/Messaging/Templates/vue/Content/ContentText.vue';
import ModalTestSms from '@src/Messaging/SmsPreview/vue/ModalTestSms.vue';

export default {
    components: {
        ContentText,
        ModalTestSms,
    },
    props: [
        'form_data',
        'lists',
    ],
    data() {
        return {
            modal_auto_sms: false,
        };
    }
};
</script>

<template>
    <svg
        viewBox="0 0 22 22"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M16 0H2C0.897 0 0 0.897 0 2V16C0 17.103 0.897 18 2 18H10C10.1313 18.0002 10.2614 17.9744 10.3828 17.9241C10.5041 17.8738 10.6143 17.8 10.707 17.707L17.707 10.707C17.7892 10.6217 17.8556 10.5225 17.903 10.414C17.917 10.384 17.925 10.353 17.936 10.321C17.9645 10.2373 17.9816 10.1502 17.987 10.062C17.989 10.041 18 10.021 18 10V2C18 0.897 17.103 0 16 0ZM2 2H16V9H10C9.73478 9 9.48043 9.10536 9.29289 9.29289C9.10536 9.48043 9 9.73478 9 10V16H2V2ZM11 14.586V11H14.586L11 14.586Z"
            fill="currentColor"
        />
    </svg>
</template>

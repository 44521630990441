const store = {
    state() {
        return {
            token: '',
            user: {},
            employer: {},
            recruiters: [],
            windowToken: '',
            authed: false,
        };
    },
    getters: {
        token: (state) => state.token,
        user: (state) => state.user,
        employer: (state) => state.employer,
        windowToken: (state) => state.windowToken,
        recruiters: (state) => state.recruiters,
        authed: (state) => state.authed,
        me: (state) => ({ user: state.user }),
    },
    actions: {
        checkForToken: async ({ commit, dispatch, getters }) => {
            let token = await ionicStorage.get('token');
            window.axios.defaults.headers.common.Authorization = `Bearer ${token}`;
            // const validToken = await dispatch('validateToken');
            if (token) {
                // && validToken
                // Init pusher in pusher vuex module
                dispatch('initPusher', token);
                // Get globals in globals vuex module
                dispatch('getGlobals');
                commit('setToken', token);
                commit('setAuthed', true);
                const { user } = getters;
                if (!user.id) {
                    await dispatch('getUserData');
                }
            } else {
                token = null;
                commit('setAuthed', false);
            }

            return token;
        },
        validateToken: async () =>
            axios
                .post(`${apiUrl}/mobile-app/check-token`)
                .then(() => true)
                .catch(() => false),
        getUserData({ commit }) {
            return axios.post(`${apiUrl}/mobile-app/user-data`).then(({ data }) => {
                commit('setUser', data.user);
                commit('setEmployer', data.employer);
                commit('setRecruiters', data.recruiters);
            });
        },
        logout({ commit }) {
            ionicStorage.remove('token');
            commit('setAuthed', false);
            commit('setToken', null);
            commit('setPusherInitialized', false);
            window.pusher = null;
        },
    },
    mutations: {
        setToken(state, token) {
            state.token = token;
        },
        setUser(state, data) {
            state.user = data;
        },
        setEmployer(state, data) {
            state.employer = data;
        },
        setWindowToken(state, token) {
            state.windowToken = token;
        },
        setRecruiters(state, data) {
            state.recruiters = data;
        },
        setAuthed(state, value) {
            state.authed = value;
        },
    },
};

window.store.registerModule('auth', store);

<template>
    <ion-modal
        :is-open="isOpen"
        :initial-breakpoint="0.75"
        :breakpoints="[0.75, 1]"
        @willDismiss="() => $emit('willDismiss')"
    >
        <ion-header>
            <ion-toolbar class="white">
                <div class="flex justify-between items-center py-1">
                    <p class="text-xl text-blue-700" @click="() => $emit('willDismiss')">Cancel</p>
                    <p class="text-lg text-gray-900">Email templates</p>
                    <p class="opacity-0 pointer-events-none">Cancel</p>
                </div>
            </ion-toolbar>
        </ion-header>
        <ion-content class="ion-padding">
            <p class="text-xs text-gray-500 uppercase mb-4">Choose a template</p>
            <div class="space-y-2">
                <ion-item
                    v-for="template in emailTemplates"
                    :key="template.id"
                    class="px-0"
                    @click="() => $emit('resolve', template.id)"
                >
                    <div class="flex flex-col">
                        <p class="text-base">{{ template.name }}</p>
                        <p class="text-sm text-gray-500 truncate pb-2">{{ template.content.body }}</p>
                    </div>
                </ion-item>
            </div>
        </ion-content>
    </ion-modal>
</template>
<script>
import { IonContent, IonHeader, IonItem, IonModal, IonToolbar } from '@ionic/vue';

export default {
    name: 'EmailTemplatesModal',
    components: {
        IonContent,
        IonHeader,
        IonModal,
        IonItem,
        IonToolbar,
    },
    props: {
        isOpen: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        emailTemplates() {
            return this.$store.getters.emailTemplates;
        },
    },
};
</script>

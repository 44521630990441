<template>
    <div class="sticky w-12 top-0 z-10">
        <div class="absolute flex items-center bg-white pb-1 border-b border-white w-12">
            <div class="flex h-px w-full bg-gray-200 my-4"></div>
        </div>
    </div>
</template>

<script>

export default {
};
</script>

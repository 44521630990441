import JobsTab from './vue/Jobs.vue';

const routes = [
    {
        path: '/tabs/',
        children: [
            {
                path: 'jobs',
                name: 'JobsTab',
                component: JobsTab,
            },
        ],
    },
];

routes.forEach((route) => window.routes.add(route));

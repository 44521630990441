<template>
    <div class="flex flex-col px-4">
        <div class="flex justify-between items-center py-1">
            <p class="text-xl text-blue-700" @click="cancel">Cancel</p>
            <p class="text-lg text-gray-900">Route preference</p>
            <button
                class="text-xl"
                :class="{ 'text-gray-300': headerLoading, 'text-blue-700': !headerLoading }"
                :disabled="headerLoading"
                @click="save()"
            >
                Save
            </button>
        </div>
        <div class="space-y-1">
            <ion-item v-for="checkbox in checkboxes" :key="checkbox.id" lines="none">
                <ion-checkbox
                    slot="start"
                    class="w-5 h-5"
                    :checked="isChecked(checkbox.id)"
                    @ionChange="(event) => updateValue(event, checkbox.id)"
                />
                <p class="text-sm">{{ checkbox.name }}</p>
            </ion-item>
        </div>
    </div>
</template>
<script>
import { IonCheckbox, IonItem } from '@ionic/vue';
import fieldsMixin from './fieldsMixin';

export default {
    name: 'CheckboxesField',
    components: {
        IonCheckbox,
        IonItem,
    },
    mixins: [fieldsMixin],
    computed: {
        options() {
            return this.$store.getters.options;
        },
        checkboxes() {
            return this.options[this.profileEditing.name];
        },
        fieldValue() {
            if (this.profile && this.profileEditing) {
                return this.profile[this.profileEditing.name];
            }
            return null;
        },
    },
    methods: {
        isChecked(id) {
            return this.fieldValue && this.fieldValue.includes(id);
        },
        updateValue({ detail }, id) {
            let selected = this.fieldValue ?? [];
            const { checked } = detail;
            const { profileEditing } = this;
            const newValue = profileEditing.newValue ?? {};
            if (checked) {
                selected.push(id);
            } else {
                selected = selected.filter(s => s !== id);
            }
            newValue.fields = [
                {
                    id: this.profileEditing.name,
                    value: selected,
                },
            ];
            this.$store.commit('setProfileEditing', { ...profileEditing, newValue });
        },
    },
};
</script>

<template>
    <button
        class="
            absolute
            z-5
            py-1
            px-2
            text-xs text-gray-700
            border border-gray-300
            rounded-md
            top-0
            right-0
            flex
            space-x-2
            items-center
            justify-center
            bg-gray-50
        "
        :class="classList"
        tabindex="-1"
    >
        <svg
            v-if="previewOpened"
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M15 19l-7-7 7-7"
            />
        </svg>
        <svg
            v-else
            xmlns="http://www.w3.org/2000/svg"
            class="w-4 h-4"
            fill="none"
            viewBox="0 0 24 24"
            stroke="currentColor"
            stroke-width="2"
        >
            <path
                stroke-linecap="round"
                stroke-linejoin="round"
                d="M9 5l7 7-7 7"
            />
        </svg>
        <span>Preview</span>
    </button>
</template>
<script>
export default {
    props: {
        previewOpened: {
            type: Boolean,
            default: false,
        },
        classList: {
            type: [Object, Array, String],
            default() { return ['mt-2.5', 'mr-4'] },
        }
    },
};
</script>

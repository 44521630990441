<template>
    <div class="flex gap-x-1 my-2" v-if="reactions.length > 0">
        <card-emoji
            v-for="(reaction, index) in reactions"
            :key="index"
            :reaction="reaction"
            :icons="icons"
            :applicant-id="applicantId"
            :activity-key="activityKey"
        />

        <tr-dropdown
            placement="bottom-start"
            container="activity-feed-container"
            ref="emoji_select"
        >
            <template #button>
                <a
                    href="#"
                    class="flex bg-white hover:bg-gray-50 border border-gray-300 py-1 px-2 rounded text-gray-500"
                >
                    <icon-emoji-add class="w-4 h-4" />
                </a>
            </template>
            <template #menu>
                <card-emoji-select
                    :applicant-id="applicantId"
                    :activity-key="activityKey"
                    @selected="emojiSelected"
                />
            </template>
        </tr-dropdown>
    </div>
</template>

<script>
import CardEmojiSelect from '@src/Ats/ActivityFeed/vue/Emojis/CardEmojiSelect';
import CardEmoji from '@src/Ats/ActivityFeed/vue/Emojis/CardEmoji';
import IconEmojiAdd from '@src/Ats/ActivityFeed/vue/Icons/EmojiAdd';

export default {
    components: {
        CardEmojiSelect,
        CardEmoji,
        IconEmojiAdd
    },
    props: {
        applicantId: {
            type: Number,
            required: true,
        },
        activityKey: {
            type: String,
            required: true,
        },
        reactions: {
            type: Array,
            required: true,
        },
    },
    data() {
        return {
            icons: [
                {
                    name: 'thumbs-up',
                    emoji: '👍',
                },
                {
                    name: 'thumbs-down',
                    emoji: '👎',
                },
                {
                    name: 'slightly-smiling-face',
                    emoji: '🙂',
                },
                {
                    name: 'heart',
                    emoji: '❤️',
                },
                {
                    name: 'fire',
                    emoji: '🔥',
                }
            ]
        };
    },
    methods: {
        emojiSelected() {
            this.$refs.emoji_select.close();
        }
    }
};
</script>

import { createRouter, createWebHistory } from '@ionic/vue-router';
import TabsLayout from '../layouts/Tabs.vue';
import HomeTab from '../views/Home/Index.vue';
import SettingsTab from '../views/Settings/Index.vue';
import store from './store';

import helpers from '../helpers/routes';

/**
 * Route helpers
 *
 * List
 *  - route() - get url by route name
 *  - window.routes.add() - add route to Vue router
 *  - routes.get() - list all registered routes
 */
Object.keys(helpers).forEach((helperName) => {
    window[helperName] = helpers[helperName];
});

window.routes.add({
    path: '/tabs/',
    component: TabsLayout,
    children: [
        {
            path: '',
            redirect: 'home',
        },
        {
            path: 'home',
            name: 'HomeTab',
            component: HomeTab,
        },
        {
            path: 'settings',
            name: 'SettingsTab',
            component: SettingsTab,
        },
    ],
});

const router = createRouter({
    history: createWebHistory(process.env.BASE_URL),
    routes: [],
});

router.beforeEach((to, from, next) => {
    const needAuth = !(to.meta && to.meta.free);
    const isLogin = to.name === 'Login';
    store.dispatch('checkForToken').then((token) => {
        if (!needAuth && !isLogin) {
            return next();
        }
        if (token) {
            if (isLogin) {
                next({ name: 'ApplicantsTab' });
            }
            next();
            // store
            //     .dispatch('validateToken')
            //     .then(() => {
            //         if (isLogin) {
            //             return next({ name: 'ApplicantsTab' });
            //         }
            //         return next();
            //     })
            //     .catch(() => {
            //         next({ name: 'Login' });
            //     });
        } else if (isLogin) {
            next();
        } else {
            next({ name: 'Login' });
        }
    });
});

export default router;

<template>
    <tr-modal
        :default-open="show_modal" 
        size="md"
        @close="$emit('close')"
    >
        <template #title>Receive test SMS</template>

        <div class="flex flex-col gap-y-4">
            <div class="text-sm text-gray-700 font-normal">Bluegrass will send you a test text message to review. Please confirm your phone number to receive the text message.</div>
            <tr-input 
                v-model="phone" 
                name="Your Phone Number"
            />
        </div>

        <template #footer>
            <div class="w-36">
                <tr-button-simple 
                    type="primary" 
                    :full="true"
                    @click="send"
                >
                    Send
                </tr-button-simple>
            </div>
        </template>
    </tr-modal>
</template>

<script>
import { mapGetters } from 'vuex';

export default {
    props: {
        show_modal: {
            type: Boolean,
            required: true,
        },
        sms_body: {
            type: String,
            default: "",
        },
        phone_number_id: {
            type: Number,
            required: false,
        },
    },
    data() {
        return {
            phone: "",
        };
    },
    computed: {
        ...mapGetters(['me']),
    },
    mounted() {
        this.phone = this.me.user.phone ? this.me.user.phone : "";
    },
    methods: {        
        send() {
            return axios
                .post(route('sms-preview.send-test-sms'), {
                    phone: this.phone,
                    body: this.sms_body,
                    phoneNumberId: this.phone_number_id
                })
                .then((result) => {
                    this.$emit('close');

                    this.$toast.success('Test SMS sent.');
                });
        },
    },
};
</script>

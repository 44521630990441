<template>
    <div
       :data-role="dropdownName"
       class="fixed z-20"
    >
       <div 
           v-if="open"
           class="flex flex-col bg-white border border-gray-300 rounded-md shadow m-0.5 max-h-80"
           v-click-outside="close"
       >
           <div class="flex flex-col w-60 p-2 text-sm text-gray-700">
               <a href="#" class="px-3 py-2 hover:bg-gray-50" v-for="(item, index) in jump_to" :key="index" @click="scrollToDate(item.date)">
                   {{ item.name }}
               </a>
           </div>
       </div>
    </div>
</template>

<script>
import IconChevronDown from '@src/Common/Ui/Components/Icons/vue/solid/chevron-down.vue';
import popperMixin from '@src/Common/Ui/popperMixin';

export default {
    components: {
        IconChevronDown
    },
    mixins: [
        popperMixin
    ],
    props: [
        'date',
        'date_human',
        'jump_to'
    ],
    data() {
        return {
            open: false,
        };
    },
    computed: {
        buttonName() {
            return `date${this.date}Button`;
        },
        dropdownName() {
            return `date${this.date}Dropdown`;
        },
    },
    watch: {
        open(val) {
            if (val) {
                let buttonElement = this.$parent.$el.querySelector("[data-role="+this.buttonName+"]");
                let dropdownElement = this.$parent.$el.querySelector("[data-role="+this.dropdownName+"]");

                this.setPopper(buttonElement, dropdownElement, {
                    positionFixed: true,
                    placement: "bottom-center",
                    modifiers: {
                        preventOverflow: {
                            enabled: true,
                            boundariesElement: document.getElementById("activity-feed-container"),
                            padding: 0
                        },
                        flip: {
                            behavior: ['bottom', 'top'],
                            flipVariations: true,
                        }
                    },
                });
            }

            this.$emit("opened", this.open);
        },
    },
    methods: {
        toggle() {
            this.open = !this.open;
        },
        close() {
            this.open = false;
        },
        scrollToDate(date) {
            let cardDateElement = document.querySelector("#activity-feed-container [data-feed-date='" + date +"']");

            cardDateElement.scrollIntoView({ behavior: 'smooth', block: 'start'});

            this.close();
        },
    },
    beforeDestroy() {
        this.close();
    }
};
</script>

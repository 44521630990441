import { createApp } from 'vue';
import { IonicVue } from '@ionic/vue';
import vClickOutside from 'v-click-outside';
import App from './App.vue';
import router from './plugins/router';
import store from './plugins/store';
import globalMixin from './plugins/globalMixin';
import LuxonPlugin from './plugins/luxon';

require('./plugins/env');
require('./plugins/styling');
require('./plugins/storage');
require('./plugins/lodash');
require('./plugins/axios');
require('./plugins/pusher');
require('./plugins/pwaElements');
require('./plugins/google');

require('./modules');

// Add routes from modules to Vue router
routes.get().forEach((route) => router.addRoute(route));

const app = createApp(App).use(store).use(IonicVue).use(router).use(LuxonPlugin).use(vClickOutside);
app.mixin(globalMixin);
window.app = app;
window.app.$store = store;

require('./components/index');

router.isReady().then(() => {
    app.mount('#app');
});

<template>
    <card-template
        cardClass="bg-white hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-sms class="text-gray-700 w-4 h-4" />
        </template>
        <template #title>
            <div class="flex items-center gap-x-2" v-if="data.is_auto_sms">
                <span>Auto SMS</span>
                <span class="text-brandLight"><icon-bolt class="w-4 h-4" /></span>
            </div>
            <div class="flex items-center gap-x-2" v-else-if="data.is_bulk_sms">
                <span>Bulk SMS</span>
                <div class="py-0.5 px-4 bg-green-100 text-sm text-green-800 rounded">
                    Sent
                    <!-- TODO: status -->
                </div>
            </div>
            <span v-else>
                SMS
            </span>
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="flex flex-col items-start gap-y-2">
            <div class="inline-block text-blackText whitespace-pre-wrap" v-html="data.text"></div>
            <add-auto-sms-button 
                v-if="data.suggestion_add_auto_sms"
                :worklist_id="data.suggestion_add_auto_sms"
                :sms_body="data.text"
            />
        </div>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineSms from '@src/Ats/ActivityFeed/vue/Icon/TimelineSms';
import AddAutoSmsButton from '@src/Drivers/Worklist/vue/AutoSms/AddAutoSmsButton';
import IconBolt from '@src/Common/Ui/Components/Icons/vue/bolt.vue';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelineSms,
        AddAutoSmsButton,
        IconBolt
    },
    props: ['data', 'readonly'],
};
</script>

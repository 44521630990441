<template>
    <div class="flex flex-col px-4">
        <div class="flex justify-between items-center py-1">
            <p class="text-xl text-blue-700" @click="cancel">Cancel</p>
            <p class="text-lg text-gray-900">Preference</p>
            <button
                class="text-xl"
                :class="{ 'text-gray-300': headerLoading, 'text-blue-700': !headerLoading }"
                :disabled="headerLoading"
                @click="save()"
            >
                Save
            </button>
        </div>
        <div v-if="localProfile" class="flex flex-col space-y-1 py-2">
            <div class="flex items-center space-x-4">
                <p class="text-sm w-1/3">Preferred Method</p>
                <ion-select
                    interface="popover"
                    class="flex-1 border border-gray-300 rounded-md py-1 text-sm"
                    :value="localMethod"
                    @ionChange="(event) => updateValue(event, 'preferred_contact_method')"
                >
                    <ion-select-option v-for="method in methods" :key="method.id" :value="method.id">
                        {{ method.name }}
                    </ion-select-option>
                </ion-select>
            </div>
            <div class="flex items-center space-x-4">
                <p class="text-sm w-1/3">Preferred Time</p>
                <ion-select
                    interface="popover"
                    class="flex-1 border border-gray-300 rounded-md py-1 text-sm"
                    :value="localTime"
                    @ionChange="(event) => updateValue(event, 'preferred_contact_time')"
                >
                    <ion-select-option v-for="time in times" :key="time.id" :value="time.id">
                        {{ time.name }}
                    </ion-select-option>
                </ion-select>
            </div>
        </div>
    </div>
</template>
<script>
import { IonSelect, IonSelectOption } from '@ionic/vue';
import fieldsMixin from './fieldsMixin';

export default {
    name: 'PreferenceField',
    components: {
        IonSelect,
        IonSelectOption,
    },
    mixins: [fieldsMixin],
    data() {
        return {
            localProfile: null,
            localMethod: null,
            localTime: null,
        };
    },
    computed: {
        options() {
            return this.$store.getters.options;
        },
        methodsValue() {
            return this.profile.preferred_contact_method;
        },
        timesValue() {
            return this.profile.preferred_contact_time;
        },
        methods() {
            return this.options.preferred_contact_method;
        },
        times() {
            return this.options.preferred_contact_time;
        },
    },
    mounted() {
        this.localProfile = { ...this.profile };
        this.localMethod = this.localProfile.preferred_contact_method;
        this.localTime = this.localProfile.preferred_contact_time;
    },
    methods: {
        updateValue({ detail }, id) {
            const { value } = detail;
            const { profileEditing } = this;
            if (!profileEditing.newValue) {
                profileEditing.newValue = {
                    fields: [
                        {
                            id,
                            value,
                        },
                    ],
                };
            } else {
                const index = profileEditing.newValue.fields.findIndex((f) => f.id === id);
                if (index !== -1) {
                    profileEditing.newValue.fields[index].value = value;
                } else {
                    profileEditing.newValue.fields.push({
                        id,
                        value,
                    });
                }
            }
            if (id === 'preferred_contact_method') {
                this.localMethod = value;
            } else {
                this.localTime = value;
            }
            this.$store.commit('setProfileEditing', profileEditing);
        },
    },
};
</script>

<template>
    <svg width="14" height="14" viewBox="0 0 14 14" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M0.75 13.25V2.25C0.75 2.25 2.75 0.75 4.75 0.75C6.75 0.75 7.25 2.25 9.25 2.25C11.25 2.25 13.25 0.75 13.25 0.75V7.75C13.25 7.75 11.25 9.25 9.25 9.25C7.25 9.25 6.75 7.75 4.75 7.75C2.75 7.75 0.75 9.25 0.75 9.25"
            stroke="currentColor"
            stroke-width="1.5"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

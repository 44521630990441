<template>
    <div>
        <content-text-plain
            v-model="localValue"
            :placeholder="placeholder"
            :previewable="previewable"
            :classes="classes"
        />

        <slot name="warning"></slot>

        <div class="flex justify-between mt-2">
            <div class="flex gap-x-2">
                <!-- Templates -->
                <div class="relative" v-if="templateButton">
                    <tr-button
                        type="secondary"
                        size="auto"
                        class="hover:bg-indigo-200"
                        style="padding: 7px"
                        @click.native="showTemplates = !showTemplates"
                    >
                        <div class="flex items-center justify-center space-x-2">
                            <tr-document-text class="w-4 h-4" />
                            <span class="text-xs font-normal"> Use Template </span>
                            <tr-chevron-down class="w-4 h-4" />
                        </div>
                    </tr-button> 

                    <templates-list
                        :templates="templates"
                        :show="showTemplates"
                        title="Saved Text Messages"
                        type="sms"
                        position="left"
                        @close="showTemplates = false"
                        @choose="(id) => useTemplate(id)"
                    />
                </div>

                <!-- Variables -->
                <div class="relative" v-if="variableButton">
                    <tr-button
                        type="secondary"
                        size="auto"
                        class="hover:bg-indigo-200"
                        style="padding: 7px"
                        @click.native="showVariables = !showVariables"
                    >
                        <div class="flex items-center justify-center space-x-2">
                            <span class="text-xs font-bold">{x}</span>
                            <span class="text-xs font-normal"> Insert </span>
                            <tr-chevron-down class="w-4 h-4" />
                        </div>
                    </tr-button>
                    
                    <variables-list
                        :variables="variables"
                        :show="showVariables"
                        :is-bulk="true"
                        title="Insert populated variable"
                        type="email"
                        @close="showVariables = false"
                        @choose="(name) => addVariable(name)"
                    />
                </div>

                <emoji-select @selected="(emoji) => insertText(emoji)" />
            </div>
            <div>
                <slot name="actions"></slot>
            </div>
        </div>
    </div>
</template>

<script>
import ContentTextPlain from '@src/Messaging/Templates/vue/Content/ContentTextPlain.vue';
import TemplatesList from '@src/Messaging/Templates/vue/TemplatesList.vue';
import VariablesList from '@src/Messaging/Templates/vue/VariablesList.vue';
import EmojiSelect from '@src/Common/UserEmoji/vue/EmojiSelect.vue';

export default {
    components: {
        ContentTextPlain,
        TemplatesList,
        VariablesList,
        EmojiSelect
    },
    props: {
        value: {
            type: String,
            default: '',
        },
        templateButton: {
            type: Boolean,
            default: true,
        },
        variableButton: {
            type: Boolean,
            default: true,
        },
        templates: {
            type: Array,
            default: () => [],
        },
        variables: {
            type: Array,
            default: () => [],
        },
        applicantId: {
            type: Number,
            default: null,
        },
        placeholder: {
            type: String,
            default: null,
        },
        previewable: {
            type: Boolean,
            default: true,
        },
        classes: {
            type: Object,
            default: () => {},
        },
    },
    data() {
        return {
            localValue: this.value,
            localApplicantId: this.applicantId,
            showTemplates: false,
            showVariables: false,
        };
    },
    computed: {
        isBulk() {
            return this.localApplicantId === null;
        },
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                this.localValue = value;
            },
        },
        localValue(localValue) {
            this.$emit('input', localValue);
        },
        applicantId(applicantId) {
            this.localApplicantId = applicantId;
        },
    },
    methods: {
        useTemplate(templateId) {
            if(this.isBulk) {
                this.localValue = _.find(this.templates, { id: templateId }).content.body;
                return;
            }

            return axios
                .post(route('message-templates.preview'), {
                    template: templateId,
                    applicantId: this.localApplicantId,
                })
                .then(({ data }) => {
                    const { resolved } = data;
                    this.localValue = resolved.body;
                });
        },
        addVariable(variable) {
            let insert = "";
            if(this.isBulk) {
                insert = "{{" + variable + "}}";
            } else {
                insert = _.find(this.variables, { name: variable }).value;
            }

            this.insertText(insert);
        },
        insertText(insert) {
            let areaElement = this.$el.getElementsByTagName("textarea")[0];

            if (insert.length === 0) {
                return;
            }

            let startPos = areaElement.selectionStart;
            let endPos = areaElement.selectionEnd;
            let cursorPos = startPos;
            let tmpStr = this.localValue === null ? "" : this.localValue;

            this.localValue = tmpStr.substring(0, startPos) + insert + tmpStr.substring(endPos, tmpStr.length);

            this.$nextTick(() => {
                cursorPos += insert.length;
                areaElement.selectionStart = areaElement.selectionEnd = cursorPos;
                areaElement.focus();
            });
        },
    }
};
</script>

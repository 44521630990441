/**
 * Class for placing all frontend routes
 * together, before we register them
 * to Vue router
 */
class Routes {
    constructor() {
        this.routes = [];
    }

    /**
     * Add route to global routes array from anywhere in the app
     * When route with same path exists, it will merge them
     *
     *  @example
     *      routes.add({
     *          path: '/settings',
     *          children: [
     *              {
     *                  path: '/telephony,
     *                  name: 'settings-telephony',
     *                  component: Telephony,
     *                  meta: {
     *                  title: 'Telephony',
     *                  },
     *              },
     *          ],
     *      });
     *
     * @param object newRoute
     */
    add(newRoute) {
        const localRoute = newRoute;
        if (typeof localRoute.priority === 'undefined') {
            localRoute.priority = 0;
        }
        if (localRoute.children) {
            const { children } = localRoute;
            localRoute.children = children.map((child) => {
                const localChild = child;
                if (typeof localChild.priority === 'undefined') {
                    localChild.priority = 0;
                }
                return localChild;
            });
        }
        const foundIndex = this.routes.findIndex((single) => single.path === localRoute.path);
        if (foundIndex === -1) {
            this.routes.push(localRoute);
        } else {
            this.merge(foundIndex, localRoute);
        }
    }

    /**
     * Get all registered sorted routes with fallback route for 404
     *
     *  @example
     *      routes.get()
     *
     * @return array
     */
    get() {
        let sortedRoutes = this.sortRoutes(this.routes);
        const { portalVersion } = window;
        if (portalVersion === 1) {
            sortedRoutes = this.filteredRoutes(sortedRoutes);
        }
        return [...sortedRoutes];
    }

    /**
     * Sort routes by priority. Route without priority is moved to end
     *
     *  @example
     *      routes.sort([{ priority: 2 }, { priority: 1 }]) => [{ priority: 1 }, { priority: 2 }]
     *
     * @return array
     */
    sortRoutes(routes) {
        const sortedRoutes = routes.sort((a, b) => {
            if (a.priority === 0) return 1;
            if (b.priority === 0) return -1;
            if (a.priority > b.priority) return 1;
            return -1;
        });
        const result = [];
        sortedRoutes.forEach((single) => {
            const localSingle = single;
            const { children } = single;
            if (children) {
                localSingle.children = this.sortRoutes(children);
            }
            result.push(single);
        });
        return result;
    }

    filteredRoutes(routes) {
        const { restrictedPages } = window;
        return routes.filter((single) => {
            const localRoute = single;
            if (localRoute.children) {
                localRoute.children = this.filteredRoutes(localRoute.children);
            }
            return restrictedPages.indexOf(single.name) === -1;
        });
    }

    /**
     * Merge routes with the same paths
     *
     *  @example
     *      routes.merge(1, { path: 'settings', children: [ { path: 'telephony' } ] })
     *
     * @param int index
     * @param object adding
     */
    merge(index, adding) {
        const original = this.routes[index];
        const merged = { ...original, ...adding };
        if (merged.children && adding.children) {
            merged.children.push(...original.children);
        }
        this.routes[index] = merged;
    }
}

const routes = new Routes();

export default {
    routes,
};

<template>
    <card-template
        cardClass="bg-white hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-tag class="text-gray-700 w-5 h-5" />
        </template>
        <template #title>
            Label added
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="flex flex-wrap -mx-1">
            <div
                v-for="(tag, index) in data.group_tags_changed"
                class="m-1 px-3 py-0 text-xs rounded-full bg-gray-100 border-gray-300 text-gray-800 border"
                :key="index"
            >
                {{ tag }}
            </div>
        </div>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineTag from '@src/Ats/ActivityFeed/vue/Icon/TimelineTag';

export default {
    components: {
        CardMenu,
        TimelineTag,
        CardTemplate
    },
    props: ['data', 'readonly'],
};
</script>

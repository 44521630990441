<template>
    <div class="flex gap-x-1">
        <div
            v-for="(tab, index) in tabs"
            :key="index"
            :class="[tab.name === value ? activeClass : iconClass]" @click="select(tab.name)"
        >
            <component
                :is="tab.icon"
                class="w-4 h-4"
            />
        </div>
    </div>
</template>

<script>
import IconBulb from '@src/Common/UserEmoji/vue/Icons/IconBulb.vue';
import IconClock from '@src/Common/UserEmoji/vue/Icons/IconClock.vue';
import IconFlag from '@src/Common/UserEmoji/vue/Icons/IconFlag.vue';
import IconHappy from '@src/Common/UserEmoji/vue/Icons/IconHappy.vue';
import IconHash from '@src/Common/UserEmoji/vue/Icons/IconHash.vue';
import IconLeaf from '@src/Common/UserEmoji/vue/Icons/IconLeaf.vue';
import IconPizza from '@src/Common/UserEmoji/vue/Icons/IconPizza.vue';
import IconPlane from '@src/Common/UserEmoji/vue/Icons/IconPlane.vue';
import IconTennisBall from '@src/Common/UserEmoji/vue/Icons/IconTennisBall.vue';

export default {
    components: {
        IconBulb,
        IconClock,
        IconFlag,
        IconHappy,
        IconHash,
        IconLeaf,
        IconPizza,
        IconPlane,
        IconTennisBall,
    },
    props: {
        value: {
            type: String,
            required: true,
        }
    },
    data() {
        return {
            tabs: [
                {
                    name: "Frequently Used",
                    icon: "icon-clock"
                },
                {
                    name: "People & Body",
                    icon: "icon-happy"
                },
                {
                    name: "Animals & Nature",
                    icon: "icon-leaf"
                },
                {
                    name: "Food & Drink",
                    icon: "icon-pizza"
                },
                {
                    name: "Travel & Places",
                    icon: "icon-plane"
                },
                {
                    name: "Activities",
                    icon: "icon-tennis-ball"
                },
                {
                    name: "Objects",
                    icon: "icon-bulb"
                },
                {
                    name: "Symbols",
                    icon: "icon-hash"
                },
                {
                    name: "Flags",
                    icon: "icon-flag"
                }
            ],
            iconClass: "p-1 text-gray-500 border border-transparent hover:border-gray-300 rounded cursor-pointer",
            activeClass: "p-1 text-indigo-500 bg-indigo-50 border border-indigo-300 rounded cursor-pointer",
        };
    },
    methods: {
        select(tab) {
            this.$emit('input', tab);
        }
    }
};
</script>

<template>
    <svg width="14" height="15" viewBox="0 0 14 15" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M12.6074 0.0984181C12.4338 0.0297817 12.2482 -0.00356559 12.0616 0.000301681C6.30495 0.106027 3.17127 1.55733 1.55161 3.86717C-0.000777245 6.08107 -0.000351336 8.9011 1.11195e-05 11.3014L2.15669e-05 11.4051L1.28746e-05 11.4062L2.86102e-06 11.4087L0 11.4098C0 11.4098 0 11.4098 2.20479e-05 11.4099L2.21464e-05 11.4108L0 11.4108C0 11.4128 7.39099e-06 11.4147 2.21464e-05 11.4167V14.0775C2.21464e-05 14.4917 0.335809 14.8275 0.750022 14.8275C1.16424 14.8275 1.50002 14.4917 1.50002 14.0775V11.4145L1.50057 11.3989C1.50136 11.3811 1.50314 11.3502 1.50712 11.3079C1.51509 11.2232 1.53176 11.0941 1.56639 10.9339C1.63593 10.6123 1.77578 10.175 2.05541 9.72058C2.5953 8.84325 3.72077 7.79533 6.17636 7.48838C6.58737 7.43701 6.87892 7.06216 6.82754 6.65115C6.77617 6.24013 6.40132 5.94859 5.99031 5.99997C3.93949 6.25632 2.56746 6.9828 1.66111 7.8458C1.82157 6.69418 2.14162 5.63842 2.77977 4.72835C3.98454 3.0102 6.49165 1.61826 11.9942 1.50192C11.9627 3.09094 11.7724 5.37079 10.728 7.25442C10.2018 8.20354 9.4625 9.04453 8.41521 9.65249C7.3669 10.261 5.96727 10.6608 4.08336 10.6608C3.66914 10.6608 3.33336 10.9966 3.33336 11.4108C3.33336 11.8251 3.66914 12.1608 4.08336 12.1608C6.18411 12.1608 7.85323 11.7131 9.16827 10.9498C10.4843 10.1858 11.403 9.1304 12.0399 7.98177C13.2984 5.7118 13.47 3.05039 13.4959 1.42079L13.496 1.42079L13.496 1.41286C13.497 1.22491 13.4603 1.03867 13.3881 0.865152C13.3158 0.691633 13.2095 0.534374 13.0754 0.402679C12.9413 0.270981 12.7822 0.167519 12.6074 0.0984181Z"
            fill="currentColor"
        />
    </svg>
</template>

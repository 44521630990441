<template>
    <div class="flex gap-x-1 py-1 px-2 rounded-md">
        <a v-for="(emoji, key) in emojis" 
            :key="key" 
            href="#" 
            class="p-1 bg-white-50 hover:bg-gray-50 border border-white hover:border-gray-300 rounded" 
            @click.prevent="add(emoji.key)"
        >
            <span class="transform scale-125">
                {{ emoji.emoji }}
            </span>
        </a>
    </div>
</template>

<script>
export default {
    components: {
    },
    props: {
        applicantId: {
            type: Number,
            required: true
        },
        activityKey: {
            type: String,
            required: true
        }
    },
    data() {
        return {
            emojis: [
                {
                    key: 'thumbs-up',
                    emoji: '👍',
                },
                {
                    key: 'thumbs-down',
                    emoji: '👎',
                },
                {
                    key: 'slightly-smiling-face',
                    emoji: '🙂',
                },
                {
                    key: 'heart',
                    emoji: '❤️',
                },
                {
                    key: 'fire',
                    emoji: '🔥',
                }
            ],
        };
    },
    methods: {
        add(emojiKey) {
            return axios
                .post(route('activity-feed.emoji.add'), {
                    applicantId: this.applicantId,
                    activityKey: this.activityKey,
                    emoji: emojiKey
                })
                .then(() => {
                    this.$store.dispatch('refreshActivityFeed');

                    this.$emit("selected");
                });
        }
    }
};
</script>

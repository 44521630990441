<template>
    <div class="flex h-px w-full bg-gray-200 my-4" :data-feed-date="date"></div>
</template>

<script>

export default {
    props: [
        'date',
    ],
};
</script>

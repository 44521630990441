<template>
    <div
        class="flex p-2 rounded-lg border"
        :class="{
            'border-transparent': !editing,
            'bg-indigo-50 border-indigo-300': editing,
            'bg-gray-50': !even && !editing,
        }"
    >
        <div class="w-2/5">
            {{ label }}
        </div>
        <div
            class="flex-1 font-medium"
            :class="{
                'text-gray-400': editing,
                'text-gray-900': !editing,
                'flex items-center justify-between': profile,
            }"
            @click="edit"
        >
            <div v-if="profile">
                {{ fieldValue }}
            </div>
            <ion-icon
                v-if="editing"
                class="w-5 h-5 text-gray-300 flex-shrink-0"
                :icon="closeCircle"
                @click.stop="stopEditing"
            />
            <div v-if="!profile" class="flex items-center">
                <ion-skeleton-text :animated="true" class="h-full" />
            </div>
        </div>
    </div>
</template>
<script>
import { IonIcon, IonSkeletonText } from '@ionic/vue';
import { closeCircle } from 'ionicons/icons';
import { statesUS } from '@assets/js/helpers/geo';

export default {
    components: {
        IonIcon,
        IonSkeletonText,
    },
    props: {
        even: {
            type: Boolean,
            default: true,
        },
        label: {
            type: String,
            default: '',
        },
        value: {
            type: [String, Array],
            default: '',
        },
        type: {
            type: String,
            default: 'string',
        },
        fieldId: {
            type: String,
            default: '',
        },
        disabled: {
            type: Boolean,
            default: false,
        },
    },
    data() {
        return {
            closeCircle,
        };
    },
    computed: {
        profile() {
            const { applicant } = this.$store.getters;
            if (applicant && applicant.profile) {
                return applicant.profile;
            }
            return null;
        },
        options() {
            return this.$store.getters.options;
        },
        editing() {
            const { profileEditing } = this.$store.getters;
            if (profileEditing) {
                if (this.fieldId) {
                    return this.fieldId === profileEditing.fieldId;
                }
                if (Array.isArray(profileEditing.name)) {
                    return _.isEqual(profileEditing.name, this.value);
                }
                return profileEditing.name === this.value;
            }
            return false;
        },
        fieldValue() {
            switch (this.type) {
                case 'date':
                    return this.formatDate();

                case 'checkboxes':
                    return this.formatCheckboxes();

                case 'equipment':
                    return this.formatEquipment();

                case 'boolean':
                    return this.formatBoolean();

                case 'license':
                    return this.formatLicense();

                case 'experience':
                    return this.formatExperience();

                case 'address':
                    return this.formatAddress();

                case 'employment':
                    return this.formatEmployment();

                case 'accidents':
                    return this.formatAccidents();

                case 'eligibility':
                    return this.formatEligibility();

                default:
                    return this.profile[this.value];
            }
        },
    },
    methods: {
        edit() {
            if (!this.disabled) {
                this.$emit('edit');
            }
        },
        stopEditing() {
            this.$store.commit('setProfileEditing', null);
        },
        formatDate() {
            const formatted = this.$luxon.fromISO(this.profile[this.value]);
            if (formatted.isValid) {
                return formatted.toFormat('LL/dd/yyyy');
            }
            return '';
        },
        formatCheckboxes() {
            const result = [];
            if (Array.isArray(this.value)) {
                this.value.forEach((val) => {
                    const value = this.profile[val];
                    const found = this.options[val].find((o) => o.id === value);
                    if (found && found.name) {
                        result.push(found.name);
                    }
                });
            } else {
                const value = this.profile[this.value];
                if (value) {
                    value.forEach((val) => {
                        result.push(this.options[this.value].find((o) => o.id === val).name);
                    });
                }
            }
            return result.join(', ');
        },
        formatEquipment() {
            const result = [];
            if (this.profile.dry_van_xp != 'none') result.push('Dry Van');
            if (this.profile.flatbed_xp != 'none') result.push('Flatbed');
            if (this.profile.refrigerated_xp != 'none') result.push('Refrigerated');
            if (this.profile.tanker_xp != 'none') result.push('Tanker');
            if (this.profile.auto_hauler_xp != 'none') result.push('Auto Hauler');
            if (this.profile.hazmat_xp != 'none') result.push('HazMat');
            return result.join(', ');
        },
        formatBoolean() {
            const value = this.profile[this.value];
            if (value === true) return 'Yes';
            if (value === false) return 'No';
            return '';
        },
        formatLicense() {
            let expiration = null;
            const data = this.profile.last_license;
            if (!data) return '';
            if (data.license_expiration && !data.license_expiration_ok) expiration = 'Expired';
            else if (data.physical_expiration && !data.physical_expiration_ok) expiration = 'Expired';
            else if (data.license_expiration_ok) expiration = 'Valid';

            const cls = data.class ? _.find(this.options.class, { id: data.class })?.name : null;
            const state = data.state ? _.find(statesUS, { id: data.state })?.name : null;
            const endorsements = _.join(data.endorsements, '').toUpperCase();

            const parts = _.filter([state, cls, endorsements, expiration]).join(', ');
            if (parts) return parts;

            return data.number;
        },
        formatExperience() {
            if (this.profile.cdl_xp_last_3years) {
                const xp = _.find(this.options.xpmonths, (x) => x.id === this.profile.cdl_xp_last_3years).name;
                if (this.profile.cdl_xp_last_3years === 'none') return xp;
                return `${xp} (in 3yrs)`;
            }
            if (this.profile.cdl_xp) {
                const xp = _.find(this.options.cdl_xp, (x) => x.id === this.profile.cdl_xp).name;
                if (this.profile.cdl_xp === 'none') return xp;
                return `${xp} (total)`;
            }
            return '';
        },
        formatAddress() {
            const result = [];
            if (this.profile.last_address.city) result.push(this.profile.last_address.city);
            if (this.profile.last_address.zip_code) result.push(this.profile.last_address.zip_code);
            return result.join(', ');
        },
        formatEmployment() {
            const data = this.profile.last_employment;
            if (data.type === 'unemployment') return 'Unemployed';
            if (data.type === 'school') return 'School (non CDL)';
            if (data.type === 'military')
                return `${
                    data.military_branch
                        ? _.find(this.options.military_branch, (x) => x.id == data.military_branch).name
                        : null
                } (Military Service)`;
            if (data.type === 'cdl_school') return 'CDL School';
            if (data.type === 'employment') {
                const result = [];
                if (data.employment_position) result.push(data.employment_position);
                if (data.company_name) result.push(data.company_name);
                return result.join(', ');
            }
            return '';
        },
        formatAccidents() {
            return this.profile[this.value].total;
        },
        formatEligibility() {
            const { profile } = this;
            const passed =
                profile.english_level === 'proficient' &&
                profile.eligible_for_employment === true &&
                profile.fmcsr_391_15 === false &&
                profile.fmcsr_391_21b9_suspended === false &&
                profile.fmcsr_391_21b9_denied === false &&
                profile.fmcsr_391_40_25j === false &&
                profile.fmcsr_395_2 === false;
            return passed ? 'Passed' : '';
        },
    },
};
</script>

<template>
    <div class="flex flex-col gap-y-1 p-2 w-72">
        <emoji-select-tabs v-model="tab" />
        <tr-input
            v-model="search"
            type="search"
            size="xs"
            placeholder="Search"
            :inline="true"
            wrapper-style="w-full"
            @keydown.native="resetTab()"
        />
        <div
            v-if="emojiFiltered.length === 0"
            class="text-sm text-gray-700"
        >
            Couldn't find your emoji
        </div>
        <div>
            <!-- virtual scroller for faster rendering -->
            <DynamicScroller
                :items="emojiFilteredDisplay"
                :min-item-size="30"
                key-field="id"
                class="h-48 w-full overflow-y-auto"
                ref="scroller"
            >
                <template v-slot="{ item, index, active }">
                    <DynamicScrollerItem
                        :item="item"
                        :active="active"
                        :size-dependencies="[
                            item.name,
                        ]"
                        :data-index="index"
                    >
                        <div 
                            v-if="item.type === 'tab'"
                            :data-id="item.id"
                            class="sticky top-0 text-xs uppercase text-gray-500 bg-white"
                        >
                            {{ item.name }}
                        </div>
                        <template v-else>
                            <div class="flex">
                                <button 
                                    v-for="(emoji, emojiIndex) in item.emojis"
                                    :key="item.id + '_' + emojiIndex"
                                    class="py-0.5 bg-white-50 hover:bg-gray-50 border border-white hover:border-gray-300 rounded w-8 text-center"
                                    @click="select(emoji.char)"
                                >
                                    {{ emoji.char }}
                                </button>
                            </div>
                        </template>
                    </DynamicScrollerItem>
                </template>
            </DynamicScroller>
        </div>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import EmojiSelectTabs from '@src/Common/UserEmoji/vue/EmojiSelectTabs.vue';
const emoji = require('emoji.json')

export default {
    components: {
        EmojiSelectTabs,
    },
    data() {
        return {
            search: "",
            tab: "",
            emoji: emoji,
        }
    },
    watch: {
        tab() {
            this.scrollToTop();
        },
    },
    computed: {
        ...mapGetters(['me']),
        emojiFiltered() {
            let emojiList = [
                ...this.me.user_emojis,
                ...this.emoji
            ];

            return _.filter(emojiList, (f) => {
                if(f.name.search(this.search) === -1) {
                    return false;
                }
                if(this.tab === "Frequently Used") {
                    return true;
                }
                if(f.group !== this.tab) {
                    return false;
                }

                return true;
            });
        },
        emojiFilteredDisplay() {
            // create an array of items to be displayed within virtual scroller (an item = a row)
            let items = [];

            let groups = _.groupBy(this.emojiFiltered, "group");
            let groupIndex = 0;

            _.each(groups, (value, key) => {
                items.push({
                    id: "emoji_group_" + groupIndex,
                    name: key,
                    type: "tab",
                });

                let emojisChunked = _.chunk(value, 8);
                let chunkIndex = 0;

                emojisChunked = _.map(emojisChunked, (f) => {
                    chunkIndex++;
                    
                    return {
                        id: "emoji_group_" + groupIndex + "_row_" + chunkIndex,
                        emojis: f,
                    }
                });
            
                items.push(...emojisChunked);
                groupIndex++;
            });

            return items;
        },
    },
    methods: {
        select(emoji) {
            this.$emit("selected", emoji);

            axios.post(route('user-emoji.clicked'), {
                emoji: emoji,
                code: _.find(this.emoji, { char: emoji }).codes
            }).then(() => {
            });
        },
        scrollToTop() {
            this.$refs.scroller.$el.scrollTop = 0;
        },
        resetTab() {
            this.tab = "Frequently Used";
        },
    },
    mounted() {
        this.resetTab();
    }
};
</script>

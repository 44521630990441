import ApplicantsTab from './vue/Applicants.vue';
import ApplicantsDetail from './vue/Detail/Detail.vue';
import ApplicantsSms from './vue/Detail/Sms/Index.vue';
import ApplicantsEmails from './vue/Detail/Email/Index.vue';

const routes = [
    {
        path: '/tabs/',
        children: [
            {
                path: 'applicants',
                name: 'ApplicantsTab',
                component: ApplicantsTab,
            },
            {
                path: 'applicants/:id',
                name: 'ApplicantsDetail',
                component: ApplicantsDetail,
            },
            {
                path: 'applicants/:id/sms',
                name: 'ApplicantsSms',
                component: ApplicantsSms,
            },
            {
                path: 'applicants/:id/email',
                name: 'ApplicantsEmails',
                component: ApplicantsEmails,
            }
        ],
    },
];

routes.forEach((route) => window.routes.add(route));

require('./store');

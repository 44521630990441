import LuxonPlugin from '@assets/js/plugins/luxon';

const temp = {
    prototype: {},
};

LuxonPlugin.install(temp);

export default {
    install: (app) => {
        app.config.globalProperties.$luxon = temp.prototype.$luxon;
    },
};

<template>
    <ion-footer>
        <ion-toolbar class="white without-border">
            <ion-grid>
                <ion-row v-if="images.length">
                    <div class="flex space-x-2 py-2 w-full">
                        <div v-for="(image, index) in images" :key="`image-${index}`" class="relative">
                            <div
                                class="absolute top-0 right-0 transform -translate-y-1/4 translate-x-1/4"
                                @click="removeImage(index)"
                            >
                                <ion-icon :icon="closeCircle" class="text-red-600 w-5 h-5" />
                            </div>
                            <img :src="image.webPath" class="w-10 h-10" />
                        </div>
                    </div>
                </ion-row>
                <ion-row>
                    <div class="w-full flex items-center px-3 space-x-3">
                        <ion-icon :icon="camera" class="text-gray-400 text-xl" @click="takePhoto" />
                        <ion-icon :icon="imageOutline" class="text-gray-400 text-xl" @click="pickFromGallery" />
                        <ion-input
                            v-model="note"
                            placeholder="Start typing"
                            class="bg-gray-100 px-4 notes-input text-base rounded-xl"
                        />
                        <ion-icon
                            :icon="paperPlane"
                            class="text-xl"
                            :class="{
                                'text-gray-400': (!note && !images.length) || uploading,
                                'text-bluegrassPrimary': (note || images.length) && !uploading,
                            }"
                            @click="addNote"
                        />
                    </div>
                </ion-row>
            </ion-grid>
        </ion-toolbar>
    </ion-footer>
</template>
<script>
import { IonFooter, IonGrid, IonIcon, IonInput, IonRow, IonToolbar } from '@ionic/vue';
import { camera, closeCircle, imageOutline, paperPlane } from 'ionicons/icons';
import { Camera, CameraResultType, CameraSource } from '@capacitor/camera';
import { Filesystem } from '@capacitor/filesystem';

export default {
    name: 'AddNote',
    components: {
        IonFooter,
        IonGrid,
        IonIcon,
        IonInput,
        IonRow,
        IonToolbar,
    },
    data() {
        return {
            camera,
            closeCircle,
            imageOutline,
            paperPlane,
            note: '',
            images: [],
            uploading: false,
        };
    },
    computed: {
        applicant() {
            return this.$store.getters.applicant;
        },
    },
    methods: {
        takePhoto() {
            Camera.getPhoto({
                resultType: CameraResultType.Uri,
                source: CameraSource.Camera,
                quality: 100,
            }).then((result) => {
                this.addImage(result);
            });
        },
        pickFromGallery() {
            Camera.pickImages({
                quality: 100,
            }).then(({ photos }) => {
                if (photos && photos.length) {
                    this.addImage(photos);
                }
            });
        },
        addImage(image) {
            if (Array.isArray(image)) {
                this.images.push(...image);
                this.$nextTick(() => {
                    image.forEach((i) => {
                        this.createBlob(i);
                    });
                });
            } else {
                this.images.push(image);
                this.$nextTick(() => {
                    this.createBlob(image);
                });
            }
        },
        createBlob(image) {
            const path = image.page ? image.path : image.webPath;
            Filesystem.readFile({ path }).then(({ data }) => {
                // iOS & Android
                fetch(`data:image/${image.format};base64,${data}`).then((response) => {
                    this.storeImageBlob(response, path);
                });
            }).catch(() => {
                // Web
                fetch(path).then((response) => {
                    this.storeImageBlob(response, path);
                })
            });
        },
        storeImageBlob(response, path) {
            response.blob().then((blob) => {
                const index = this.images.findIndex((i) => i.path === path || i.webPath === path);
                if (index !== -1) {
                    this.images[index].blob = blob;
                }
            });
        },
        removeImage(index) {
            this.images.splice(index, 1);
        },
        addNote() {
            if (!this.note && !this.images.length) return;
            this.uploading = true;
            const formData = new FormData();
            const text = this.prepareText();
            formData.append('text', text);
            formData.append('applicantId', this.applicant.id);
            this.images.forEach((image) => {
                const path = image.path ? image.path : image.webPath;
                const fileName = path.split('/').pop();
                formData.append('image[]', image.blob, fileName);
            });
            this.$store.dispatch('addNote', formData).then(() => {
                this.uploading = false;
                this.note = '';
                this.images = [];
            });
        },
        prepareText() {
            return `<p>${this.note}</p>`;
        },
    },
};
</script>

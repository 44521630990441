import Popper from 'popper.js';

const popperMixin = {
    data() {
        return {
            popper: null,
        };
    },
    methods: {
        setPopper(referenceEl, popperEl, options = {}) {
            let defaultOptions = {
                positionFixed: true,
                placement: 'bottom-start',
                modifiers: {
                    preventOverflow: {
                        enabled: true,
                        boundariesElement: document.getElementById('content-container'),
                        padding: 0
                    },
                    flip: {
                        behavior: ['bottom', 'top'],
                        flipVariations: true,
                    }
                },
            };

            if (this.popper) {
                this.popper.update();
            } else {
                this.popper = new Popper(referenceEl, popperEl, _.assign(defaultOptions, options));
            }
        }
    },
    beforeDestroy() {
        if (this.popper) {
            this.popper.destroy();
            this.popper = null;
        }
    },
};

export default popperMixin;

<template>
    <div>
        <div class="border border-gray-300 rounded flex items-center space-x-2 py-1 px-2" @click="openModal">
            <icon-calendar-automation v-if="icon === 'automation'" class="w-4 h-4" />
            <icon-calendar-check v-else-if="icon === 'check'" class="w-4 h-4" />
            <icon-calendar-cross v-else-if="icon === 'cross'" class="w-4 h-4" />
            <icon-calendar-plus v-else-if="icon === 'plus'" class="w-4 h-4" />

            <p v-if="icon === 'plus'" class="text-xs">Schedule Follow-up</p>
            <p v-if="!nextContactIn.day && nextContactIn.hour" class="text-black">{{ nextContactIn.hour }}</p>
            <div v-else-if="nextContactIn.day" class="flex space-x-1 font-normal items-baseline">
                <span class="text-black">{{ nextContactIn.day_full }}</span>
                <span class="text-gray-500 text-xs">{{ nextContactIn.hour }}</span>
            </div>
        </div>
        <!-- Modal -->
        <ion-modal :is-open="showScheduleModal" :presenting-element="presentingElement">
            <ion-header>
                <ion-toolbar class="white">
                    <ion-buttons slot="start">
                        <ion-button @click="cancel">Cancel</ion-button>
                    </ion-buttons>
                    <ion-title> Schedule reminder </ion-title>
                    <ion-buttons slot="end">
                        <ion-button :disabled="!nextCallIn" @click="save">Save</ion-button>
                    </ion-buttons>
                </ion-toolbar>
                 <ion-progress-bar v-if="loading" type="indeterminate"></ion-progress-bar>
            </ion-header>
            <ion-content>
                <div v-if="hasScheduled" class="ion-padding border-b border-gray-300">
                    <p class="text-base text-red-600">Reminder scheduled</p>
                    <p class="text-xs text-gray-500">
                        {{ applicant.next_contact.day_full || applicant.next_contact.hour }}
                    </p>
                </div>
                <!-- Options -->
                <div class="border-b border-gray-300 ion-padding">
                    <div v-if="Object.keys(options).length" class="space-y-2">
                        <div
                            v-for="option in options"
                            :key="option.id"
                            class="flex items-center p-2 rounded-lg"
                            :class="{
                                'bg-gray-100': nextCallIn === option.id,
                            }"
                            @click="setNextCallIn(option.id)"
                        >
                            <p class="text-base text-gray-700 w-1/3">
                                {{ option.name }}
                            </p>
                            <p class="text-xs text-gray-500">
                                {{ option.date_recruiter }}
                            </p>
                        </div>
                    </div>
                    <div v-else class="space-y-2">
                        <ion-skeleton-text
                            v-for="n in 5"
                            :key="`option-skeleton-${n}`"
                            class="p-2 text-base"
                            :animated="true"
                        >
                        </ion-skeleton-text>
                    </div>
                </div>
                <div class="ion-padding">
                    <div class="flex space-x-2 text-sm">
                        <div class="flex-grow text-base flex items-center"><span>Custom</span></div>
                        <div class="bg-gray-100 p-2 rounded-lg w-[100px] text-center" @click="editCustomDate">
                            {{ custom.dateReadable }}
                        </div>
                        <div class="bg-gray-100 p-2 rounded-lg w-[55px] text-center" @click="editCustomHoursMinutes">
                            {{ custom.hoursMinutesReadable }}
                        </div>
                        <div class="w-[100px]">
                            <ion-segment :value="custom.part" :disabled="nextCallIn !== 'custom'">
                                <ion-segment-button value="AM" class="min-w-0" @click="partChanged">
                                    <span>AM</span>
                                </ion-segment-button>
                                <ion-segment-button value="PM" class="min-w-0" @click="partChanged">
                                    <span>PM</span>
                                </ion-segment-button>
                            </ion-segment>
                        </div>
                    </div>
                    <ion-datetime
                        v-if="nextCallIn === 'custom' && customEdit === 'date'"
                        class="w-full"
                        locale="en-US"
                        presentation="date"
                        :value="custom.date"
                        :prefer-wheel="true"
                        :min="today"
                        @ionChange="changeCustomDate"
                    />
                    <ion-datetime
                        v-if="nextCallIn === 'custom' && customEdit === 'hoursMinutes'"
                        class="w-full"
                        presentation="time"
                        locale="en-US"
                        :value="`${custom.hours}:${custom.minutes}`"
                        :prefer-wheel="true"
                        @ionChange="changeCustomHoursMinutes"
                    />
                </div>
            </ion-content>
        </ion-modal>
    </div>
</template>
<script>
import {
    IonButton,
    IonButtons,
    IonContent,
    IonDatetime,
    IonHeader,
    IonModal,
    IonProgressBar,
    IonSegment,
    IonSegmentButton,
    IonSkeletonText,
    IonTitle,
    IonToolbar,
} from '@ionic/vue';
import IconCalendarAutomation from '@src/Ats/Dialer/vue/NextContact/Icons/IconCalendarAutomation';
import IconCalendarCheck from '@src/Ats/Dialer/vue/NextContact/Icons/IconCalendarCheck';
import IconCalendarCross from '@src/Ats/Dialer/vue/NextContact/Icons/IconCalendarCross';
import IconCalendarPlus from '@src/Ats/Dialer/vue/NextContact/Icons/IconCalendarPlus';

export default {
    components: {
        IonButton,
        IonButtons,
        IonContent,
        IonDatetime,
        IonHeader,
        IonModal,
        IonProgressBar,
        IonSegment,
        IonSegmentButton,
        IonSkeletonText,
        IonTitle,
        IonToolbar,
        IconCalendarAutomation,
        IconCalendarCheck,
        IconCalendarCross,
        IconCalendarPlus,
    },
    props: {
        presentingElement: {
            default: null,
        },
    },
    data() {
        return {
            showScheduleModal: false,
            options: {},
            nextCallIn: '',
            hasScheduled: false,
            custom: {
                date: null,
                dateReadable: '',
                hours: null,
                minutes: null,
                hoursMinutesReadable: '',
                part: 'AM',
            },
            customEdit: '',
            today: new Date().toISOString(),
            loading: false,
        };
    },
    computed: {
        applicant() {
            return this.$store.getters.applicant;
        },
        icon() {
            return this.applicant.next_contact.icon;
        },
        nextContactIn() {
            return this.applicant.next_contact;
        },
        nextCallInUtc() {
            if (!this.nextCallIn) return null;
            if (this.nextCallIn === 'custom') {
                let luxonDate = this.$luxon.fromISO(this.custom.date);
                luxonDate = luxonDate.set({ hour: this.custom.hours, minute: this.custom.minutes });
                luxonDate = luxonDate.setZone('UTC').toISO();
                return luxonDate;
            }
            if (this.options[this.nextCallIn]) {
                return this.options[this.nextCallIn].date;
            }
            return null;
        },
    },
    methods: {
        openModal() {
            this.options = [];
            axios
                .get(`${apiUrl}/mobile-app/queue/next-contact/options`, { params: { applicantId: this.applicant.id } })
                .then(({ data }) => {
                    this.options = data.options;
                });
            if (this.applicant.next_contact.datetime) {
                this.hasScheduled = true;
            }
            this.resetCustom();
            this.showScheduleModal = true;
        },
        cancel() {
            this.showScheduleModal = false;
        },
        save() {
            this.loading = true;
            this.$store
                .dispatch('setNextContact', {
                    nextCallInUtc: this.nextCallInUtc,
                    isCustom: this.nextCallIn === 'custom',
                })
                .then(() => {
                    this.loading = false;
                    this.showScheduleModal = false;
                });
        },
        setNextCallIn(id) {
            this.nextCallIn = id;
            if (id !== 'custom') {
                this.resetCustom();
            }
        },
        editCustomDate() {
            this.nextCallIn = 'custom';
            this.customEdit = 'date';
            if (!this.custom.dateReadable) {
                this.setCustomDate(this.today);
            }
            if (!this.custom.hoursMinutesReadable) {
                this.setHoursMinutes(this.today);
            }
        },
        setCustomDate(date) {
            this.custom.date = date;
            this.custom.dateReadable = this.$luxon.formatDateLong(this.$luxon.fromISO(date));
        },
        changeCustomDate({ detail }) {
            const { value } = detail;
            this.setCustomDate(value);
        },
        editCustomHoursMinutes() {
            this.nextCallIn = 'custom';
            this.customEdit = 'hoursMinutes';
            if (!this.custom.hoursMinutesReadable) {
                this.setHoursMinutes(this.today);
            }
            if (!this.custom.dateReadable) {
                this.setCustomDate(this.today);
            }
        },
        changeCustomHoursMinutes({ detail }) {
            const { value } = detail;
            this.setHoursMinutes(value);
        },
        setHoursMinutes(date) {
            const luxonDate = this.$luxon.fromISO(date);
            this.custom.hours = luxonDate.toFormat('HH');
            this.custom.minutes = luxonDate.toFormat('mm');
            const time = this.$luxon.formatTime(luxonDate).split(' ');
            this.custom.hoursMinutesReadable = time[0];
            this.custom.part = time[1].toUpperCase();
        },
        partChanged() {
            let luxonDate = this.$luxon.fromISO(this.custom.date);
            luxonDate = luxonDate.set({ hour: this.custom.hours, minute: this.custom.minutes });
            if (this.custom.part === 'AM') {
                luxonDate = luxonDate.minus({ hour: 12 });
            } else {
                luxonDate = luxonDate.plus({ hour: 12 });
            }
            const iso = luxonDate.toISO();
            this.setHoursMinutes(iso);
        },
        resetCustom() {
            this.custom = {
                date: null,
                dateReadable: '',
                hours: null,
                minutes: null,
                hoursMinutesReadable: '',
                part: 'AM',
            };
            this.customEdit = '';
            this.today = new Date().toISOString();
        },
    },
};
</script>

<template>
    <ion-fab slot="fixed" vertical="bottom" horizontal="end" @click="toggleBackdrop">
        <ion-fab-button>
            <ion-icon :icon="ellipsisHorizontal" />
        </ion-fab-button>
        <ion-fab-list ref="list" side="top">
            <ion-fab-button class="indigo" data-desc="Add a note" @click="addNote">
                <ion-icon :icon="document" />
            </ion-fab-button>
            <ion-fab-button color="success" data-desc="Call driver" @click="callApplicant">
                <ion-icon :icon="call" class="text-white" />
            </ion-fab-button>
            <ion-fab-button class="indigo" data-desc="Send Email" @click="sendEmail">
                <ion-icon :icon="mail" />
            </ion-fab-button>
            <ion-fab-button class="indigo" data-desc="Send SMS" @click="sendSms">
                <ion-icon :icon="paperPlane" />
            </ion-fab-button>
        </ion-fab-list>
    </ion-fab>
</template>
<script>
import { IonFab, IonFabButton, IonFabList, IonIcon } from '@ionic/vue';
import { call, document, ellipsisHorizontal, mail, paperPlane } from 'ionicons/icons';
import { TwilioVoice } from 'capacitor-twilio-voice';

export default {
    name: 'ApplicantActions',
    components: {
        IonFab,
        IonFabButton,
        IonFabList,
        IonIcon,
    },
    data() {
        return {
            call,
            document,
            ellipsisHorizontal,
            mail,
            paperPlane,
        };
    },
    computed: {
        applicant() {
            return this.$store.getters.applicant;
        },
    },
    methods: {
        callApplicant() {
            const { id, name } = this.applicant;
            TwilioVoice.callApplicant({
                applicantId: `${id}`,
                applicantName: name,
            })
                .then(() => {
                    console.log('call');
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        sendEmail() {
            this.$router.push({ name: 'ApplicantsEmails', params: { id: this.applicant.id } });
        },
        sendSms() {
            this.$router.push({ name: 'ApplicantsSms', params: { id: this.applicant.id } });
        },
        addNote() {
            this.$router.push({ name: 'ApplicantsDetail', query: { tab: 'notes' } });
        },
        toggleBackdrop() {
            this.$store.dispatch('toggleGlobalBackdrop');
        },
    },
};
</script>

<template>
    <div class="sticky flex my-1 top-8 z-10">
        <div class="absolute flex items-center -mt-8 left-1/2 transform -translate-x-1/2">
            <div 
                :data-role="buttonName"
                @click="toggle"
            >
                <div 
                    class="flex gap-x-1 items-center border rounded-full py-1 pl-3 pr-2 text-sm border-gray-300 bg-white hover:bg-gray-50 cursor-pointer"
                >
                    <span>
                        {{ date_human }}
                    </span>
                    <icon-chevron-down class="w-4" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import IconChevronDown from '@src/Common/Ui/Components/Icons/vue/solid/chevron-down.vue';

export default {
    components: {
        IconChevronDown
    },
    props: [
        'date',
        'date_human',
    ],
    computed: {
        buttonName() {
            return `date${this.date}Button`;
        },
    },
    methods: {
        toggle() {
            this.$emit('toggle', this.date);
        },
    },
};
</script>

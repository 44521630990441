<template>
    <span
        class="inline-flex items-center px-2 py-0 text-xs rounded-full border whitespace-nowrap"
    >
        {{ status }}
    </span>
</template>

<script>
export default {
    props: {
        status: {
            type: String,
            required: true
        },
    }
};
</script>

<template>
    <ion-select
        v-if="applicant"
        :value="applicant.recruiter_id"
        placeholder="Recruiter"
        interface="action-sheet"
        class="border border-gray-300 text-sm rounded-md p-2"
        @ionChange="updateRecruiter"
    >
        <ion-select-option v-for="recruiter in recruiters" :key="recruiter.id" :value="recruiter.id">{{
            recruiter.name
        }}</ion-select-option>
    </ion-select>
    <ion-skeleton-text v-else :animated="true" class="p-2 text-sm m-0" />
</template>
<script>
import { IonSelect, IonSelectOption, IonSkeletonText } from '@ionic/vue';

export default {
    name: 'RecruiterSelect',
    components: {
        IonSelect,
        IonSelectOption,
        IonSkeletonText,
    },
    computed: {
        applicant() {
            return this.$store.getters.applicant;
        },
        recruiters() {
            return this.$store.getters.recruiters;
        },
    },
    methods: {
        updateRecruiter({ detail }) {
            const { value } = detail;
            this.$store.dispatch('updateRecruiter', { value });
        },
    },
};
</script>

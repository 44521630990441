<template>
    <tr-tooltip :content="reaction.description" placement="top">
        <button 
            class="flex gap-x-1 items-center px-2 py-0.5 text-gray-500 border rounded transform active:scale-95"
            :class="[reaction.is_active ? ' bg-indigo-50 hover:bg-indigo-100 border-indigo-300' : 'bg-white hover:bg-gray-50 border-gray-300']"
            @click="toggle"
        >
            {{ icon.emoji }}
            <span class="text-xs">{{ reaction.count }}</span>        
        </button>
    </tr-tooltip>
</template>

<script>
export default {
    props: {
        reaction: {
            type: Object,
            required: true
        },
        icons: {
            type: Array,
            required: true
        },
        applicantId: {
            type: Number,
            required: true
        },
        activityKey: {
            type: String,
            required: true
        }
    },
    computed: {
        icon() {
            return _.find(this.icons, { name: this.reaction.emoji });
        }
    },
    methods: {
        toggle() {
            return axios
                .post(route('activity-feed.emoji.toggle'), {
                    applicantId: this.applicantId,
                    activityKey: this.activityKey,
                    emoji: this.reaction.emoji,
                    isActive: this.reaction.is_active
                })
                .then(() => {
                    this.$store.dispatch('refreshActivityFeed');
                });
        }
    }
};
</script>

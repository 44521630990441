<template>
    <div class="relative h-full flex flex-col">
        <div
            v-if="!mobile"
            class="py-0.5 px-3 border-b border-gray-300 flex justify-between"
            :class="{ 'flex-col': compact, 'items-center gap-x-4': !compact }"
        >
            <div
                class="flex-none text-base text-blackText whitespace-nowrap"
                :class="{ 'py-2': compact }"
            >
                Activity stream
            </div>
            <watch-button v-if="!readonly" class="flex-none" />

            <activity-stats :stats="applicantActivityFeed.stats" :compact="compact" />
        </div>
        <div class="flex-1 pt-2 overflow-y-auto" id="activity-feed-container">
            <div role="list" class="flex flex-col text-sm">
                <!--
                    This is a container for CSS sticky elements.
                    All the sticky elements (floating date, avatar) have to be its children (no nesting!).
                -->
                <div v-for="(group, dateIndex) in applicantActivityFeed.activities" :key="dateIndex">
                    <stream-date-separator
                        :date="group.date"
                    />
                    <stream-date-floating
                        :date="group.date"
                        :date_human="group.date_human"
                        @toggle="toggleDateDropdown"
                    />
                    <!-- keep this dropdown outside of the <stream-date-floating> "sticky" element (otherwise z-index will not work) -->
                    <stream-date-floating-dropdown
                        :date="group.date"
                        :date_human="group.date_human"
                        :jump_to="group.jump_to"
                        :ref="'dateDropdown'+group.date"
                    />

                    <div v-for="(card, cardIndex) in group.cards">
                        <div 
                            class="sticky w-12 -top-4 z-10" 
                            :key="`${dateIndex}-${card.component}-${cardIndex}`"
                        >
                            <div class="absolute flex items-center pb-8 mt-3 ml-2">
                                <template v-if="card.author_hide">
                                </template>
                                <template v-else-if="card.author_bluegrass">
                                    <tr-avatar :bluegrass="true" name="Bluegrass" color="bg-white" size="sm" />
                                </template>
                                <template v-else-if="card.author">
                                    <tr-avatar :name="card.author" size="sm" />
                                </template>
                                <template v-else>
                                    <tr-avatar name="" color="bg-gray" size="sm" :tooltip="false" />
                                </template>
                            </div>
                        </div>

                        <div 
                            class="relative break-word"
                            @click="findImage"
                            :key="`${applicantId}-${card.component}-${dateIndex}-${cardIndex}`"
                        >
                            <component
                                :is="card.component"
                                :data="card"
                                :readonly="readonly"
                                :mobile="mobile"
                            />
                        </div>
                    </div>

                    <!-- design hack so avatars do not overflow to the following date -->
                    <stream-date-separator-patch v-if="dateIndex+1 < applicantActivityFeed.activities.length" />
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import WatchButton from '@src/Ats/ActivityFeed/vue/Watchers/WatchButton';
import ActivityStats from '@src/Ats/ActivityFeed/vue/ActivityStats';
import CardCallInbound from '@src/Ats/ActivityFeed/vue/CardCallInbound';
import CardSmsInbound from '@src/Ats/ActivityFeed/vue/CardSmsInbound';
import CardSmsOutbound from '@src/Ats/ActivityFeed/vue/CardSmsOutbound';
import CardSmsBulk from '@src/Ats/ActivityFeed/vue/CardSmsBulk';
import CardCallInboundMissed from '@src/Ats/ActivityFeed/vue/CardCallInboundMissed';
import CardCallOutbound from '@src/Ats/ActivityFeed/vue/CardCallOutbound';
import CardCallOutboundNotAnswered from '@src/Ats/ActivityFeed/vue/CardCallOutboundNotAnswered';
import CardEmailInbound from '@src/Ats/ActivityFeed/vue/CardEmailInbound';
import CardEmailOutbound from '@src/Ats/ActivityFeed/vue/CardEmailOutbound';
import CardEmailBulk from '@src/Ats/ActivityFeed/vue/CardEmailBulk';
import CardRecruiter from '@src/Ats/ActivityFeed/vue/CardRecruiter';
import CardNote from '@src/Ats/ActivityFeed/vue/CardNote';
import CardApplication from '@src/Ats/ActivityFeed/vue/CardApplication';
import CardDocument from '@src/Ats/ActivityFeed/vue/CardDocument';
import CardStatus from '@src/Ats/ActivityFeed/vue/CardStatus';
import CardTagsAdded from '@src/Ats/ActivityFeed/vue/CardTagsAdded';
import CardTagsRemoved from '@src/Ats/ActivityFeed/vue/CardTagsRemoved';
import CardDotApplication from '@src/Ats/ActivityFeed/vue/CardDotApplication';
import CardApplicantImport from '@src/Ats/ActivityFeed/vue/CardApplicantImport';
import CardDriverReview from '@src/Ats/ActivityFeed/vue/CardDriverReview';
import CardProfileUpdated from '@src/Ats/ActivityFeed/vue/CardProfileUpdated';
import StreamDateSeparator from "@src/Ats/ActivityFeed/vue/Partials/StreamDateSeparator";
import StreamDateSeparatorPatch from "@src/Ats/ActivityFeed/vue/Partials/StreamDateSeparatorPatch";
import StreamDateFloating from "@src/Ats/ActivityFeed/vue/Partials/StreamDateFloating";
import StreamDateFloatingDropdown from "@src/Ats/ActivityFeed/vue/Partials/StreamDateFloatingDropdown";
import { mapGetters } from 'vuex';

export default {
    components: {
        WatchButton,
        ActivityStats,
        CardApplicantImport,
        CardDriverReview,
        CardDotApplication,
        CardTagsAdded,
        CardTagsRemoved,
        CardStatus,
        CardDocument,
        CardApplication,
        CardNote,
        CardEmailOutbound,
        CardEmailInbound,
        CardEmailBulk,
        CardCallOutboundNotAnswered,
        CardCallOutbound,
        CardCallInboundMissed,
        CardSmsOutbound,
        CardSmsInbound,
        CardSmsBulk,
        CardCallInbound,
        CardRecruiter,
        CardProfileUpdated,
        StreamDateSeparator,
        StreamDateSeparatorPatch,
        StreamDateFloating,
        StreamDateFloatingDropdown
    },
    props: {
        readonly: {
            type: Boolean,
            default: false,
        },
        compact: {
            type: Boolean,
            default: false,
        },
        mobile: {
            type: Boolean,
            default: false,
        },
    },
    computed: {
        ...mapGetters(['applicantId', 'applicantActivityFeed']),
    },
    methods: {
        toggleDateDropdown(date) {
            let ref = 'dateDropdown'+date;

            this.$refs[ref][0].toggle();
        },
        scrollToActivity() {
            let activityKey = this.$route.params.activity_key;

            if(activityKey) {
                let cardElement = this.$el.querySelector("[data-key='" + activityKey +"']");

                if(cardElement) {
                    cardElement.scrollIntoView({ behavior: 'smooth', block: 'start'});
                } 
            } else {
                let activityFeedContainer = document.getElementById("activity-feed-container");

                activityFeedContainer.scrollTo({
                    top: 0,
                    left: 0
                });
            }
        },
        findImage(event) {
            const { target } = event;
            if (target.tagName=== 'IMG' && target.closest('.email-content') !== null) {
                event.preventDefault();
                window.open(target.src);
            }
        },
    },
    mounted() {
        this.scrollToActivity();
    },
};
</script>

<template>
    <div
        class="flex flex-grow"
        id="content-note"
    >
        <div :class="wrapperClasses">
            <div class="flex flex-col">
                <div class="flex">
                    <div class="relative flex-grow flex-shrink-0 w-full">
                        <froala
                            v-model="val"
                            ref="editor"
                            :config="config"
                            class="shadow-sm note-froala"
                        />
                    </div>
                </div>

                <div
                    v-show="emojiDialog"
                    data-role="emojiDialog"
                    class="z-20 w-72 bg-white border border-gray-300 rounded-md shadow-md m-0.5"
                >
                    <emoji-select-dialog @selected="(emoji) => insertText(emoji)" />
                </div>
            </div>
            <p v-if="hasErrors" class="text-sm text-red-500">
                {{ errorMessage }}
            </p>
        </div>
    </div>
</template>

<script>
import FroalaEditor from 'froala-editor';
import EmojiSelectDialog from '@src/Common/UserEmoji/vue/EmojiSelectDialog.vue';
import froalaMentionMixin from '@src/Common/Ui/froalaMentionMixin';
import popperMixin from '@src/Common/Ui/popperMixin';

export default {
    components: {
        EmojiSelectDialog
    },
    mixins: [
        froalaMentionMixin,
        popperMixin
    ],
    props: {
        value: {
            type: String,
            default: '',
        },
        users: {
            type: Array,
            default: () => [],
        },
        errors: {
            type: Array,
            default: () => [],
        },
    },
    data() {
        const self = this;
        return {
            emojiDialog: false,
            froalaInstance: null,
            val: this.value,
            config: {
                attribution: false,
                key: 'xGE6oE4A3J4B11A8C5F5gKTRe1CD1PGb1DESAb1Kd1EBH1Pd1TKoD6C5G5D4G2D3J4B4D6C4==',
                charCounterCount: false,
                enter: FroalaEditor.ENTER_BR,
                toolbarSticky: false,
                toolbarBottom: true,
                height: 200,
                placeholderText: 'Type your note here...\nUse @ to mention your team members.',

                quickInsertTags: [''],
                quickInsertEnabled: false,
                toolbarButtons: [['bold', 'italic', 'underline', '|', 'formatOL', 'formatUL', 'userMention', 'emojis']],
                htmlRemoveTags: ['script', 'style', 'base'],
                pasteDeniedTags: ['script', 'style', 'base', 'div', 'span', 'dl', 'dt', 'dd'],
                pasteDeniedAttrs: ['class', 'id', 'style', 'dir'],

                // always clean on Word paste (not giving a choice)
                wordPasteModal: false,
                wordPasteKeepFormatting: false,

                events: {
                    initialized: function () {
                        self.froalaInstance = this;
                        self.setPopper(self.$el.querySelector("[data-cmd=emojis]"), self.$el.querySelector("[data-role=emojiDialog]"));
                    },
                    'blur': () =>  {
                        // force Froala to remember the last cursor position (we need it when inserting variables)
                        if(self.froalaInstance.selection) {
                            self.froalaInstance.selection.save();
                        }
                    },
                    'froalaEditor.initialized': () => {
                        this.$el.querySelector('.fr-box').classList.remove('fr-bottom');
                    }
                },
            },
        };
    },
    computed: {
        styles() {
            const defaultStyle = {
                wrapper: 'relative flex flex-col bg-gray-50',
                wrapperWidth: 'w-full',
                textarea: {
                    input: 'w-full px-3 py-2 text-sm h-52 shadow-sm border-0 focus:border-0 rounded-md outline-none'
                },
            };

            return _.merge(defaultStyle, this.css);
        },
        wrapperClasses() {
            const classes = [];
            classes.push(this.styles.wrapper);
            classes.push(this.styles.wrapperWidth);

            if(this.hasErrors) {
                classes.push('has-errors');
            }

            return classes.join(' ');
        },
        hasErrors() {
            return this.errors.length > 0;
        },
        errorMessage() {
            if (this.hasErrors) {
                return this.errors.join(', ');
            }
            return null;
        }
    },
    watch: {
        value: {
            deep: true,
            handler(value) {
                this.val = value;
            },
        },
        val(val) {
            this.$emit('input', val);
        },
        users(val) {
            this.updateUserMentionValues(val);
        },
    },
    methods: {
        insertText(insert) {
            this.froalaInstance.selection.restore();
            this.froalaInstance.html.insert(insert, true);
        }
    },
    mounted() {
        FroalaEditor.DefineIcon('emojiIcon', {PATH: FroalaEditor.SVG.smile, template: 'svg'});
        FroalaEditor.RegisterCommand('emojis', {
            title: 'Emoji',
            icon: 'emojiIcon',
            focus: false,
            undo: false,
            refreshAfterCallback: false,
            callback: (e) => {
                this.emojiDialog = ! this.emojiDialog;
            }
        });

        this.initMentions(true, true, this.users);
    },
};
</script>

<style>
.fr-box.fr-basic.fr-bottom .fr-wrapper {
    border-width: 0px;
    border-radius: 0px;
    -moz-border-radius: 0px;
    -webkit-border-radius: 0px;
}
</style>

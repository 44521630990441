<template>
    <card-template
        cardClass="bg-indigo-50 hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-document-text class="w-5 h-5 text-gray-700" />
        </template>
        <template #title>
            Driver signed their full application
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div v-if="!mobile" class="flex flex-row flex-wrap items-center justify-start">
            <tr-tooltip content="Download full driver profile as PDF">
                <attachment-single 
                    :name="fileName" 
                    @click.native="reportUrl"
                />
            </tr-tooltip>
        </div>
    </card-template>
</template>

<script>
import { mapGetters } from 'vuex';
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import AttachmentSingle from '@src/Ats/ActivityFeed/vue/Attachments/AttachmentSingle';
import TimelineDocumentText from '@src/Ats/ActivityFeed/vue/Icons/DocumentText';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelineDocumentText,
        AttachmentSingle,
    },
    props: ['data', 'readonly', 'mobile'],
    computed: {
        ...mapGetters(['applicantSnippet']),
        fileName() {
            return this.applicantSnippet.name + " - Application";
        },
        reportUrl() {
            window.open(route('applicant-report.generate', { applicantId: this.applicantSnippet.id }));
        }
    },
};
</script>

<template>
    <div class="flex flex-col px-4 w-full">
        <div class="flex justify-between items-center py-1">
            <p class="text-xl text-blue-700" @click="cancel">Cancel</p>
            <p class="text-lg text-gray-900">Eligibility</p>
            <button
                class="text-xl"
                :class="{ 'text-gray-300': headerLoading, 'text-blue-700': !headerLoading }"
                :disabled="headerLoading"
                @click="save()"
            >
                Save
            </button>
        </div>
        <div v-if="localProfile" class=" flex flex-col space-y-1 py-2">
            <div class="flex items-center space-x-1">
                <div class="text-xs w-2/5">English Level</div>
                <ion-select
                    interface="action-sheet"
                    class="flex-1 border border-gray-300 rounded-md py-1 px-3 text-xs"
                    placeholder="..."
                    :value="localProfile.english_level"
                    @ionChange="(event) => updateValue(event, 'english_level')"
                >
                <ion-select-option v-for="option in options" :key="option.id" :value="option.id">
                    {{ option.name }}
                </ion-select-option>
                </ion-select>
            </div>
            <div class="flex items-center space-x-1">
                <p class="text-xs w-2/5">Legally eligible for employment in US</p>
                <ion-segment
                    class="w-3/5"
                    :value="localProfile.eligible_for_employment ?? ''"
                    @ionChange="(event) => updateValue(event, 'eligible_for_employment')"
                >
                    <ion-segment-button :value="true"> Yes </ion-segment-button>
                    <ion-segment-button value=""> </ion-segment-button>
                    <ion-segment-button :value="false"> No </ion-segment-button>
                </ion-segment>
            </div>
            <div class="flex items-center space-x-1">
                <p class="text-xs w-2/5">Disqualified from driving (CFR 391.15)</p>
                <ion-segment
                    class="w-3/5"
                    :value="localProfile.fmcsr_391_15 ?? ''"
                    @ionChange="(event) => updateValue(event, 'fmcsr_391_15')"
                >
                    <ion-segment-button :value="true"> Yes </ion-segment-button>
                    <ion-segment-button value=""> </ion-segment-button>
                    <ion-segment-button :value="false"> No </ion-segment-button>
                </ion-segment>
            </div>
            <div class="flex items-center space-x-1">
                <p class="text-xs w-2/5">License ever suspended (CFR 391.21b9)</p>
                <ion-segment
                    class="w-3/5"
                    :value="localProfile.fmcsr_391_21b9_suspended ?? ''"
                    @ionChange="(event) => updateValue(event, 'fmcsr_391_21b9_suspended')"
                >
                    <ion-segment-button :value="true"> Yes </ion-segment-button>
                    <ion-segment-button value=""> </ion-segment-button>
                    <ion-segment-button :value="false"> No </ion-segment-button>
                </ion-segment>
            </div>
            <div class="flex items-center space-x-1">
                <p class="text-xs w-2/5">License ever denied (CFR 391.21b9)</p>
                <ion-segment
                    class="w-3/5"
                    :value="localProfile.fmcsr_391_21b9_denied ?? ''"
                    @ionChange="(event) => updateValue(event, 'fmcsr_391_21b9_denied')"
                >
                    <ion-segment-button :value="true"> Yes </ion-segment-button>
                    <ion-segment-button value=""> </ion-segment-button>
                    <ion-segment-button :value="false"> No </ion-segment-button>
                </ion-segment>
            </div>
            <div class="flex items-center space-x-1">
                <p class="text-xs w-2/5">Drug and Alcohol tested positive (CFR 40.25j)</p>
                <ion-segment
                    class="w-3/5"
                    :value="localProfile.fmcsr_391_40_25j ?? ''"
                    @ionChange="(event) => updateValue(event, 'fmcsr_391_40_25j')"
                >
                    <ion-segment-button :value="true"> Yes </ion-segment-button>
                    <ion-segment-button value=""> </ion-segment-button>
                    <ion-segment-button :value="false"> No </ion-segment-button>
                </ion-segment>
            </div>
            <div class="flex items-center space-x-1">
                <p class="text-xs w-2/5">Offenses committed on-duty (CFR 391.15 and CFR 395.2)</p>
                <ion-segment
                    class="w-3/5"
                    :value="localProfile.fmcsr_395_2 ?? ''"
                    @ionChange="(event) => updateValue(event, 'fmcsr_395_2')"
                >
                    <ion-segment-button :value="true"> Yes </ion-segment-button>
                    <ion-segment-button value=""> </ion-segment-button>
                    <ion-segment-button :value="false"> No </ion-segment-button>
                </ion-segment>
            </div>
        </div>
    </div>
</template>
<script>
import { IonSelect, IonSelectOption, IonSegment, IonSegmentButton } from '@ionic/vue';
import fieldsMixin from './fieldsMixin';

export default {
    name: 'EligibilityField',
    components: {
        IonSelect,
        IonSelectOption,
        IonSegment,
        IonSegmentButton,
    },
    mixins: [fieldsMixin],
    data() {
        return {
            localProfile: null,
        };
    },
    computed: {
        options() {
            const { options } = this.$store.getters;
            if (options && options.english_level) return options.english_level;
            return [];
        },
    },
    mounted() {
        this.localProfile = { ...this.profile };
    },
    methods: {
        updateValue({ detail }, id) {
            const { value } = detail;
            const { profileEditing } = this;
            const newValue = profileEditing.newValue ?? {};
            let finalValue = value;
            if (value === 'true') {
                finalValue = true;
            } else if (value === 'false') {
                finalValue = false;
            }
            if (newValue.fields) {
                const index = newValue.fields.findIndex(f => f.id === id);
                if (index !== -1) {
                    newValue.fields[index].value = finalValue;
                } else {
                    newValue.fields.push({ id, value: finalValue });
                }
            } else {
                newValue.fields = [ { id, value: finalValue }];
            }
            this.localProfile[id] = finalValue;
            this.$store.commit('setProfileEditing', { ...profileEditing, newValue });
        }
    }
}
</script>

<template>
    <div class="flex text-xs space-x-1 divide-x h-12">
        <div class="text-center flex-1">
            <single-filter
                title="All"
                :count="applicants.length"
                :selected="selected.id === 'all'"
                @click="selectStatus({ id: 'all', name: 'All' })"
            ></single-filter>
        </div>
        <div v-for="status in statusFilters" :key="status.id" class="text-center flex-1">
            <single-filter
                :title="status.name"
                :count="countApplicants(status)"
                :selected="status.id === selected.id"
                @click="selectStatus(status)"
            />
        </div>
    </div>
</template>
<script>
import SingleFilter from './SingleFilter.vue';

export default {
    name: 'StatusFilters',
    components: {
        SingleFilter,
    },
    data() {
        return {
            selected: {
                id: 'all',
                name: 'All',
            },
        };
    },
    computed: {
        statusFilters() {
            return this.$store.getters.statusFilters;
        },
        applicants() {
            return this.$store.getters.applicants;
        },
    },
    watch: {
        selected(val) {
            this.$emit('filter', val);
        },
    },
    methods: {
        countApplicants({ statuses }) {
            const filteredApplicants = this.applicants.filter((single) => statuses.includes(single.status));
            return filteredApplicants.length;
        },
        selectStatus(status) {
            this.selected = {
                id: status.id,
                name: status.name,
            };
        },
    },
};
</script>

<template>
    <div
        class="rounded py-1 px-2 text-sm flex items-center space-x-1 mb-2 mr-2"
        :class="[colors.background, colors.text]"
    >
        <ion-icon v-if="!externalIcon" :class="[colors.icon]" :icon="icon" />
        <img v-else :src="icon" />
        <span>
            <slot />
        </span>
    </div>
</template>
<script>
import { IonIcon } from '@ionic/vue';

export default {
    components: {
        IonIcon,
    },
    props: {
        icon: {
            default: null,
        },
        externalIcon: {
            type: Boolean,
            default: false,
        },
        colors: {
            type: Object,
            default: () => {},
        },
    },
};
</script>

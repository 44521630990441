<template>
    <ion-page ref="detailPage">
        <ion-header>
            <ion-toolbar class="white ion-padding-start ion-padding-end">
                <div class="flex items-center justify-between">
                    <router-link
                        :to="{ name: 'ApplicantsTab' }"
                        class="flex items-center space-x-2"
                        :class="{
                            'w-full': !applicant,
                        }"
                    >
                        <ion-icon class="text-2xl" :icon="chevronBackOutline" />
                        <p v-if="applicant" class="text-2xl">{{ applicant.name }}</p>
                        <ion-skeleton-text v-else :animated="true" class="w-full text-2xl" />
                    </router-link>
                    <next-contact v-if="applicant" :presenting-element="presentingElement" />
                </div>
            </ion-toolbar>
            <ion-progress-bar v-if="globalLoading || headerLoading" type="indeterminate"></ion-progress-bar>
        </ion-header>
        <ion-content>
            <div
                class="ion-padding-start ion-padding-end ion-padding-top text-gray-700 flex flex-col"
                :class="{ 'h-full': applicant }"
            >
                <div>
                    <!-- Tags -->
                    <tags v-if="applicant" />
                    <ion-skeleton-text v-else :animated="true" class="text-2xl mb-2" />
                    <!-- Selects -->
                    <div class="flex space-x-2">
                        <div class="w-1/2">
                            <status-select />
                        </div>
                        <div class="w-1/2">
                            <recruiter-select />
                        </div>
                    </div>
                    <!-- Labels -->
                    <labels v-if="applicant" :presenting-element="presentingElement" />
                    <ion-skeleton-text v-else :animated="true" class="text-2xl my-2" />
                    <!-- Tabs -->
                    <div class="flex text-sm font-medium text-gray-600 pb-2">
                        <p
                            class="uppercase flex-grow flex-1 text-center py-2"
                            :class="{
                                'border-b-2 border-bluegrassPrimary text-bluegrassPrimary': tab === 'activity',
                            }"
                            @click="setTab('activity')"
                        >
                            Activity
                        </p>
                        <p
                            class="uppercase flex-grow flex-1 text-center py-2"
                            :class="{
                                'border-b-2 border-bluegrassPrimary text-bluegrassPrimary': tab === 'notes',
                            }"
                            @click="setTab('notes')"
                        >
                            Notes
                        </p>
                        <p
                            class="uppercase flex-grow flex-1 text-center py-2"
                            :class="{
                                'border-b-2 border-bluegrassPrimary text-bluegrassPrimary': tab === 'profile',
                            }"
                            @click="setTab('profile')"
                        >
                            Profile
                        </p>
                    </div>
                </div>
                <!-- Tab content -->
                <div id="tabWrapper"  class="h-full overflow-y-scroll">
                    <component :is="tab" />
                </div>
            </div>
            <ion-backdrop v-if="globalBackdrop" :visible="true" class="bg-white opacity-80" />
            <applicant-actions />
        </ion-content>
        <add-note v-if="tab === 'notes'" />
        <edit-profile-footer v-if="tab === 'profile'" />
    </ion-page>
</template>
<script>
import {
    IonBackdrop,
    IonButton,
    IonContent,
    IonFooter,
    IonHeader,
    IonIcon,
    IonPage,
    IonProgressBar,
    IonSkeletonText,
    IonToolbar,
} from '@ionic/vue';
import { callOutline, chevronBackOutline, location, mailOutline } from 'ionicons/icons';
import { TwilioVoice } from 'capacitor-twilio-voice';
import Profile from './Profile/Profile.vue';
import NextContact from './NextContact.vue';
import Tags from './Tags/Tags.vue';
import Labels from './Labels/Labels.vue';
import StatusSelect from './StatusSelect.vue';
import RecruiterSelect from './RecruiterSelect.vue';
import Notes from './Notes/Notes.vue';
import Activity from './Activity.vue';
import AddNote from './Notes/AddNote.vue';
import EditProfileFooter from './Profile/EditProfileFooter.vue';
import ApplicantActions from './Actions.vue';

export default {
    name: 'ApplicantsDetail',
    components: {
        IonBackdrop,
        IonButton,
        IonContent,
        IonFooter,
        IonHeader,
        IonIcon,
        IonPage,
        IonProgressBar,
        IonSkeletonText,
        IonToolbar,
        Activity,
        AddNote,
        NextContact,
        Notes,
        Profile,
        RecruiterSelect,
        StatusSelect,
        Tags,
        Labels,
        EditProfileFooter,
        ApplicantActions,
    },
    beforeRouteLeave(to, from) {
        if (to.name !== 'ApplicantsSms' && to.name !== 'ApplicantsEmails') {
            this.$store.commit('setApplicant', null);
        }
    },
    data() {
        return {
            profile: null,
            presentingElement: null,
            loading: false,
            callOutline,
            chevronBackOutline,
            tab: 'profile',
            location,
            mailOutline,
        };
    },
    computed: {
        applicant() {
            return this.$store.getters.applicant;
        },
        onCall() {
            return this.$store.getters.onCall;
        },
        globalBackdrop() {
            return this.$store.getters.globalBackdrop;
        },
    },
    watch: {
        '$route.query'({ tab }) {
            this.tab = tab;
        },
    },
    mounted() {
        this.presentingElement = this.$refs.detailPage.$el;
    },
    methods: {
        callApplicant() {
            TwilioVoice.callApplicant({
                applicantId: `${this.applicant.id}`,
                applicantName: this.applicant.name,
            })
                .then(() => {
                    console.log('call applicant');
                })
                .catch((err) => {
                    console.log(err);
                });
        },
        setTab(tab) {
            this.$router.push({ name: 'ApplicantsDetail', query: { tab } });
        },
    },
    ionViewWillEnter() {
        this.setGlobalLoading(true);
        this.$store.dispatch('getApplicantDetail', this.$route.params.id);
        if (!this.$route.query.tab) {
            this.setTab('profile');
        }
    },
};
</script>

import axios from 'axios';
import { Device } from '@capacitor/device';
import { isPlatform } from '@ionic/vue';
import store from './store';
import router from './router';

window.axios = axios;
window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

Device.getId().then(({ uuid }) => {
    const transformed = uuid.replaceAll('-', '');
    window.axios.defaults.headers.common.Device = transformed;
    window.axios.defaults.headers.common['Window-Token'] = transformed;
    const platform = isPlatform('ios') ? 'ios' : isPlatform('android') ? 'android' : '';
    window.axios.defaults.headers.common.Platform = platform;
    store.commit('setWindowToken', transformed);
});

window.axios.interceptors.response.use(
    (response) => response,
    (error) => {
        const { status } = error.response;
        if (status === 401) {
            store.dispatch('logout');
            const currentRoute = router.currentRoute.value.name;
            if (currentRoute !== 'Login') {
                router.push({ name: 'Login' });
            }
        }
        return Promise.reject(error);
    },
);

<template>
    <div>
        <ion-modal v-if="onCall" :is-open="onCall" :breakpoints="[0.1, 1]" :initial-breakpoint="1" :handle="false">
            <ion-content :fullscreen="true" class="bg-bluegrassPrimary">
                <div class="w-full h-full flex flex-col">
                    <div class="h-3/5 text-white flex space-y-2 flex-col justify-center items-center">
                        <p class="font-semibold text-2xl">{{ applicant.name }}</p>
                        <p class="text-base">{{ applicant.phone }}</p>
                        <p class="text-base font-semibold">
                            <span v-if="call.state === 'ringing'">Ringing</span>
                            <span v-else-if="call.state === 'connected'">{{ timeOnCall }}</span>
                        </p>
                    </div>
                    <div class="h-2/5 flex flex-col justify-around items-center">
                        <div class="flex items-center w-2/3 justify-between">
                            <div class="flex flex-col items-center space-y-1">
                                <div
                                    class="rounded-full w-12 h-12 flex items-center justify-center"
                                    :class="{
                                        'bg-black bg-opacity-30 text-white': !call.applicantOnHold,
                                        'bg-white text-black': call.applicantOnHold,
                                    }"
                                    @click="mute"
                                >
                                    <ion-icon :icon="micOutline" class="h-8 w-8" />
                                </div>
                                <p class="text-white text-sm">Mute</p>
                            </div>
                            <div class="flex flex-col items-center space-y-1">
                                <div
                                    class="rounded-full w-12 h-12 flex items-center justify-center text-white bg-black bg-opacity-30"
                                >
                                    <ion-icon :icon="volumeMediumOutline" class="h-8 w-8" />
                                </div>
                                <p class="text-white text-sm">Audio</p>
                            </div>
                            <div class="flex flex-col items-center space-y-1">
                                <div
                                    class="rounded-full w-12 h-12 flex items-center justify-center text-white bg-black bg-opacity-30"
                                >
                                    <ion-icon :icon="ellipsisHorizontal" class="h-8 w-8" />
                                </div>
                                <p class="text-white text-sm">Options</p>
                            </div>
                        </div>
                        <div class="flex items-center justify-center">
                            <div
                                class="bg-danger rounded-full h-20 w-20 overflow-hidden relative flex items-center justify-center text-white ion-activatable"
                                @click="hangup"
                            >
                                <phone-off />
                                <ion-ripple-effect></ion-ripple-effect>
                            </div>
                        </div>
                    </div>
                </div>
            </ion-content>
        </ion-modal>
    </div>
</template>
<script>
import { IonContent, IonIcon, IonModal, IonRippleEffect, isPlatform } from '@ionic/vue';
import { call, stop, micOutline, volumeMediumOutline, ellipsisHorizontal } from 'ionicons/icons';
import { TwilioVoice } from 'capacitor-twilio-voice';
import { FCM } from '@capacitor-community/fcm';

import phoneOff from './icons/phoneOff.vue';

export default {
    name: 'CallWidget',
    components: {
        IonContent,
        IonIcon,
        IonModal,
        IonRippleEffect,
        phoneOff,
    },
    data() {
        return {
            micOutline,
            volumeMediumOutline,
            ellipsisHorizontal,
            callIcon: call,
            stopIcon: stop,
            fcmToken: null,
            notificationRegisterListener: null,
            callStatusListener: null,
            call: null,
            applicant: null,
            timer: 0,
            timerInterval: null,
        };
    },
    computed: {
        user() {
            return this.$store.getters.user;
        },
        windowToken() {
            return this.$store.getters.windowToken;
        },
        onCall() {
            return this.$store.getters.onCall;
        },
        timeOnCall() {
            if (this.timer) {
                return new Date(this.timer).toISOString().slice(14, 19);
            }
            return '00:00';
        },
        profileEditing() {
            return this.$store.getters.profileEditing;
        },
    },
    watch: {
        fcmToken(val) {
            if (val) {
                console.log('inituju twilio');
                this.initTwilio();
            }
        },
        call: {
            depp: true,
            handler(val, oldVal) {
                if (val && oldVal && val.state === 'connected' && oldVal.state === 'ringing') {
                    this.startTimer();
                }
            },
        },
    },
    mounted() {
        TwilioVoice.removeAllListeners();
        this.registerListeners();
        this.initPushNotifications();
        console.log('mountuju');
    },
    methods: {
        async initPushNotifications() {
            let status = await TwilioVoice.checkPermissions();

            if (status.receive === 'prompt') {
                status = await TwilioVoice.requestPermissions();
            }

            if (status.receive !== 'granted') {
                // alert('Permission denied');
            }
            await TwilioVoice.register();
        },
        async registerListeners() {
            if (!this.notificationRegisterListener) {
                this.notificationRegisterListener = await TwilioVoice.addListener('registration', (token) => {
                    if (isPlatform('ios')) {
                        FCM.getToken().then((r) => (this.fcmToken = r.token));
                    } else {
                        this.fcmToken = token.value;
                    }
                });
            }
        },
        getTwilioToken() {
            return new Promise((resolve) => {
                axios
                    .get(`${apiUrl}/mobile-app/call/get-token?ttl=${60 * 60}`)
                    .then((response) => resolve(response.data.token));
            });
        },
        hangup() {
            axios.post(`${apiUrl}/mobile-app/call/hangup/${this.call.conferenceId}`);
        },
        initTwilio() {
            this.getTwilioToken().then((token) => {
                TwilioVoice.registerTwilio({
                    accessToken: token,
                    registrationToken: this.fcmToken,
                    userId: `${this.user.id}`,
                    windowToken: this.windowToken,
                }).then(() => {
                    this.$store.commit('setTwilioInitialized', true);
                    const channel = pusher.subscribe(`private-user.${this.user.id}`);
                    channel.bind('RefreshCallWidget', (data) => {
                        console.log(data.call);
                        this.applicant = data.applicant;
                        this.call = data.call;
                        if (
                            data.call &&
                            data.call.state &&
                            (data.call.state === 'disconnected' || data.call.state === 'closed')
                        ) {
                            this.call = null;
                            clearInterval(this.timerInterval);
                            this.timer = 0;
                            this.$store.commit('setOnCall', false);
                            this.$store.dispatch('getApplicantDetail');
                            this.applicant = null;
                        } else {
                            this.$store.commit('setOnCall', true);
                        }
                    });
                });
            });
        },
        startTimer() {
            this.timerInterval = setInterval(() => {
                this.timer += 1000;
            }, 1000);
        },
        mute() {
            if (this.call && this.call.conferenceId) {
                this.$store.dispatch('toggleApplicantOnHold', {
                    conferenceId: this.call.conferenceId,
                    applicantOnHold: !this.call.applicantOnHold,
                });
            }
        },
    },
};
</script>

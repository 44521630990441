<template>
<svg width="88" height="44" viewBox="0 0 88 44" fill="none" xmlns="http://www.w3.org/2000/svg">
<g filter="url(#filter0_d_0_1)">
<g clip-path="url(#clip0_0_1)">
<rect x="2" y="7" width="30" height="30" rx="15" fill="#F3F4F6"/>
<path d="M32 33.2425V37.0012H2V33.2562C3.74494 30.9243 6.00988 29.0317 8.61474 27.7288C11.2196 26.426 14.0925 25.7488 17.005 25.7512C23.135 25.7512 28.58 28.6937 32 33.2425ZM22.0025 18.25C22.0025 19.5761 21.4757 20.8479 20.538 21.7855C19.6004 22.7232 18.3286 23.25 17.0025 23.25C15.6764 23.25 14.4046 22.7232 13.467 21.7855C12.5293 20.8479 12.0025 19.5761 12.0025 18.25C12.0025 16.9239 12.5293 15.6521 13.467 14.7145C14.4046 13.7768 15.6764 13.25 17.0025 13.25C18.3286 13.25 19.6004 13.7768 20.538 14.7145C21.4757 15.6521 22.0025 16.9239 22.0025 18.25Z" fill="#D1D5DB"/>
</g>
</g>
<g filter="url(#filter1_d_0_1)">
<g clip-path="url(#clip1_0_1)">
<rect x="24" y="2" width="40" height="40" rx="20" fill="#F3F4F6"/>
<path d="M64 36.9899V42.0016H24V37.0082C26.3266 33.899 29.3465 31.3755 32.8196 29.6383C36.2928 27.9012 40.1233 26.9983 44.0067 27.0016C52.18 27.0016 59.44 30.9249 64 36.9899ZM50.67 16.9999C50.67 18.768 49.9676 20.4637 48.7174 21.714C47.4671 22.9642 45.7714 23.6666 44.0033 23.6666C42.2352 23.6666 40.5395 22.9642 39.2893 21.714C38.039 20.4637 37.3367 18.768 37.3367 16.9999C37.3367 15.2318 38.039 13.5361 39.2893 12.2859C40.5395 11.0356 42.2352 10.3333 44.0033 10.3333C45.7714 10.3333 47.4671 11.0356 48.7174 12.2859C49.9676 13.5361 50.67 15.2318 50.67 16.9999Z" fill="#D1D5DB"/>
</g>
</g>
<g filter="url(#filter2_d_0_1)">
<g clip-path="url(#clip2_0_1)">
<rect x="56" y="7" width="30" height="30" rx="15" fill="#F3F4F6"/>
<path d="M86 33.2425V37.0012H56V33.2562C57.7449 30.9243 60.0099 29.0317 62.6147 27.7288C65.2196 26.426 68.0925 25.7488 71.005 25.7512C77.135 25.7512 82.58 28.6937 86 33.2425ZM76.0025 18.25C76.0025 19.5761 75.4757 20.8479 74.538 21.7855C73.6004 22.7232 72.3286 23.25 71.0025 23.25C69.6764 23.25 68.4046 22.7232 67.467 21.7855C66.5293 20.8479 66.0025 19.5761 66.0025 18.25C66.0025 16.9239 66.5293 15.6521 67.467 14.7145C68.4046 13.7768 69.6764 13.25 71.0025 13.25C72.3286 13.25 73.6004 13.7768 74.538 14.7145C75.4757 15.6521 76.0025 16.9239 76.0025 18.25Z" fill="#D1D5DB"/>
</g>
</g>
<defs>
<filter id="filter0_d_0_1" x="0" y="5" width="34" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_0_1"/>
<feOffset/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
</filter>
<filter id="filter1_d_0_1" x="22" y="0" width="44" height="44" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_0_1"/>
<feOffset/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
</filter>
<filter id="filter2_d_0_1" x="54" y="5" width="34" height="34" filterUnits="userSpaceOnUse" color-interpolation-filters="sRGB">
<feFlood flood-opacity="0" result="BackgroundImageFix"/>
<feColorMatrix in="SourceAlpha" type="matrix" values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0" result="hardAlpha"/>
<feMorphology radius="2" operator="dilate" in="SourceAlpha" result="effect1_dropShadow_0_1"/>
<feOffset/>
<feComposite in2="hardAlpha" operator="out"/>
<feColorMatrix type="matrix" values="0 0 0 0 1 0 0 0 0 1 0 0 0 0 1 0 0 0 1 0"/>
<feBlend mode="normal" in2="BackgroundImageFix" result="effect1_dropShadow_0_1"/>
<feBlend mode="normal" in="SourceGraphic" in2="effect1_dropShadow_0_1" result="shape"/>
</filter>
<clipPath id="clip0_0_1">
<rect x="2" y="7" width="30" height="30" rx="15" fill="white"/>
</clipPath>
<clipPath id="clip1_0_1">
<rect x="24" y="2" width="40" height="40" rx="20" fill="white"/>
</clipPath>
<clipPath id="clip2_0_1">
<rect x="56" y="7" width="30" height="30" rx="15" fill="white"/>
</clipPath>
</defs>
</svg>
</template>

import LoginLayout from './vue/Login.vue';

const routes = [
    {
        path: '/login',
        name: 'Login',
        component: LoginLayout,
        meta: {
            free: true,
        },
    },
    {
        path: '/',
        redirect: '/login',
    },
];

routes.forEach((route) => window.routes.add(route));

require('./store');

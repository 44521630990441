<template>
    <div>
        <div v-if="state === 'Scheduled'" class="py-0.5 px-4 bg-yellow-100 text-sm text-yellow-800 rounded">
            {{ state }}
        </div>
        <div v-if="state === 'Failed'" class="py-0.5 px-4 bg-red-100 text-sm text-red-800 rounded">
            {{ state }}
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'state',
    ],
};
</script>

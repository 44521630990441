<template>
    <card-template
        cardClass="bg-white hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-sms class="text-gray-700 w-5 h-5" />
        </template>

        <template #title>
            <div class="flex items-center gap-x-2">
                <span>Driver review</span>
            </div>
        </template>

        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="flex flex-col space-y-3">
            <div v-if="data.approval_state === 'approved'" class="flex items-center space-x-2">
                <icon-thumb-up class="w-5 h-5 text-success" />
                <div>Driver was <span class="font-medium">approved</span></div>
            </div>
            <div v-if="data.approval_state === 'rejected'" class="flex items-center space-x-2">
                <icon-thumb-down class="w-5 h-5 text-red-600" />
                <div>Driver was <span class="font-medium">rejected</span></div>
            </div>
            <div v-if="data.note">
                <div class="font-medium">Reviewer's note:</div>
                <div>- {{ data.note }}.</div>
            </div>
        </div>

    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineSms from '@src/Ats/ActivityFeed/vue/Icon/TimelineSms';
import IconThumbUp from '@src/Common/Ui/Components/Icons/vue/solid/thumb-up.vue';
import IconThumbDown from '@src/Common/Ui/Components/Icons/vue/solid/thumb-down.vue';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelineSms,
        IconThumbUp,
        IconThumbDown,
    },
    props: ['data', 'readonly'],
};
</script>

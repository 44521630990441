<template>
    <card-template
        cardClass="bg-white hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-sms class="text-gray-700 w-4 h-4" />
        </template>
        <template #title>
            <div class="flex items-center gap-x-2">
                <span>Bulk SMS</span>

                <template v-if="data.sms_failed_at">
                    <tr-tooltip :content="data.sms_failed_reason">
                        <message-state state="Failed" />
                    </tr-tooltip>
                </template>
                <template v-else>
                    <message-state state="Scheduled" />
                </template>
            </div>
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="inline-block text-blackText whitespace-pre-wrap" v-html="data.text"></div>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineSms from '@src/Ats/ActivityFeed/vue/Icon/TimelineSms';
import MessageState from '@src/Ats/ActivityFeed/vue/BulkMessages/MessageState';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelineSms,
        MessageState,
    },
    props: ['data', 'readonly'],
};
</script>

const store = {
    state() {
        return {
            onCall: false,
            twilioInitialized: false,
        };
    },
    getters: {
        onCall: (state) => state.onCall,
        twilioInitialized: (state) => state.twilioInitialized,
    },
    actions: {
        toggleApplicantOnHold({ state }, { conferenceId, applicantOnHold }) {
            return axios.post(`${apiUrl}/mobile-app/call/hold/${conferenceId}`, {
                onHold: applicantOnHold,
            });
        },
    },
    mutations: {
        setOnCall(state, value) {
            state.onCall = value;
        },
        setTwilioInitialized(state, value) {
            state.twilioInitialized = value;
        },
    },
};

window.store.registerModule('twilio', store);

<template>
    <svg width="60" height="60" viewBox="0 0 60 60" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M9 6C7.34315 6 6 7.34315 6 9V15C6 25.7696 10.3652 35.5196 17.4228 42.5772L21.6655 38.3345C18.546 35.2151 16.0482 31.474 14.3738 27.3131L19.0182 24.9909C20.1962 24.4019 20.8523 23.1135 20.6357 21.8144L18.4178 8.5068C18.1767 7.06024 16.9251 6 15.4586 6H9ZM23.1223 39.7061L18.8731 43.9553C25.7879 50.1986 34.95 54 45 54H51C52.6569 54 54 52.6569 54 51V44.5414C54 43.0749 52.9398 41.8233 51.4932 41.5822L38.1856 39.3643C36.8865 39.1477 35.5981 39.8038 35.0091 40.9818L32.6869 45.6262C29.1551 44.2049 25.9258 42.1904 23.1223 39.7061Z"
            fill="currentColor"
        />
        <line x1="54" y1="9.70711" x2="9.70711" y2="54" stroke="currentColor" stroke-linecap="round" />
    </svg>
</template>

<template>
    <div
        class="w-full border border-solid flex flex-row items-center justify-between py-2 px-3 gap-x-2 rounded-md text-xs bg-white border-gray-300 cursor-pointer hover:bg-indigo-50 hover:border-indigo-300"
    >
        <div class="flex-shrink-0 w-4">
            <document-icon class="w-4 h-4 text-indigo-600" />
        </div>
        <div class="flex flex-grow gap-x-2">
            <div>{{ name }}</div>
            <div v-if="size !== null" class="text-gray-500 whitespace-nowrap">({{ fileSize(size) }})</div>
        </div>
        <div class="flex-shrink-0 w-4">
            <download-icon class="w-4 h-4 text-gray-700" />
        </div>
    </div>
</template>
<script>
import DocumentIcon from '@src/Common/Ui/Components/Icons/vue/outline/document.vue';
import DownloadIcon from '@src/Common/Ui/Components/Icons/vue/solid/download.vue';
import { humanFileSize } from "@src/Ats/DqFiles/vue/helpers.js";

export default {
    components: {
        DocumentIcon,
        DownloadIcon
    },
    props: {
        name: {
            type: String,
            required: true
        },
        size: {
            type: Number,
            default: null
        }
    },
    methods: {
        fileSize(bytes) {
            return humanFileSize(bytes);
        }
    },
};
</script>

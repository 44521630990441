<template>
    <svg width="16" height="10" viewBox="0 0 16 10" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            fill-rule="evenodd"
            clip-rule="evenodd"
            d="M5.79612 0.0187173C5.98558 -0.0322497 6.18774 0.0225768 6.32581 0.162374L10.2376 4.12304L13.4458 3.26068C13.7001 3.18779 13.9662 3.16611 14.2289 3.19693C14.4935 3.22797 14.7493 3.31163 14.9813 3.44303C15.2133 3.57443 15.4168 3.75092 15.58 3.96219C15.7432 4.17345 15.8628 4.41524 15.9317 4.67342C16.0007 4.9316 16.0176 5.20098 15.9816 5.4658C15.9455 5.73063 15.8572 5.98558 15.7217 6.21575C15.5863 6.44591 15.4065 6.64667 15.1928 6.80628C14.9806 6.96475 14.7393 7.07949 14.4828 7.14391L3.93036 9.98129C3.73039 10.0351 3.5173 9.97084 3.37996 9.8154L0.137549 6.14555C0.0119202 6.00336 -0.0313748 5.80605 0.0231253 5.62407C0.0776254 5.44209 0.22215 5.3014 0.405088 5.25224L2.51594 4.68495C2.65589 4.64733 2.80501 4.66702 2.9305 4.73969L4.51966 5.6599L5.71213 5.33943L3.35735 1.39822C3.26919 1.25068 3.25517 1.07017 3.31947 0.910705C3.38376 0.751236 3.51893 0.631288 3.68454 0.58674L5.79612 0.0187173ZM4.66717 1.45771L7.02204 5.39907C7.11021 5.54663 7.12422 5.72716 7.0599 5.88664C6.99557 6.04612 6.86036 6.16606 6.69473 6.21058L4.58388 6.77787C4.44392 6.81548 4.29481 6.79579 4.16932 6.72313L2.58015 5.80292L1.5419 6.08195L3.97374 8.83436L14.2046 6.08345L14.2149 6.08079C14.3324 6.05163 14.443 5.99924 14.5401 5.92669C14.6373 5.85415 14.719 5.76289 14.7806 5.65827C14.8421 5.55365 14.8823 5.43776 14.8987 5.31739C14.9151 5.19701 14.9074 5.07457 14.876 4.95721C14.8447 4.83986 14.7903 4.72995 14.7162 4.63392C14.642 4.5379 14.5495 4.45767 14.444 4.39794C14.3386 4.33822 14.2223 4.30019 14.102 4.28608C13.9818 4.27197 13.8599 4.28207 13.7435 4.31578L13.7333 4.31863L10.214 5.2646C10.0246 5.31552 9.82249 5.26069 9.68445 5.12092L5.77275 1.1603L4.66717 1.45771Z"
            fill="currentColor"
        />
    </svg>
</template>

/**
 * Luxon
 *
 * Work with dates - formatting, manipulation
 *
 * Usage:
 *  this.$luxon.now().startOf('month').toISODate() - returns 2022-04-01
 *  this.$luxon.nowSQLDate() - returns 2022-04-10
 *  this.$luxon.nowSQLTime() - returns 13:11:23
 */
import { DateTime, Interval, Duration } from 'luxon';

const LuxonPlugin = {
    install(VueInstance) {
        // eslint-disable-next-line
        VueInstance.prototype.$luxon = {
            DateTime,
            Interval,
            Duration,
            now() {
                return DateTime.now();
            },
            nowZone(zone = 'UTC') {
                return DateTime.now().setZone(zone);
            },
            nowSQLTime(zone = 'UTC') {
                return this.formatSQLTime(this.nowZone(zone));
            },
            nowSQLDate(zone = 'UTC') {
                return this.formatSQLDate(this.nowZone(zone));
            },
            format(luxonDate, format = DateTime.DATE_SHORT, locale = 'en-US') {
                return luxonDate.setLocale(locale).toLocaleString(format);
            },
            fromJSDate(date) {
                return DateTime.fromJSDate(date);
            },
            fromString(stringDate) {
                return DateTime.fromFormat(stringDate, 'yyyy-MM-dd hh:mm:ss', { zone: 'UTC' }).setZone(
                    this.timezone_my(),
                );
            },
            fromFormat(stringDate, format, options) {
                return DateTime.fromFormat(stringDate, format, options);
            },
            fromSQL(SQLDate) {
                return DateTime.fromSQL(SQLDate);
            },
            fromISO(ISODate) {
                return DateTime.fromISO(ISODate).setZone(this.timezone_my());
            },
            getLuxon(luxonDate) {
                let localDate = null;
                if (typeof luxonDate === 'string') {
                    localDate = this.fromString(luxonDate);
                } else {
                    localDate = luxonDate;
                }
                return localDate;
            },
            formatTime(luxonDate, locale = 'en-US') {
                return this.getLuxon(luxonDate)
                    .setLocale(locale)
                    .toLocaleString(DateTime.TIME_SIMPLE)
                    .replace('AM', 'am')
                    .replace('PM', 'pm');
            },
            formatDatetimeShort(luxonDate, locale = 'en-US') {
                if (luxonDate === null) {
                    return null;
                }
                return this.getLuxon(luxonDate)
                    .setLocale(locale)
                    .toLocaleString({ month: '2-digit', day: '2-digit', year: 'numeric', hour: 'numeric', minute: 'numeric' })
                    .replace('AM', 'am')
                    .replace('PM', 'pm');
            },
            formatDatetimeLong(luxonDate, locale = 'en-US') {
                if (luxonDate === null) {
                    return null;
                }
                return this.getLuxon(luxonDate)
                    .setLocale(locale)
                    .toLocaleString(DateTime.DATETIME_MED)
                    .replace('AM', 'am')
                    .replace('PM', 'pm');
            },
            formatDateShort(luxonDate, locale = 'en-US') {
                if (luxonDate === null) {
                    return null;
                }
                return this.getLuxon(luxonDate).setLocale(locale).toFormat('MM/dd');
            },
            formatDate(luxonDate, locale = 'en-US') {
                if (luxonDate === null) {
                    return null;
                }
                return this.getLuxon(luxonDate).setLocale(locale).toFormat('MM/dd/yyyy');
            },
            formatDateLong(luxonDate, locale = 'en-US') {
                if (luxonDate === null) {
                    return null;
                }
                return this.getLuxon(luxonDate).setLocale(locale).toLocaleString(DateTime.DATE_MED);
            },
            formatSQLDate(luxonDate) {
                return luxonDate.toSQLDate();
            },
            formatSQLTime(luxonDate) {
                return luxonDate.toSQLTime();
            },
            formatJSDate(JSDate, format, locale) {
                return this.format(DateTime.fromJSDate(JSDate), format, locale);
            },
            formatJSDatetime(JSDate, format = DateTime.DATETIME_SHORT, locale = 'en-US') {
                return this.format(DateTime.fromJSDate(JSDate), format, locale);
            },
            formatJSDatetimeTwoDigitYear(
                JSDate,
                format = { ...DateTime.DATETIME_SHORT, year: '2-digit' },
                locale = 'en-US',
            ) {
                return this.format(DateTime.fromJSDate(JSDate), format, locale);
            },
            formatSQL(SQLDate, format, locale) {
                return this.format(DateTime.fromSQL(SQLDate), format, locale);
            },
            startOfUSWeek(luxonDate) {
                return luxonDate.startOf('week').minus({ day: 1 });
            },
            endOfUSWeek(luxonDate) {
                return luxonDate.endOf('week').minus({ day: 1 });
            },
            // Timezone
            timezoneGuess() {
                return DateTime.local().offsetNameShort;
            },
            timezone_is_currently_dst() {
                return DateTime.local().isInDST;
            },
            timezone_abbr_from_name(name) {
                return DateTime.local().setZone(name).offsetNameShort;
            },
            timezone_get_specific(timezone, fallback = 'UTC') {
                if (!timezone) {
                    return fallback;
                }

                const found = Object.values(window.app.timezones).find(
                    (item) => item.generalized === timezone || item.daylight === timezone || item.standard === timezone,
                );

                if (found) {
                    return this.timezone_is_currently_dst() ? found.daylight : found.standard;
                }

                return fallback;
            },
            timezone_get_generalized(timezone, fallback = 'UTC') {
                if (!timezone) {
                    return fallback;
                }

                const found = Object.values(window.app.timezones).find(
                    (item) => item.daylight === timezone || item.standard === timezone,
                );

                if (found) {
                    return found.generalized;
                }

                return fallback;
            },
            timezone_get_standard(input) {
                // TODO: take from config
                if (input === 'MT' || input === 'MDT') {
                    return 'America/Denver';
                }
                if (input === 'PT' || input === 'PDT') {
                    return 'PST';
                }
                if (input === 'CT' || input === 'CDT') {
                    return 'CST';
                }
                if (input === 'ET' || input === 'EDT') {
                    return 'EST';
                }
                return input;
            },
            timezone_my() {
                return this.timezone_get_standard(window.app.$store.getters.me.user.timezone_generalized) ?? 'UTC';
            },
            timezone_my_offset() {
                return DateTime.local().setZone(window.app.$store.getters.me.user.timezone_generalized).offset / 60;
            },
            get_time_in_my_timezone(luxonDate) {
                return this.getLuxon(luxonDate).setZone(this.timezone_my());
            },
        };
    },
};

export default LuxonPlugin;

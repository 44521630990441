<template>
    <div
        v-if="show"
        v-click-outside="
            () => {
                $emit('close');
            }
        "
        class="absolute z-50 w-screen max-w-md p-2 mt-2 bg-white border rounded-md shadow-lg top-full broder-gray-300"
        :class="[ position === 'right' ? 'right-0' : '']"
    >
        <div class="flex flex-col space-y-2">
            <div class="flex items-center justify-between pb-2 border-b border-gray-300">
                <div class="text-sm text-gray-500">{{ title }}</div>
                <div class="flex items-center space-x-2">
                    <router-link
                        v-if="templates.length"
                        class="text-sm font-medium text-indigo-600"
                        :to="{ name: 'settings-message-templates' }"
                    >
                        Manage list
                    </router-link>
                    <tr-x class="w-5 h-5 text-gray-700 cursor-pointer" @click.native="$emit('close')" />
                </div>
            </div>
            <div v-if="templates.length" class="space-y-2">
                <div
                    v-for="template in templates"
                    :key="`template-${template.id}`"
                    class="px-2 py-1 rounded-md cursor-pointer hover:bg-indigo-50"
                    @click="$emit('choose', template.id)"
                >
                    <p class="text-sm text-gray-900 truncate">
                        {{ template.name }} -
                        <span class="text-gray-500" v-html="sanitize(template.content.body)"></span>
                    </p>
                </div>
            </div>
            <div v-else class="flex flex-col items-center justify-center h-40 space-y-2">
                <p class="text-sm text-center text-gray-500">Message templates empty</p>
                <tr-button type="primary" size="sm" font-size="sm" @click.native="createTemplate">
                    Create
                </tr-button>
            </div>
        </div>
    </div>
</template>
<script>

export default {
    props: {
        templates: {
            type: Array,
            default: () => [],
        },
        show: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        type: {
            type: String,
            default: '',
        },
        position: {
            type: String,
            default: 'right',
        },
    },
    methods: {
        sanitize(input) {
            return input.replace(/<\/?[^>]+(>|$)/g, ' ');
        },
        loadData() {
            this.$emit('load-data');
        },
        createTemplate() {
            this.$router.push({ name: 'settings-message-templates' });
        },
    },
};
</script>

<template>
    <card-template
        cardClass="bg-white-50 hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <icon-switch-horizontal class="text-gray-700 w-5 h-5" />
        </template>
        <template #title>
            <template v-if="data.updated">
                Assigned recruiter
            </template>
            <template v-else>
                Unassigned recruiter
            </template>
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="flex text-xs text-gray-700 items-center gap-x-2" v-if="data.updated">
            <span>To</span>
            <span>{{ data.updated.name }}</span>
        </div>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import IconSwitchHorizontal from '@src/Common/Ui/Components/Icons/vue/solid/switch-horizontal.vue';

export default {
    components: {
        CardTemplate,
        CardMenu,
        IconSwitchHorizontal
    },
    props: ['data', 'readonly'],
    data() {
        return {
        };
    },
    methods: {
    },
};
</script>

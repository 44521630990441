<template>
    <div class="flex flex-col px-4">
        <div class="flex justify-between items-center py-1">
            <p class="text-xl text-blue-700" @click="cancel">Cancel</p>
            <p class="text-lg text-gray-900">Date of Birth</p>
            <button class="text-xl" :class="{'text-gray-300': headerLoading, 'text-blue-700': !headerLoading}" :disabled="headerLoading" @click="save()">Save</button>
        </div>
        <div>
            <ion-datetime
                class="w-full"
                locale="en-US"
                presentation="date"
                :prefer-wheel="true"
                :value="fieldValue"
                @ionChange="updateValue"
            />
        </div>
    </div>
</template>
<script>
import { IonDatetime } from '@ionic/vue';
import fieldsMixin from './fieldsMixin';

export default {
    name: 'DateField',
    components: {
        IonDatetime,
    },
    mixins: [fieldsMixin],
    computed: {
        fieldValue() {
            if (this.profile && this.profileEditing) {
                return this.profile[this.profileEditing.name];
            }
            return null;
        },
    },
    methods: {
        updateValue({ detail }) {
            const { value } = detail;
            const { profileEditing } = this;
            const newValue = {
                fields: [
                    {
                        id: profileEditing.name,
                        value,
                    }
                ]
            };
            this.$store.commit('setProfileEditing', { ...profileEditing, newValue });
        },
    },
};
</script>

<template>
    <div class="h-full pl-1">
        <template v-if="!globalLoading">
            <div
                class="h-full flex flex-col justify-center"
                :class="{ 'bg-indigo-100 rounded': selected && !globalLoading }"
            >
                <p class="text-gray-600">{{ title }}</p>
                <p class="text-sm font-bold text-gray-900">{{ count }}</p>
            </div>
        </template>
        <div v-else class="h-full pl-1">
            <ion-skeleton-text :animated="true" class="rounded" style="margin: 0" />
        </div>
    </div>
</template>
<script>
import { IonSkeletonText } from '@ionic/vue';

export default {
    name: 'ApplicantSingleFilter',
    components: {
        IonSkeletonText,
    },
    props: {
        selected: {
            type: Boolean,
            default: false,
        },
        title: {
            type: String,
            default: '',
        },
        count: {
            type: Number,
            default: 0,
        },
    },
};
</script>

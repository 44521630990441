<template>
    <svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
        <path
            d="M14 6L16 4M16 4L18 2M16 4L14 2M16 4L18 6M3 1C1.89543 1 1 1.89543 1 3V4C1 12.2843 7.71573 19 16 19H17C18.1046 19 19 18.1046 19 17V13.7208C19 13.2903 18.7246 12.9082 18.3162 12.7721L13.8228 11.2743C13.3507 11.1169 12.8347 11.3306 12.6121 11.7757L11.4835 14.033C9.03878 12.9308 7.06925 10.9612 5.96701 8.5165L8.22427 7.38787C8.66938 7.16531 8.88311 6.64932 8.72574 6.17721L7.22792 1.68377C7.09181 1.27543 6.70967 1 6.27924 1H3Z"
            stroke="#6B7280"
            stroke-linecap="round"
            stroke-linejoin="round"
        />
    </svg>
</template>

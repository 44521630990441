<template>
    <card-template
        cardClass="bg-white hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-mail class="text-gray-700 w-5 h-5" />
        </template>

        <template #title>
            <span v-if="! data.email_sent_at">
                Email
            </span>
            <div class="flex items-center gap-x-2" v-else>
                <span>Bulk Email</span>
                <div class="py-0.5 px-4 bg-green-100 text-sm text-green-800 rounded">
                    Sent
                </div>
            </div>
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="w-full truncate text-gray-500">
            Subject: {{ data.subject }}
        </div>
        <div class="inline-block text-blackText email-content" v-html="data.text"></div>

        <email-attachment-list :attachments="data.attachments" />
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelineMail from '@src/Ats/ActivityFeed/vue/Icon/TimelineMail';
import EmailAttachmentList from '@src/Ats/ActivityFeed/vue/Attachments/EmailAttachmentList';
import DocumentIcon from '@src/Common/Ui/Components/Icons/vue/outline/document.vue';
import { humanFileSize } from "@src/Ats/DqFiles/vue/helpers.js";

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelineMail,
        EmailAttachmentList,
        DocumentIcon,
        TimelineMail,
    },
    props: ['data', 'readonly'],
    methods: {
        fileSize(bytes) {
            return humanFileSize(bytes);
        },
        attachmentUrl(attachmentId, type = 'inline') {
            return route('email.attachment.' + type) + `?attachmentId=${attachmentId}`;
        },
        linkTo(attachmentId, event) {
            if (event.ctrlKey || event.metaKey || event.altKey) {
                window.open(this.attachmentUrl(attachmentId), '_blank').focus();
            } else {
                window.open(this.attachmentUrl(attachmentId, 'download'));
            }
        },
    },
};
</script>
<style>
    .email-content img {
        cursor: pointer;
        max-width: 50% !important;
    }
</style>

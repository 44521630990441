<template>
    <div>
        <tr-dropdown
            ref="dropdown"
            placement="bottom-end"
            @opened="opened"
        >
            <template #button>
                <tr-tooltip content="Watch activity">
                    <div class="flex items-center space-x-2 border border-solid p-1 rounded cursor-pointer" :class="styles">
                        <tr-eye-solid v-if="watching" class="w-6 h-6" />
                        <tr-eye v-else class="w-6 h-6" />
                        <div v-if="applicantActivityFeed.watchers.length">{{ applicantActivityFeed.watchers.length }}</div>
                    </div>
                </tr-tooltip>
            </template>
            <template #menu>
                <watch-dropdown v-if="open" />
            </template>
        </tr-dropdown>
    </div>
</template>

<script>
import { mapGetters } from 'vuex';
import TrEye from '@src/Common/Ui/Components/Icons/vue/outline/eye.vue';
import TrEyeSolid from '@src/Common/Ui/Components/Icons/vue/solid/eye.vue';
import WatchDropdown from './WatchDropdown';

export default {
    components: {
        TrEye,
        TrEyeSolid,
        WatchDropdown,
    },
    data() {
        return {
            open: false,
        };
    },
    computed: {
        ...mapGetters(['me', 'applicantActivityFeed']),
        watching() {
            return this.applicantActivityFeed.watchers.includes(this.me.user.id);
        },
        styles() {
            return [
                this.watching || this.applicantActivityFeed.watchers.length ? 'text-bluegrassPrimary' : 'text-gray-700',
                'hover:bg-gray-50 hover:border-gray-300',
                this.open ? 'bg-indigo-100 border-indigo-300' : 'bg-white border-white',
            ];
        },
    },
    methods: {
        opened(value) {
            this.open = value;
        }
    }
};
</script>

<template>
    <card-template
        cardClass="bg-indigo-50 hover:bg-gray-50"
        :time="data.time"
        :card="data"
        :readonly="readonly"
    >
        <template #icon>
            <timeline-phone-in class="text-gray-700 w-5 h-5" />
        </template>
        <template #title>
            Inbound call
        </template>
        <template #menu>
            <card-menu :data="data" />
        </template>

        <div class="flex flex-col items-start">
            <span class="inline-block text-gray-700 font-medium">Call duration {{ data.duration }}</span>
            <div class="font-bold">{{ data.disposition }}</div>
            <span>{{ data.reasons }}</span>
        </div>
    </card-template>
</template>

<script>
import CardTemplate from '@src/Ats/ActivityFeed/vue/CardTemplate';
import CardMenu from '@src/Ats/ActivityFeed/vue/Menu/CardMenu';
import TimelinePhoneIn from '@src/Ats/ActivityFeed/vue/Icon/TimelinePhoneIn';

export default {
    components: {
        CardTemplate,
        CardMenu,
        TimelinePhoneIn,
    },
    props: ['data', 'readonly'],
};
</script>
